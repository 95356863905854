
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { Nav, Image, Navbar, Dropdown, Container } from 'react-bootstrap';

// import NOTIFICATIONS_DATA from "../data/notifications";
import { GetCurrentUser, LogoutUser } from "../services/UserService";


export default (props) => {
  // const [notifications, setNotifications] = useState(NOTIFICATIONS_DATA);
  // const areNotificationsRead = notifications.reduce((acc, notif) => acc && notif.read, true);
  const { data, isLoading } = GetCurrentUser();


  // const markNotificationsAsRead = () => {
  //   setTimeout(() => {
  //     setNotifications(notifications.map(n => ({ ...n, read: true })));
  //   }, 300);
  // };

  // const Notification = (props) => {
  //   const { link, sender, image, time, message, read = false } = props;
  //   const readClassName = read ? "" : "text-danger";

  //   return (
  //     <ListGroup.Item action href={link} className="border-bottom border-light">
  //       <Row className="align-items-center">
  //         <Col className="col-auto">
  //           <Image src={image} className="user-avatar lg-avatar rounded-circle" />
  //         </Col>
  //         <Col className="ps-0 ms--2">
  //           <div className="d-flex justify-content-between align-items-center">
  //             <div>
  //               <h4 className="h6 mb-0 text-small">{sender}</h4>
  //             </div>
  //             <div className="text-end">
  //               <small className={readClassName}>{time}</small>
  //             </div>
  //           </div>
  //           <p className="font-small mt-1 mb-0">{message}</p>
  //         </Col>
  //       </Row>
  //     </ListGroup.Item>
  //   );
  // };

  let handleLogout = async (e) => {
    return await LogoutUser();
  };

  return (
    <Navbar variant="dark" expanded className="p-0" bg="white">
      <Container fluid className="mt-2" style={{ border: "1px solid rgb(226 232 240)", borderTop: "0" }}>
        <div className="d-flex justify-content-end w-100">
          <Nav className="align-items-center">
            {/* <Dropdown as={Nav.Item} onToggle={markNotificationsAsRead} >
              <Dropdown.Toggle as={Nav.Link} className="text-dark icon-notifications me-lg-3">
                <span className="icon icon-sm">
                  <FontAwesomeIcon icon={faBell} className="bell-shake" />
                  {areNotificationsRead ? null : <span className="icon-badge rounded-circle unread-notifications" />}
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 mr-2 py-0">
                <ListGroup className="list-group-flush">
                  <Nav.Link href="#" className="text-center text-primary border-bottom border-light py-3">
                    Notificações
                  </Nav.Link>

                  {notifications.map(n => <Notification key={`notification-${n.id}`} {...n} />)}

                  <Dropdown.Item className="text-center text-primary  py-3">
                    Visualizar todas
                  </Dropdown.Item>
                </ListGroup>
              </Dropdown.Menu>
            </Dropdown> */}

            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  <Image src='https://picsum.photos/300/300' className="user-avatar md-avatar rounded-circle" />
                  <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                    {(isLoading) ? (
                      <span className="mb-0 font-small me-2">Usuário</span>
                    ) : (
                      <>
                        <span className="mb-0 font-small me-2">{data.username}</span>
                        <FontAwesomeIcon color="grey" icon={faChevronDown} className="mb-0" />
                        <br />
                        {data.role && (
                          <span className="mb-0 font-small me-2">{data.role.name}</span>
                        )}
                        {/* <small className="mb-0 mt-0 font-small">Cervet Mooca</small> */}
                      </>
                    )}
                  </div>

                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-1">
                {/* <Dropdown.Item className="">
                  <FontAwesomeIcon icon={faUserCircle} className="me-2" /> Meu Perfil
                </Dropdown.Item> */}

                {/* <Dropdown.Divider /> */}

                <Dropdown.Item className="">
                  <span onClick={handleLogout}>
                    <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> Sair
                  </span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
};
