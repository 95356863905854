import { useEffect, useState } from "react";
import api from "../api";

export const FindActiveExam = (page, refreshState) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    api
      .get(`exam?page=${page}`)
      .then((response) => {
        response.data.length === 0 ? setIsEmpty(true) : setIsEmpty(false);
        setData(response.data.data.info);
        setPagination(response.data.data.pagination);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsEmpty(true);
        setIsLoading(false);
      });
  }, [page, refreshState]);

  return { data, pagination, isLoading, isEmpty };
};



export const CreateExam = async (pet_id, file) => {
  const headers = {
    "content-type": file.type,
  };

  const formData = new FormData();
  formData.append("pet_id", pet_id);
  formData.append("file", file);

  return await api.post("/exam", formData, { headers });
};

export const FindExam = async (id, password) => {
  return await api.get(`/exam/${id}/${password}`).then(function (response) {
    return { data: response.data.data, status: response.data.status };
  });
};

export const FindExamByPassword = async (password) => {
  return await api.get(`/exam/p/${password}`).then(function (response) {
    return { data: response.data.data, status: response.data.status };
  });
};


export const FindExamByCustomer = async (customer, page) => {
  return await api.get(`/exam/c/${customer}?page=${page}`).then(function (response) {
    return { data: response.data.data.info, pagination: response.data.data.pagination, status: response.data.status };
  });
};

export const FindExamByCurrentCustomer = async (id, page, filter) => {
  return await api.get(`/c/exam?animal_id=${filter.pet ?? ''}&page=${page}`).then(function (response) {
    return { data: response.data.data.info, pagination: response.data.data.pagination, status: response.data.status };
  });
};



export const FindAnimalByCurrentCustomer = async () => {
  return await api.get(`/c/animals`).then(function (response) {
    return { data: response.data.data.info, pagination: response.data.data.pagination, status: response.data.status };
  });
};

export const FindExamById = async (id, password) => {
  return await api.get(`/exam/i/${id}`).then(function (response) {
    return { data: response.data.data, status: response.data.status };
  });
};

export const ResetExamPassword = async (id) => {
  return await api.get(`/exam/r/${id}`).then(function (response) {
    return { data: response.data.data, status: response.data.status };
  });
};

export const DeleteExam = async (id) => {
  return await api.delete(`/exam/${id}`).then(function (response) {
    return { data: response.data.data, status: response.data.status };
  });
};
