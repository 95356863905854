import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { GetCurrentUser } from "../../../services/UserService";
import { GetCurrentCustomer } from "../../../services/CustomerService";

function PrivateCustomerOutlet() {
  const token = localStorage.getItem("token") || sessionStorage.getItem("token");

  if (!token) {
    return <Navigate to='/customer/login' />;
  }

  const { data } = GetCurrentCustomer();

  if (data !== "Unauthorized") return <Outlet />;

  return <Navigate to='/customer/login' />;
}

export default PrivateCustomerOutlet;
