import axios from "axios";
import { useEffect, useState } from "react";
import api from "../api";

export const FetchServices = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    api
      .get(`list/services`)
      .then((response) => {
        if (!response.data.status === "error") setIsEmpty(true);

        setData(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsEmpty(true);
        setIsLoading(false);
      });
  }, []);
  return { data, isLoading, isEmpty };
};

export const FindActiveService = (page, filter, refreshState) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    api
      .get(`service?page=${page}`)
      .then((response) => {
        response.data.length === 0 ? setIsEmpty(true) : setIsEmpty(false);
        setData(response.data.data.info);
        setPagination(response.data.data.pagination);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsEmpty(true);
        setIsLoading(false);
      });
  }, [page, filter, refreshState]);

  return { data, pagination, isLoading, isEmpty };
};

export const CreateService = async (name, storage, unit, type, price, buy_cost, users) => {

  (buy_cost === "" ? buy_cost = 0 : buy_cost = buy_cost);
  return await api.post("/service", {
    name: name,
    storage: type === 0 ? storage = 0 : storage,
    unit: type === 0 ? unit = 3 : unit,
    price: price,
    buy_cost: buy_cost,
    users: users

  })
    .then(function (response) {
      return { data: response.data.data, status: response.data.status };
    });
};

export const UpdateService = async (id, name, storage, unit, price, buy_cost, users) => {
  return await api
    .put(`/service/${id}`, {
      name: name,
      storage: storage,
      unit: unit,
      price: price,
      buy_cost: buy_cost,
      users: users

    }).then(function (response) {
      return { data: response.data.data, status: response.data.status };
    });

};

export const FindService = async (id) => {
  return await api.get(`/service/${id}`).then(function (response) {
    return { data: response.data.data, status: response.data.status };
  });
};

export const DeleteService = async (id) => {
  return await api.delete(`/service/${id}`).then(function (response) {
    return { data: response.data, status: response.data.status };
  });
};