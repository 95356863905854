import { useEffect, useState } from 'react';
import api from '../api'

export const FindActiveUsers = (page, filter, refreshState) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState([]);
    useEffect(() => {
        setIsLoading(true);
        api.get(`/user?page=${page}&username=${filter.search ?? ''} `)
            .then(response => {
                response.data.length === 0 ? setIsEmpty(true) : setIsEmpty(false);
                setData(response.data.data.info);
                setPagination(response.data.data.pagination);
                setIsLoading(false);

            })
            .catch(error => {
                setIsEmpty(true);
                setIsLoading(false);
            });
    }, [page, filter, refreshState])

    return { data, pagination, isLoading, isEmpty };
}


export const FindUser = async (id) => {
    return await api.get(`/user/${id}`).then(function (response) {
        return { data: response.data.data, status: response.data.status };
    });
};

export const CreateUser = async (username, password, password_confirmation, name, email, role, crmv, crmvS, mapa) => {
    return await api.post('/user', {
        username: username,
        password: password,
        password_confirmation: password_confirmation,
        name: name,
        email: email,
        role_id: role,
        crmv: crmv,
        crmvS: crmvS,
        mapa: mapa,

    });
};

export const UpdateUser = async (id, username, name, email, role, crmv, crmvS, mapa) => {
    return await api.put(`/user/${id}`, {
        username: username,
        name: name,
        email: email,
        role_id: role,
        crmv: crmv,
        crmv_state: crmvS,
        mapa: mapa
    }).then(function (response) {
        return { data: response.data.data, status: response.data.status };
      });
    

};

export const resetPassword = async (id) => {
    const response = await api.put(`/user/p/${id}`);
    return response;
};

export const DeleteUser = async (id) => {
    return await api.delete(`/user/${id}`).then(function (response) {
        return { data: response.data.data, status: response.data.status };
    });
};

export const GetCurrentUser = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    useEffect(() => {
        setIsLoading(true);
        api.get('/who-am-i')
            .then(response => {
                setData(response.data);
                setIsLoading(false);

            })
            .catch(error => {
                setIsLoading(false);

                if (error.response.status === 401) setData('Unauthorized');


            });
    }, [])

    return { data, isLoading };
}

export const FetchUsers = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);
    const [data, setData] = useState([]);
    useEffect(() => {
        setIsLoading(true);
        api.get(`/list/users`)
            .then(response => {
                if (!response.data.status === "error") setIsEmpty(true);

                setData(response.data.data);
                setIsLoading(false);

            })
            .catch(error => {
                setIsEmpty(true);
                setIsLoading(false);
            });
    }, [])
    return { data, isLoading, isEmpty };
}

export const FetchRoles = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);
    const [data, setData] = useState([]);
    useEffect(() => {
        setIsLoading(true);
        api.get(`/list/roles`)
            .then(response => {
                if (!response.data.status === "error") setIsEmpty(true);

                setData(response.data.data.data);
                setIsLoading(false);

            })
            .catch(error => {
                setIsEmpty(true);
                setIsLoading(false);
            });
    }, [])
    return { data, isLoading, isEmpty };
}

export const AuthenticateUser = async (username, password, remember = false) => {
    const response = await api.post('/login', {
        username: username,
        password: password,
    });

    localStorage.clear();
    sessionStorage.clear();

    if (remember) {
        localStorage.setItem("token", `Bearer ${response.data.data.token}`);
    } else {
        sessionStorage.setItem("token", `Bearer ${response.data.data.token}`);
    }


    return response;
};

export const LogoutUser = async () => {
    await api.post('/logout');

    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
};