import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCreditCard,
  faDog,
  faEllipsisH, faEye, faFile, faFileArchive, faList, faReceipt
} from "@fortawesome/free-solid-svg-icons";
import { Nav, Card, Button, Table, Dropdown, Pagination, ButtonGroup, Badge, Spinner, Col, Row } from "react-bootstrap";
import { FindActiveCustomer } from "../services/CustomerService";
import { FindActiveAnimal, FindAnimalByCustomer } from "../services/AnimalService";
import { FindActiveExam, FindExamByCustomer } from "../services/ExamService";
import { FindActivePayment } from "../services/PaymentService";
import {
  AnimalDetailModal,
  CheckExamPasswordModal,
  CommissionDetailModal,
  CustomerDetailModal,
  DeleteAnimalModal,
  DeleteCustomerModal,
  DeleteExamModal,
  DeleteServiceModal,
  DeleteTicketModal,
  DeleteUserModal, ExamDetailModal, ResetExamPasswordModal,
  ServiceDetailModal,
  TicketAddPaymentModal,
  TicketDetailModal,
  UserDetailModal
} from "./Modals";
import { FindActiveUsers } from "../services/UserService";
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { Link } from "react-router-dom";
import { AppRoutes } from "../routes";
import { toast } from "react-toastify";
import { useEffect } from "react";
import moment from "moment-timezone";
import { FindActiveService } from "../services/ServiceService";
import { PaymentWidget } from "./Widgets";
import { DeleteTicket, FindTicketByCustomer } from "../services/TicketService";
import { FindActiveCommission } from "../services/FinanceService";


export const CustomerTable = ({ filter }) => {
  const [page, setPage] = useState(1);
  const [refreshState, setRefreshState] = useState();
  const { data, pagination, isLoading, isEmpty } = FindActiveCustomer(page, filter, refreshState);

  const renderTable = () => {
    return data.map((customer) => {
      return (
        <tr key={customer.id}>
          <td>
            <span className='fw-bold text-info'>#{customer.id.toString().padStart(5, 0)}</span>
          </td>
          <td>
            <span className='fw-normal'>{customer.name}</span>
          </td>
          <td>
            <span className='fw-normal'>{customer.cpf}</span>
          </td>
          <td>
            <span className='fw-normal'>{customer.rg}</span>
          </td>
          <td>
            <span className='fw-bold text-info'>{customer.email}</span>
          </td>
          <td>
            <span className='fw-normal'>{customer.phone}</span>
          </td>
          <td>
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle as={Button} split variant='link' className='text-dark m-0 p-0'>
                <span className='icon icon-sm'>
                  <FontAwesomeIcon icon={faEllipsisH} className='icon-dark' />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item className='text-info'>
                  <Link to={{
                    pathname: `/customer/${customer.id}`,
                  }}>                    <FontAwesomeIcon icon={faEye} /> Detalhes
                  </Link>

                  {/* <CustomerDetailModal id={customer.id} setRefreshState={setRefreshState} refreshState={refreshState} /> */}
                </Dropdown.Item>

                <Dropdown.Item className='text-danger'>
                  <DeleteCustomerModal id={customer.id} setRefreshState={setRefreshState} refreshState={refreshState} />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </td>
        </tr>
      );
    });
  };

  return (
    <Card border='light' className='table-wrapper table-responsive shadow-sm'>
      <Card.Body className='pt-0'>
        <Table hover className='align-items-center justify-content-center'>
          <thead>
            <tr>
              <th className='border-bottom'>ID CLIENTE</th>
              <th className='border-bottom'>Nome</th>
              <th className='border-bottom'>CPF</th>
              <th className='border-bottom'>RG</th>
              <th className='border-bottom'>E-MAIL</th>
              <th className='border-bottom'>Telefone</th>
              <th className='border-bottom'>Ações</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan='100%' className='text-center'>
                  <Spinner animation='border' role='status' />
                </td>
              </tr>
            ) : isEmpty ? (
              <tr>
                <td colSpan='100%' className='text-center'>
                  <span className='mt-2'>Não há registros cadastrados</span>
                </td>
              </tr>
            ) : (
              <>{renderTable()}</>
            )}
          </tbody>
        </Table>

        <Card.Footer className='px-3 border-0 d-lg-flex align-items-center justify-content-between'>
          <PaginationControl
            page={pagination.current_page}
            between={4}
            total={pagination.total}
            limit={pagination.per_page}
            changePage={(page) => {
              setPage(page);
            }}
            ellipsis={1}
          />
        </Card.Footer>
      </Card.Body>
    </Card>
  );
};

export const ServiceTable = ({ filter }) => {
  const [page, setPage] = useState(1);
  const [refreshState, setRefreshState] = useState();
  const { data, pagination, isLoading, isEmpty } = FindActiveService(page, filter, refreshState);

  const renderTable = () => {
    return data.map((service) => {

      const unit = service.unit == 0 ? "KG" : "Unitário";

      return (
        <tr key={service.id}>
          <td>
            <span className='fw-bold text-info'>#{service.id.toString().padStart(5, 0)}</span>
          </td>
          <td>
            <span className='fw-normal'>{service.name}</span>
          </td>
          <td>
            <span className='fw-normal'>R$ {service.price}</span>
          </td>
          {service.unit == 3 ?
            <>
              <td>
                <span className='fw-normal text-success'>ILIMITADO</span>
              </td>
              <td>
                <span className='fw-bold text-info'>Serviço</span>
              </td>
            </>
            :
            <>
              <td>
                <span className='fw-normal'>{service.storage}</span>
              </td>
              <td>
                <span className='fw-bold text-info'>{unit}</span>
              </td>
            </>
          }

          <td>
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle as={Button} split variant='link' className='text-dark m-0 p-0'>
                <span className='icon icon-sm'>
                  <FontAwesomeIcon icon={faEllipsisH} className='icon-dark' />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item className='text-info'>
                  <ServiceDetailModal id={service.id} setRefreshState={setRefreshState} refreshState={refreshState} />
                </Dropdown.Item>

                <Dropdown.Item className='text-danger'>
                  <DeleteServiceModal id={service.id} setRefreshState={setRefreshState} refreshState={refreshState} />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </td>
        </tr>
      );
    });
  };

  return (
    <Card border='light' className='table-wrapper table-responsive shadow-sm'>
      <Card.Body className='pt-0'>
        <Table hover className='align-items-center justify-content-center'>
          <thead>
            <tr>
              <th className='border-bottom'>ID SERVIÇO</th>
              <th className='border-bottom'>Nome</th>
              <th className='border-bottom'>Preço</th>
              <th className='border-bottom'>Estoque</th>
              <th className='border-bottom'>Unidade</th>
              <th className='border-bottom'>Ações</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan='100%' className='text-center'>
                  <Spinner animation='border' role='status' />
                </td>
              </tr>
            ) : isEmpty ? (
              <tr>
                <td colSpan='100%' className='text-center'>
                  <span className='mt-2'>Não há registros cadastrados</span>
                </td>
              </tr>
            ) : (
              <>{renderTable()}</>
            )}
          </tbody>
        </Table>

        <Card.Footer className='px-3 border-0 d-lg-flex align-items-center justify-content-between'>
          <PaginationControl
            page={pagination.current_page}
            between={4}
            total={pagination.total}
            limit={pagination.per_page}
            changePage={(page) => {
              setPage(page);
            }}
            ellipsis={1}
          />
        </Card.Footer>
      </Card.Body>
    </Card>
  );
};

export const PaymentTable = ({ filter, setRefreshState, refreshState }) => {
  const [showDefault, setShowDefault] = useState(false);
  const [page, setPage] = useState(1);
  const handleClose = () => setShowDefault(false);

  const { data, pagination, isLoading, isEmpty } = FindActivePayment(page, filter, refreshState);

  const renderWidgets = () => {
    return data.map(payment => {
      return (
        <Col xs={4} className="mb-4 d-none d-sm-block">
          <PaymentWidget
            id={payment.id}
            icon={faCreditCard}
            iconColor={payment.background_color}
            name={payment.name}
            tax={payment.tax}
            timesUsed={payment.times_used}
            setRefreshState={setRefreshState}
          />
        </Col>
      );
    });
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body>
        <Row>
          {isLoading ? (
            <Col md={11} className="d-flex align-items-center justify-content-center mt-2 position-absolute col-12">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </Col>
          ) : isEmpty ? (
            <Col md={11} className="d-flex align-items-center justify-content-center mt-2 position-absolute col-12">
              <span className="mt-2">Não há registros cadastrados</span>
            </Col>
          ) : (
            <>
              {renderWidgets()}
            </>
          )}
        </Row>
      </Card.Body>
      <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
        <PaginationControl
          page={pagination.current_page}
          between={4}
          total={pagination.total}
          limit={pagination.per_page}
          changePage={(page) => {
            setPage(page);
          }}
          ellipsis={1}
        />
        {!isLoading && !isEmpty && (
          <small className="fw-bold ms-auto">
            Mostrando <b>{pagination.count}</b> de <b>{pagination.total}</b> registros
          </small>
        )}
      </Card.Footer>
    </Card>
  );
};

export const CommissionTable = ({ filter }) => {
  const [page, setPage] = useState(1);
  const [refreshState, setRefreshState] = useState();
  const { data, pagination, isLoading, isEmpty } = FindActiveCommission(page, filter, refreshState);

  const renderTable = () => {
    return data.map((commission) => {
      return (
        <tr key={commission.id}>
          <td>
            <span className='fw-bold text-info'>#{commission.id.toString().padStart(5, 0)}</span>
          </td>
          <td>
            <span className='fw-normal'>{commission.user.username}</span>
          </td>
          <td>
            <span className='fw-normal'>{commission.service.name}</span>
          </td>
          <td>
            <span className='fw-bold text-success'>R$ {commission.commission}</span>
          </td>
          <td>
            <span className='fw-bold'>#{commission.ticket.id.toString().padStart(5, 0)}</span>
          </td>
          <td>
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle as={Button} split variant='link' className='text-dark m-0 p-0'>
                <span className='icon icon-sm'>
                  <FontAwesomeIcon icon={faEllipsisH} className='icon-dark' />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item className='text-info'>
                  <CommissionDetailModal id={commission.id} setRefreshState={setRefreshState} refreshState={refreshState} />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </td>
        </tr>
      );
    });
  };

  return (
    <Card border='light' className='table-wrapper table-responsive shadow-sm'>
      <Card.Body className='pt-0'>
        <Table hover className='user-table align-items-center text-center'>
          <thead>
            <tr>
              <th className='border-bottom'>ID</th>
              <th className='border-bottom'>Colaborador</th>
              <th className='border-bottom'>Serviço</th>
              <th className='border-bottom'>Comissão</th>
              <th className='border-bottom'>ID Ticket</th>
              <th className='border-bottom'>Ações</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan='100%' className='text-center'>
                  <Spinner animation='border' role='status' />
                </td>
              </tr>
            ) : isEmpty ? (
              <tr>
                <td colSpan='100%' className='text-center'>
                  <span className='mt-2'>Não há registros cadastrados</span>
                </td>
              </tr>
            ) : (
              <>{renderTable()}</>
            )}
          </tbody>
        </Table>

        <Card.Footer className='px-3 border-0 d-lg-flex align-items-center justify-content-between'>
          <PaginationControl
            page={pagination.current_page}
            between={4}
            total={pagination.total}
            limit={pagination.per_page}
            changePage={(page) => {
              setPage(page);
            }}
            ellipsis={1}
          />
        </Card.Footer>
      </Card.Body>
    </Card>
  );
};

export const AnimalTable = ({ filter }) => {
  const [page, setPage] = useState(1);
  const [refreshState, setRefreshState] = useState();
  const { data, pagination, isLoading, isEmpty } = FindActiveAnimal(page, filter, refreshState);

  const renderTable = () => {
    return data.map((animal) => {
      const deceasedColor = animal.deceased === 1 ? "text-danger" : "text-success";
      const deceased = animal.deceased === 0 ? "Não" : "Sim";

      return (
        <tr key={animal.id}>
          <td>
            <span className='fw-bold text-info'>#{animal.id.toString().padStart(5, 0)}</span>
          </td>
          <td>
            <span className='fw-normal'>{animal.name}</span>
          </td>
          <td>
            <span className='fw-normal'>{animal.owner.name}</span>
          </td>
          <td>
            <span className='fw-normal'>{animal.owner.cpf}</span>
          </td>
          <td>
            <span className={`fw-bold ${deceasedColor}`}>{deceased}</span>
          </td>
          <td>
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle as={Button} split variant='link' className='text-dark m-0 p-0'>
                <span className='icon icon-sm'>
                  <FontAwesomeIcon icon={faEllipsisH} className='icon-dark' />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item className='text-info'>
                  <AnimalDetailModal id={animal.id} setRefreshState={setRefreshState} refreshState={refreshState} />
                </Dropdown.Item>

                <Dropdown.Item className='text-danger'>
                  <DeleteAnimalModal id={animal.id} setRefreshState={setRefreshState} refreshState={refreshState} />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </td>
        </tr>
      );
    });
  };

  return (
    <Card border='light' className='table-wrapper table-responsive shadow-sm'>
      <Card.Body className='pt-0'>
        <Table hover className='user-table align-items-center'>
          <thead>
            <tr>
              <th className='border-bottom'>ID ANIMAL</th>
              <th className='border-bottom'>Nome do Animal</th>
              <th className='border-bottom'>Dono</th>
              <th className='border-bottom'>CPF</th>
              <th className='border-bottom'>Óbito</th>
              <th className='border-bottom'>Ações</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan='100%' className='text-center'>
                  <Spinner animation='border' role='status' />
                </td>
              </tr>
            ) : isEmpty ? (
              <tr>
                <td colSpan='100%' className='text-center'>
                  <span className='mt-2'>Não há registros cadastrados</span>
                </td>
              </tr>
            ) : (
              <>{renderTable()}</>
            )}
          </tbody>
        </Table>

        <Card.Footer className='px-3 border-0 d-lg-flex align-items-center justify-content-between'>
          <PaginationControl
            page={pagination.current_page}
            between={4}
            total={pagination.total}
            limit={pagination.per_page}
            changePage={(page) => {
              setPage(page);
            }}
            ellipsis={1}
          />
        </Card.Footer>
      </Card.Body>
    </Card>
  );
};

export const ExamTable = ({ filter }) => {
  const [page, setPage] = useState(1);
  const [refreshState, setRefreshState] = useState();
  const { data, pagination, isLoading, isEmpty } = FindActiveExam(page, refreshState);

  const renderTable = () => {
    return data.map((exam) => {
      return (
        <tr key={exam.id}>
          <td>
            <span className='fw-bold text-info'>#{exam.id.toString().padStart(5, 0)}</span>
          </td>
          <td>
            <span className='fw-normal'>{exam.pet.name}</span>
          </td>
          <td>
            <span className='fw-normal'>{exam.pet.owner.name}</span>
          </td>
          <td>
            <span className='fw-normal'>{exam.pet.owner.cpf}</span>
          </td>
          <td>
            <span className='fw-bold text-info'>{exam.pet.tag}</span>
          </td>
          <td>
            <span className='fw-normal'>{exam.created_at}</span>
          </td>
          <td>
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle as={Button} split variant='link' className='text-dark m-0 p-0'>
                <span className='icon icon-sm'>
                  <FontAwesomeIcon icon={faEllipsisH} className='icon-dark' />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item className='text-info'>
                  <ExamDetailModal id={exam.id} setRefreshState={setRefreshState} refreshState={refreshState} />
                </Dropdown.Item>
                <Dropdown.Item className='text-danger'>
                  <DeleteExamModal id={exam.id} setRefreshState={setRefreshState} refreshState={refreshState} />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </td>
        </tr>
      );
    });
  };

  return (
    <Card border='light' className='table-wrapper table-responsive shadow-sm'>
      <Card.Body className='pt-0'>
        <Table hover className='user-table align-items-center'>
          <thead>
            <tr>
              <th className='border-bottom'>ID Exame</th>
              <th className='border-bottom'>Nome do Animal</th>
              <th className='border-bottom'>Dono</th>
              <th className='border-bottom'>CPF</th>
              <th className='border-bottom'>Nº Carteirinha</th>
              <th className='border-bottom'>Data do Exame</th>
              <th className='border-bottom'>Ações</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan='100%' className='text-center'>
                  <Spinner animation='border' role='status' />
                </td>
              </tr>
            ) : isEmpty ? (
              <tr>
                <td colSpan='100%' className='text-center'>
                  <span className='mt-2'>Não há registros cadastrados</span>
                </td>
              </tr>
            ) : (
              <>{renderTable()}</>
            )}
          </tbody>
        </Table>

        <Card.Footer className='px-3 border-0 d-lg-flex align-items-center justify-content-between'>
          <PaginationControl
            page={pagination.current_page}
            between={4}
            total={pagination.total}
            limit={pagination.per_page}
            changePage={(page) => {
              setPage(page);
            }}
            ellipsis={1}
          />
        </Card.Footer>
      </Card.Body>
    </Card>
  );
};

export const WorkerTable = ({ filter, refreshState, setRefreshState }) => {
  const [page, setPage] = useState(1);
  const { data, pagination, isLoading, isEmpty } = FindActiveUsers(page, filter, refreshState);

  const renderTable = () => {
    return data.map((user) => {
      return (
        <tr key={user.id}>
          <td>
            <span className="fw-bold text-info">#{user.id.toString().padStart(5, 0)}</span>
          </td>
          <td>
            <span className="fw-normal">{user.username}</span>
          </td>
          <td>
            <span className="fw-normal">{user.name}</span>
          </td>
          <td>
            <span className="fw-normal">{user.email}</span>
          </td>
          <td>
            <span className="fw-normal">{user.role.name}</span>
          </td>
          <td>
            <span className="fw-normal">
              <Badge bg="success" className="badge me-1">
                {user.created_at}
              </Badge>
            </span>
          </td>
          <td>
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle
                as={Button}
                split
                variant="link"
                className="text-dark m-0 p-0"
              >
                <span className="icon icon-sm">
                  <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item className="text-info">
                  <UserDetailModal id={user.id} setRefreshState={setRefreshState} refreshState={refreshState} />
                </Dropdown.Item>
                <Dropdown.Item className="text-danger">
                  <DeleteUserModal id={user.id} setRefreshState={setRefreshState} refreshState={refreshState} />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </td>
        </tr>
      );
    });
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">#</th>
              <th className="border-bottom">Usuário</th>
              <th className="border-bottom">Nome</th>
              <th className="border-bottom">E-mail</th>
              <th className="border-bottom">Função</th>
              <th className="border-bottom">Admissão</th>
              <th className="border-bottom">Ações</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="100%" className="text-center">
                  <Spinner animation="border" role="status" />
                </td>
              </tr>
            ) : isEmpty ? (
              <tr>
                <td colSpan="100%" className="text-center">
                  <span className="mt-2">Não há registros cadastrados</span>
                </td>
              </tr>
            ) : (
              <>{renderTable()}</>
            )}
          </tbody>
        </Table>
      </Card.Body>
      <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
        <PaginationControl
          page={pagination.current_page}
          between={4}
          total={pagination.total}
          limit={pagination.per_page}
          changePage={(page) => {
            setPage(page);
          }}
          ellipsis={1}
        />
      </Card.Footer>
    </Card>
  );
};

export const CustomerExamTable = ({ id, refreshState, setRefreshState }) => {
  const [pagination, setPagination] = useState([]);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    getExamData(id);
  }, [id, page, refreshState]);

  const getExamData = async (id) => {
    setLoading(true);

    if (id) {
      try {
        const { data, pagination, status } = await FindExamByCustomer(id, page);
        if (status === "success") {
          setLoading(false);
          setPagination(pagination);

          if (data.length > 0) {
            setData(data);
          } else {
            setIsEmpty(true);
          }

        }
      } catch (err) {
        toast.error("Ocorreu um erro ao buscar os exames!");
      }
    };
  }


  const renderTable = () => {
    if (data) {
      return data.map((exam) => {
        return (
          <tr key={exam.id}>
            <td>
              <span className='fw-bold text-info'>#{exam.id.toString().padStart(5, 0)}</span>
            </td>
            <td>
              <span className='fw-normal'>{exam.pet.name}</span>
            </td>
            <td>
              <span className='fw-normal'>{exam.created_at}</span>
            </td>
            <td>
              <Dropdown as={ButtonGroup}>
                <Dropdown.Toggle as={Button} split variant='link' className='text-dark m-0 p-0'>
                  <span className='icon icon-sm'>
                    <FontAwesomeIcon icon={faEllipsisH} className='icon-dark' />
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item className='text-info'>
                    <ExamDetailModal id={exam.id} />
                  </Dropdown.Item>
                  <Dropdown.Item className='text-danger'>
                    <DeleteExamModal id={exam.id} setRefreshState={setRefreshState} refreshState={refreshState} />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr>
        );
      });
    }
  };
  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm p-0">
      <Card.Body className="pt-0">
        <Table hover responsive className='align-items-center'>
          <thead>
            <tr>
              <th className='border-bottom'>ID</th>
              <th className='border-bottom'>Animal</th>
              <th className='border-bottom'>Data da Consulta</th>
              <th className='border-bottom'>Ações</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="100%" className="text-center">
                  <Spinner animation="border" role="status" />
                </td>
              </tr>
            ) : isEmpty ? (
              <tr>
                <td colSpan='100%' className='text-center'>
                  <span className='mt-2'>Não há registros cadastrados</span>
                </td>
              </tr>
            ) : (
              <>{renderTable()}</>
            )}
          </tbody>
        </Table>
      </Card.Body>
      <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
        <PaginationControl
          page={pagination.current_page}
          between={4}
          total={pagination.total}
          limit={pagination.per_page}
          changePage={(page) => {
            setPage(page);
          }}
          ellipsis={1}
        />
      </Card.Footer>
    </Card>
  );
};

export const CustomerTicketTable = ({ id, refreshState, setRefreshState }) => {
  const [pagination, setPagination] = useState([]);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    getExamData(id);
  }, [id, page, refreshState]);

  const getExamData = async (id) => {
    setLoading(true);

    if (id) {
      try {
        const { data, pagination, status } = await FindTicketByCustomer(id, page);
        if (status === "success") {
          setLoading(false);
          setPagination(pagination);

          if (data.length > 0) {
            setData(data);
          } else {
            setIsEmpty(true);
          }

        }
      } catch (err) {
        toast.error("Ocorreu um erro ao buscar os tickets!");
      }
    };
  }


  const renderTable = () => {
    if (data) {
      return data.map((ticket) => {
        const ticketColor = ticket.status === 'Pendente' ? "text-danger" : "text-success";

        return (
          <tr key={ticket.id}>
            <td>
              <span className='fw-bold text-info'>#{ticket.id.toString().padStart(5, 0)}</span>
            </td>
            <td>
              <span className='fw-normal'>{ticket.animal.name}</span>
            </td>
            <td>
              <span className='fw-normal'>{ticket.created_at}</span>
            </td>
            <td>
              <span className={`fw-bold ${ticket.paid_amount === ticket.total ? 'text-info' : 'text-success'}`}>{ticket.paid_amount === ticket.total ? 'PAGO' : `R$ ${ticket.total - ticket.paid_amount}`}</span>
            </td>
            <td>
              <span className={`fw-bold ${ticketColor}`}>{ticket.status}</span>
            </td>
            <td>
              <Dropdown as={ButtonGroup}>
                <Dropdown.Toggle as={Button} split variant='link' className='text-dark m-0 p-0'>
                  <span className='icon icon-sm'>
                    <FontAwesomeIcon icon={faEllipsisH} className='icon-dark' />
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {ticket.paid_amount != ticket.total &&
                    <Dropdown.Item className='text-success'>
                      <TicketAddPaymentModal id={ticket.id} refreshState={refreshState} setRefreshState={setRefreshState} />
                    </Dropdown.Item>
                  }
                  <Dropdown.Item className='text-info'>
                    <TicketDetailModal id={ticket.id} refreshState={refreshState} setRefreshState={setRefreshState} />
                  </Dropdown.Item>
                  <Dropdown.Item className='text-danger'>
                    <DeleteTicketModal id={ticket.id} setRefreshState={setRefreshState} refreshState={refreshState} />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr >
        );
      });
    }
  };
  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm p-0">
      <Card.Body className="pt-0">
        <Table hover className='align-items-center'>
          <thead>
            <tr>
              <th className='border-bottom'>ID</th>
              <th className='border-bottom'>Animal</th>
              <th className='border-bottom'>Data do Ticket</th>
              <th className='border-bottom'>Valor a Pagar</th>
              <th className='border-bottom'>Status</th>
              <th className='border-bottom'>Ações</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="100%" className="text-center">
                  <Spinner animation="border" role="status" />
                </td>
              </tr>
            ) : isEmpty ? (
              <tr>
                <td colSpan='100%' className='text-center'>
                  <span className='mt-2'>Não há registros cadastrados</span>
                </td>
              </tr>
            ) : (
              <>{renderTable()}</>
            )}
          </tbody>
        </Table>
      </Card.Body>
      <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
        <PaginationControl
          page={pagination.current_page}
          between={4}
          total={pagination.total}
          limit={pagination.per_page}
          changePage={(page) => {
            setPage(page);
          }}
          ellipsis={1}
        />
      </Card.Footer>
    </Card>
  );
};

export const CustomerAnimalTable = ({ id, refreshState, setRefreshState }) => {
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    findAnimalfromId();
  }, [id, page, refreshState]);

  let findAnimalfromId = async (e) => {
    setLoading(true);

    if (id) {
      try {
        const { data, pagination, status } = await FindAnimalByCustomer(id, page);
        if (status === "success") {
          setLoading(false);
          setPagination(pagination);

          if (data.length > 0) {
            setData(data);
          } else {
            setIsEmpty(true);
          }

        }
      } catch (error) {
        toast.error("O tutor selecionado não possui nenhum pet cadastrado!");
      }
    };
  }


  const renderTable = () => {
    if (data) {
      return data.map((animal) => {

        var m = moment(animal.born_at, "DD/MM/YYYY");
        var years = moment().diff(m, 'years', false);
        var months = moment().diff(m.add(years, 'years'), 'months', false);

        const AnimalAge = (years + ' anos, ' + months + ' meses');

        const deceasedColor = animal.deceased === 1 ? "text-danger" : "text-success";
        const deceased = animal.deceased === 0 ? "Não" : "Sim";

        return (
          <tr key={animal.id}>
            <td>
              <span className='fw-bold text-info'>#{animal.id.toString().padStart(5, 0)}</span>
            </td>
            <td>
              <span className='fw-normal'>{animal.name}</span>
            </td>
            <td>
              <span className='fw-normal'>{animal.gender}</span>
            </td>
            <td>
              <span className='fw-normal'>{animal.born_at}</span>
            </td>
            <td>
              <span className='fw-normal'>{AnimalAge}</span>
            </td>
            <td>
              <span className='fw-normal'>{animal.color.name}</span>
            </td>
            <td>
              <span className='fw-normal'>{animal.race.name}</span>
            </td>
            <td>
              <span className='fw-normal'>{animal.species.name}</span>
            </td>
            <td>
              <span className={`fw-bold ${deceasedColor}`}>{deceased}</span>
            </td>
            <td>
              <Dropdown as={ButtonGroup}>
                <Dropdown.Toggle as={Button} split variant='link' className='text-dark m-0 p-0'>
                  <span className='icon icon-sm'>
                    <FontAwesomeIcon icon={faEllipsisH} className='icon-dark' />
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item className='text-info'>
                    <AnimalDetailModal id={animal.id} setRefreshState={setRefreshState} refreshState={refreshState} />
                  </Dropdown.Item>

                  <Dropdown.Item className='text-danger'>
                    <DeleteAnimalModal id={animal.id} setRefreshState={setRefreshState} refreshState={refreshState} />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr>
        );
      });
    }
  };
  return (
    <>
      <Card border="light" className="table-wrapper table-responsive shadow-sm p-0">
        <Card.Body className="pt-0">
          <Table hover className='align-items-center'>
            <thead>
              <tr>
                <th className='border-bottom'>ID</th>
                <th className='border-bottom'>Nome</th>
                <th className='border-bottom'>Genero</th>
                <th className='border-bottom'>Dt. Nascimento</th>
                <th className='border-bottom'>Idade</th>
                <th className='border-bottom'>Pelagem</th>
                <th className='border-bottom'>Raça</th>
                <th className='border-bottom'>Espécie</th>
                <th className='border-bottom'>Óbito</th>
                <th className='border-bottom'>Ações</th>
              </tr>
            </thead>
            <tbody>{isLoading ? (
              <tr>
                <td colSpan="100%" className="text-center">
                  <Spinner animation="border" role="status" />
                </td>
              </tr>
            ) : isEmpty ? (
              <tr>
                <td colSpan='100%' className='text-center'>
                  <span className='mt-2'>Não há registros cadastrados</span>
                </td>
              </tr>
            ) : (
              <>{renderTable()}</>
            )
            }</tbody>
          </Table>
        </Card.Body >
        <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
          <PaginationControl
            page={pagination.current_page}
            between={4}
            total={pagination.total}
            limit={pagination.per_page}
            changePage={(page) => {
              setPage(page);
            }}
            ellipsis={1}
          />
        </Card.Footer>
      </Card >
    </>
  );
};
