import React, { useEffect, useState } from 'react';
import { Row, Col, InputGroup, Form, Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faFunnelDollar, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { CreateUser, FetchRoles, FetchUsers } from '../services/UserService';
import { Typeahead } from 'react-bootstrap-typeahead';
import { FetchCustomers } from '../services/CustomerService';
import { FetchAnimals } from '../services/AnimalService';
import { FindActiveExam } from '../services/ExamService';

export const WorkerFilter = ({ setFilter, refreshState, setRefreshState }) => {
    const [search, setSearch] = useState("");
    const [showDefault, setShowDefault] = useState(false);
    const handleClose = () => setShowDefault(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [role, setRole] = useState("");
    const [mapa, setMapa] = useState("");
    const [crmv, setCRMV] = useState("");
    const [crmvS, setCRMVS] = useState("");
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [password_confirmation, setPasswordConfirmation] = useState("");
    const [error, setError] = useState({});

    const { data: roles, isLoading: rolesLoading } = FetchRoles();

    const states = [
        { id: 0, name: "AC" },
        { id: 1, name: "AL" },
        { id: 2, name: "AP" },
        { id: 3, name: "AM" },
        { id: 4, name: "BA" },
        { id: 5, name: "CE" },
        { id: 6, name: "DF" },
        { id: 7, name: "ES" },
        { id: 8, name: "GO" },
        { id: 9, name: "MA" },
        { id: 10, name: "MT" },
        { id: 11, name: "MS" },
        { id: 12, name: "MG" },
        { id: 13, name: "PA" },
        { id: 14, name: "PB" },
        { id: 15, name: "PR" },
        { id: 16, name: "PE" },
        { id: 17, name: "PI" },
        { id: 18, name: "RJ" },
        { id: 19, name: "RN" },
        { id: 20, name: "RS" },
        { id: 21, name: "RO" },
        { id: 22, name: "RR" },
        { id: 23, name: "SC" },
        { id: 24, name: "SP" },
        { id: 25, name: "SE" },
        { id: 26, name: "TO" },
    ];

    /**
     * VAI USAR O USEEFFECT PARA TODA VEZ QUE O CAMPO SEARCH MUDAR, DAR TRIGGER NO HANDLEFILTER
     */
    useEffect(() => {
        handleFilter();
    }, [search]);

    let handleFilter = async (e) => {
        if (e) e.preventDefault();
        const response = await ({ search });
        setFilter(response);
    };

    let handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await CreateUser(username, password, password_confirmation, name, email, role, crmv, crmvS, mapa);
            if (response.data.status === 'success') {
                refreshState === true ? setRefreshState(false) : setRefreshState(true);
                setUsername("");
                setPassword("");
                setPasswordConfirmation("");
                toast.success('Colaborador cadastrado com sucesso!')
                handleClose();
                setError("");
            } else {
                throw new Error('Falha ao cadastrar funcionário');
            }
        } catch (err) {
            const errors = err.response.data;
            if (errors) {
                setError(errors.data);
                toast.warn('Verifique os campos obrigatórios!');
            } else {
                toast.error('Ocorreu um erro ao cadastrar o Colaborador!');

            };
        }
    };

    return (
        <div className="table-settings">
            <Row className="justify-content-between align-items-center">
                <Col xs={6}>
                    <InputGroup>
                        <InputGroup.Text>
                            <FontAwesomeIcon icon={faSearch} />
                        </InputGroup.Text>
                        <Form.Control type="text" placeholder="Procurar na Tabela" value={search} onChange={e => setSearch(e.target.value)} />
                    </InputGroup>
                </Col>
                <Col xs={6} className="ps-md-0 text-end">
                    <React.Fragment>
                        <Button variant="success" className="my-3 me-2" onClick={() => setShowDefault(true)}> <FontAwesomeIcon icon={faPlus} /> Cadastrar Funcionário</Button>
                        <Modal size={'xl'} as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
                            <Modal.Header>
                                <Modal.Title className="h6">Cadastro de Funcionário</Modal.Title>
                                <Button variant="close" aria-label="Close" onClick={handleClose} />
                            </Modal.Header>
                            <Form onSubmit={handleSubmit}>
                                <Modal.Body>
                                    <Row>
                                        <h5 className="mb-2">Dados do Funcionário</h5>
                                        <Col md={12} className="mb-3">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Usuário de Acesso</Form.Label>
                                                <Form.Control type="text" value={username} onChange={e => setUsername(e.target.value)} placeholder='Nome Exemplo' isInvalid={!!error.username} />
                                                <Form.Control.Feedback type="invalid">{error.username}</Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} className="mb-3">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Nome</Form.Label>
                                                <Form.Control type="text" value={name} onChange={e => setName(e.target.value)} placeholder='Nome Exemplo' isInvalid={!!error.username} />
                                                <Form.Control.Feedback type="invalid">{error.name}</Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} className="mb-3">
                                            <Form.Group className="mb-2">
                                                <Form.Label>E-mail</Form.Label>
                                                <Form.Control type="text" value={email} onChange={e => setEmail(e.target.value)} placeholder='Email Exemplo' isInvalid={!!error.email} />
                                                <Form.Control.Feedback type="invalid">{error.email}</Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col md={12} className="mb-3">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Função</Form.Label>
                                                <Typeahead
                                                    labelKey={(roles) => `${roles.name}`}
                                                    onChange={(value) => value[0] && setRole(value[0].id)}
                                                    options={roles}
                                                    placeholder='Escolha uma das funções'
                                                    isLoading={rolesLoading}
                                                    className='is-invalid'
                                                    isInvalid={!!error.role_id}
                                                />
                                                <Form.Control.Feedback type="invalid">{error.role_id}</Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} className="mb-3">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Senha</Form.Label>
                                                <Form.Control type="password" value={password} onChange={e => setPassword(e.target.value)} placeholder='Senha' isInvalid={!!error.password} />
                                                <Form.Control.Feedback type="invalid">{error.password}</Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} className="mb-3">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Confirmação de Senha</Form.Label>
                                                <Form.Control type="password" value={password_confirmation} onChange={e => setPasswordConfirmation(e.target.value)} placeholder='Confirmação de Senha' isInvalid={!!error.password} />
                                                <Form.Control.Feedback type="invalid">{error.password}</Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    {role == 3 || role == 2 &&
                                        <>
                                            <hr></hr>
                                            <h5 className="mb-2">Dados do Veterinário</h5>
                                            <Row>
                                                <Col md={6} className="mb-3">
                                                    <Form.Group className="mb-2">
                                                        <Form.Label>CRMV</Form.Label>
                                                        <Form.Control type="text" value={crmv} onChange={e => setCRMV(e.target.value)} placeholder='CRMV Exemplo' isInvalid={!!error.email} />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6} className="mb-3">
                                                    <Form.Group className="mb-2">
                                                        <Form.Label>CRMV Estado</Form.Label>
                                                        <Typeahead
                                                            labelKey={(states) => `${states.name}`}
                                                            onChange={(value) => value[0] && setCRMVS(value[0].id)}
                                                            options={states}
                                                            placeholder='Escolha um dos estados'
                                                            className='is-invalid'
                                                            isInvalid={!!error.crmvs}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={12} className="mb-3">
                                                    <Form.Group className="mb-2">
                                                        <Form.Label>Mapa</Form.Label>
                                                        <Form.Control type="text" value={mapa} onChange={e => setMapa(e.target.value)} placeholder='MAPA Exemplo' isInvalid={!!error.email} />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="primary" type='submit'>
                                        Cadastrar Dados
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </Modal>
                    </React.Fragment>
                </Col>

            </Row>
        </div>
    )

};

export const CustomerFilter = ({ setFilter, refreshState, setRefreshState }) => {
    const [search, setSearch] = useState("");

    useEffect(() => {
        handleFilter();
    }, [search]);

    let handleFilter = async (e) => {
        if (e) e.preventDefault();
        const response = await ({ search });
        setFilter(response);
    };

    return (
        <div className="table-settings my-3">
            <Row className="justify-content-between align-items-center">
                <Col xs={6}>
                    <InputGroup>
                        <InputGroup.Text>
                            <FontAwesomeIcon icon={faSearch} />
                        </InputGroup.Text>
                        <Form.Control type="text" placeholder="Procurar na Tabela" value={search} onChange={e => setSearch(e.target.value)} />
                    </InputGroup>
                </Col>
            </Row>
        </div>
    )

};

export const AnimalFilter = ({ setFilter, refreshState, setRefreshState }) => {
    const [search, setSearch] = useState("");

    useEffect(() => {
        handleFilter();
    }, [search]);

    let handleFilter = async (e) => {
        if (e) e.preventDefault();
        const response = await ({ search });
        setFilter(response);
    };

    return (
        <div className="table-settings my-3">
            <Row className="justify-content-between align-items-center">
                <Col xs={6}>
                    <InputGroup>
                        <InputGroup.Text>
                            <FontAwesomeIcon icon={faSearch} />
                        </InputGroup.Text>
                        <Form.Control type="text" placeholder="Procurar na Tabela" value={search} onChange={e => setSearch(e.target.value)} />
                    </InputGroup>
                </Col>
            </Row>
        </div>
    )

};

export const CommissionFilter = ({ setFilter, refreshState, setRefreshState }) => {
    const [worker, setWorker] = useState("");

    useEffect(() => {
        handleFilter();
    }, [worker]);

    let handleFilter = async (e) => {
        if (e) e.preventDefault();
        const response = await ({ worker });
        setFilter(response);
    };

    const { data: users, isLoading: usersLoading } = FetchUsers();


    return (
        <div className="table-settings my-3">
            <Row className="justify-content-between align-items-center">
                <Col xs={6}>
                    <Typeahead
                        id='users'
                        labelKey={(users) => `${users.username}`}
                        onChange={(value) => {
                            value[0] && setWorker(value[0].id)
                        }}
                        options={users}
                        isLoading={usersLoading}
                        placeholder='Digite para filtrar por colaborador'
                        className='is-invalid'
                    />
                </Col>
            </Row>
        </div >
    )

};

export const CustomerAnimalFilter = ({ id, setFilter, refreshState, setRefreshState }) => {
    const [pet, setPet] = useState("");
    useEffect(() => {
        handleFilter();
    }, [pet]);

    let handleFilter = async (e) => {
        if (e) e.preventDefault();
        const response = await ({ pet });
        setFilter(response);
    };

    const handleClearFilters = () => {
        setPet('');
    };

    const { data: animals, isLoading: animalsLoading, isEmpty: animalsEmpty } = FetchAnimals(id);


    return (
        <div className="table-settings my-3">
            <Row className="justify-content-between align-items-center">
                <Col xs={6}>
                    <Typeahead
                        id='users'
                        labelKey={(animal) => `${animal.name}`}
                        onChange={(value) => {
                            value[0] && setPet(value[0].id)
                        }}
                        options={animals}
                        isLoading={animalsLoading}
                        placeholder='Digite para filtrar por animal'
                        className='is-invalid'
                    />

                </Col>
                <Col><Button onClick={handleClearFilters}>Limpar Filtros</Button></Col>

            </Row>
        </div >
    )

};