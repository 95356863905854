import { useParams } from "react-router-dom";
import React, { useEffect, useState, Component } from "react";
import {
  Row,
  Form,
  Modal,
  Button,
  Col,
  InputGroup,
  Accordion,
  Spinner,
  Table,
  Breadcrumb,
  Card,
  Nav,
  Tab,
  Dropdown,
  ButtonGroup,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Datetime from "react-datetime";
import {
  faCalendarAlt,
  faPen,
  faEye,
  faTrashAlt,
  faWarning,
  faLock,
  faLockOpen,
  faUndo,
  faRefresh,
  faDog,
  faList,
  faHome,
  faUser,
  faEllipsisH,
  faChevronDown,
  faReceipt,
} from "@fortawesome/free-solid-svg-icons";
import "moment/locale/pt-br";
import { toast } from "react-toastify";
import { IMaskInput } from "react-imask";
import moment from "moment-timezone";
import { FindCustomer } from "../../../services/CustomerService";
import Skeleton from "react-loading-skeleton";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { DeleteExam, FindExamByCustomer, ResetExamPassword } from "../../../services/ExamService";
import {
  AnimalDetailModal,
  CheckExamPasswordModal,
  CreateAnimalModal,
  CreateExamModal,
  CustomerDetailModal,
  DeleteAnimalModal,
  DeleteExamModal,
  ExamDetailModal,
  ResetExamPasswordModal,
} from "../../../components/Modals";
import { FetchAnimals, FindAnimalByCustomer } from "../../../services/AnimalService";
import { PaginationControl } from "react-bootstrap-pagination-control";
import { CustomerAnimalTable, CustomerExamTable, CustomerTicketTable } from "../../../components/Tables";
import { Link } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";

export default () => {
  const params = useParams();


  const [refreshState, setRefreshState] = useState();
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [name, setName] = useState("");


  useEffect(() => {
    fillForm();
  }, [refreshState]);

  let fillForm = async () => {
    setLoading(true);

    try {
      const { data, status } = await FindCustomer(params.customerId);
      if (status === "success") {
        setLoading(false);
        setData(data);
        setName(data.name);
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao buscar o cliente!");
    }
  };

  return (
    <div className='justify-content-between flex-wrap flex-md-nowrap py-4'>
      <div className='mb-4 mb-lg-0'>
        <Breadcrumb className='d-none d-md-inline-block' listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
          <Breadcrumb.Item>
            <FontAwesomeIcon icon={faHome} />
          </Breadcrumb.Item>
          <Breadcrumb.Item>Tutores</Breadcrumb.Item>
          <Breadcrumb.Item active>{name}</Breadcrumb.Item>
        </Breadcrumb>

        <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
          <div className='d-block mb-4 mb-md-0'>
            <h4>Perfil do Paciente</h4>
          </div>
          <div className='btn-toolbar mb-2 mb-md-0'>
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle as={Button} split variant='link' className='text-dark m-0 p-0'>
                <span className='icon icon-sm btn btn-success'>
                  Opções Adicionais
                  <FontAwesomeIcon icon={faChevronDown} className='ms-2' />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item className='text-important'>
                  <CustomerDetailModal id={data.id} refreshState={refreshState} setRefreshState={setRefreshState} />
                </Dropdown.Item>
                <Dropdown.Item className='text-important'>
                  <CreateAnimalModal id={data.id} refreshState={refreshState} setRefreshState={setRefreshState} />
                </Dropdown.Item>
                <Dropdown.Item className='text-important'>
                  <CreateExamModal id={data.id} refreshState={refreshState} setRefreshState={setRefreshState} />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <TutorHeader data={data} isLoading={isLoading} refreshState={refreshState} />

        <section>
          <Card border='light' className='mt-3'>
            <Tab.Container defaultActiveKey='0'>
              <Row>
                <Col lg={12}>
                  <Nav className='nav-tabs'>
                    <Nav.Item>
                      <Nav.Link eventKey='0' className='mb-sm-3 mb-md-0'>
                        Animais
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey='1' className='mb-sm-3 mb-md-0'>
                        Exames
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey='2' className='mb-sm-3 mb-md-0'>
                        Tickets
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey='3' className='mb-sm-3 mb-md-0'>
                        Registrar Ticket
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col lg={12}>
                  <Tab.Content className='p-0'>
                    <Tab.Pane eventKey='0'>
                      <CustomerAnimalTable id={data.id} refreshState={refreshState} setRefreshState={setRefreshState} />
                    </Tab.Pane>
                    <Tab.Pane eventKey='1'>
                      <CustomerExamTable id={data.id} refreshState={refreshState} setRefreshState={setRefreshState} />
                    </Tab.Pane>
                    <Tab.Pane eventKey='2'>
                      <CustomerTicketTable id={data.id} refreshState={refreshState} setRefreshState={setRefreshState} />
                    </Tab.Pane>
                    <Tab.Pane eventKey='3'>
                      <RegisterTicket id={params.customerId} />
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Card>
        </section>
      </div>
    </div>
  );
};

export const TutorHeader = ({ data, isLoading, refreshState }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [cpf, setCPF] = useState("");
  const [street, setStreet] = useState("");
  const [district, setDistrict] = useState("");
  const [city, setCity] = useState("");
  const [cep, setCEP] = useState("");
  const [phone, setPhone] = useState("");
  const [knowledge, setKnowledge] = useState("");

  const SetCustomerData = (data) => {
    setName(data.name);
    setEmail(data.email);
    setCPF(data.cpf);
    setCEP(data.cep);
    setPhone(data.phone);
    setStreet(data.street);
    setDistrict(data.district);
    setCity(data.city);
    setKnowledge(data.knowledge);
  };


  useEffect(() => {
    SetCustomerData(data);
  }, [data]);

  const address = street + ", " + district + ", " + city + " - " + cep;

  return (
    <section className='mt-3'>
      <Card border='light' className='table-wrapper table-responsive shadow-sm'>
        <Card.Body>
          <div className='d-flex'>
            <FontAwesomeIcon size='10x' icon={faUser} color='black' border={true} />
            <Row className='ms-3'>
              <Col md={4}>
                <Form.Group>
                  <Form.Label>Nome</Form.Label>
                  {isLoading ? (
                    <Skeleton height={"40px"} width={"100%"} />
                  ) : (
                    <Form.Control type='text' placeholder='Nome Exemplo' disabled value={name} />
                  )}
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group>
                  <Form.Label>E-mail</Form.Label>
                  {isLoading ? (
                    <Skeleton height={"40px"} width={"100%"} />
                  ) : (
                    <Form.Control type='text' placeholder='Email Exemplo' disabled value={email} />
                  )}
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group>
                  <Form.Label>CPF</Form.Label>
                  {isLoading ? (
                    <Skeleton height={"40px"} width={"100%"} />
                  ) : (
                    <Form.Control type='text' placeholder='CPF Exemplo' disabled value={cpf} />
                  )}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Endereço</Form.Label>
                  {isLoading ? (
                    <Skeleton height={"40px"} width={"100%"} />
                  ) : (
                    <Form.Control type='text' placeholder='Endereço Exemplo' disabled value={address} />
                  )}
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group>
                  <Form.Label>Telefone</Form.Label>{" "}

                  {isLoading ? (
                    <Skeleton height={"40px"} width={"100%"} />
                  ) : (
                    <>
                      {phone &&
                        <a target={"_blank"} href={`https://api.whatsapp.com/send?phone=55${phone.replace(/[()\s-]/g, "")}`}>
                          <FontAwesomeIcon icon={faWhatsapp} color='#33cc33' />
                        </a>
                      }

                      <Form.Control type='text' placeholder='Telefone Exemplo' disabled value={phone} />
                    </>
                  )}
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group>
                  <Form.Label>Conhecimento</Form.Label>{" "}
                  {isLoading ? (
                    <Skeleton height={"40px"} width={"100%"} />
                  ) : (
                    <Form.Control type='text' placeholder='Telefone Exemplo' disabled value={knowledge} />
                  )}
                </Form.Group>
              </Col>
            </Row>
          </div>
        </Card.Body>
      </Card>
    </section>
  );
};

export const RegisterTicket = ({ id }) => {
  const { data: animals, isLoading: animalsLoading, isEmpty: animalsEmpty } = FetchAnimals(id);
  const [pet, setPet] = useState("");
  return (
    <Card.Body>
      <p>Escolha o animal em que será registrado o ticket.</p>
      <Row>
        <Col md={6}>

          <Form.Label>Pets do Tutor</Form.Label>
          <InputGroup>

            <Typeahead
              id='customer'
              labelKey={(animals) => `${animals.name}`}
              onChange={(value) => {
                value[0] && setPet(value[0].id);
              }}
              options={animals}
              isLoading={animalsLoading}
              placeholder='Escolha o pet do tutor'
              className='is-invalid'
            />
            {pet &&
              <InputGroup.Text className='btn btn-success border text-white'>
                <Link to={{
                  pathname: `/ticket/u/${id}/${pet}`,
                }}>Registrar

                </Link>
              </InputGroup.Text>
            }


          </InputGroup>


        </Col>
      </Row>

    </Card.Body >
  )
}


