import React, { useState } from 'react';
import { Breadcrumb, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { CustomerTable, ServiceTable } from "../../components/Tables";
import { CustomerFilter } from '../../components/Filters';
// import { ProductFilter } from "../../components/Filters";



export default () => {
    const [filter, setFilter] = useState({});
    return (
        <div className="justify-content-between flex-wrap flex-md-nowrap py-4">
            <div className="mb-4 mb-lg-0">
                <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                    <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
                    <Breadcrumb.Item>Serviços</Breadcrumb.Item>
                    <Breadcrumb.Item active>Consultar</Breadcrumb.Item>

                </Breadcrumb>

                <Row className="d-flex flex-wrap flex-md-nowrap align-items-center py-4">
                    <Col className="d-block mb-4 mb-md-0">
                        <h1 className="h2">Consulta de Serviços</h1>
                        <p className="mb-0">
                            Tabela contendo todos os serviços cadastrados.
                        </p>
                    </Col>
                </Row>
                <CustomerFilter setFilter={setFilter}/>
                <ServiceTable filter={filter}/> 
            </div>
        </div >
    );
};
