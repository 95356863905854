
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Button, FormCheck, Container, InputGroup } from '@themesberg/react-bootstrap';
import { useNavigate } from 'react-router-dom';

import BgImage from "../../assets/img/illustrations/signin.svg";
import { AuthenticateUser } from "../../services/UserService";
import { useEffect } from "react";


export default () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberToken, setRememberToken] = useState(false);
  const [error, setError] = useState({});
  const handleClick = () => setRememberToken(!rememberToken)
  const navigate = useNavigate();


  const TempError = {};

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await AuthenticateUser(username, password, rememberToken);
      if (response.data.status === 'success') {
        navigate(`/`);
        navigate(0);
      }
    } catch (err) {
      if (err) {
        TempError.password = [];
        TempError.password[0] =
          "Nome de usuário ou senha Inválidas";
      }
      setError(TempError);
    }
  };

  useEffect(() => {
    document.body.style.backgroundColor = "#735986";
  }, []);



  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <h4>C.E.R VET - Área do Colaborador</h4>
                <Form className="mt-4" onSubmit={handleSubmit}>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Seu Usuário</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control autoFocus type="text" placeholder="Nome de Usuário" value={username} onChange={(e) => setUsername(e.target.value)} />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Sua senha</Form.Label>
                      <InputGroup>
                        <InputGroup.Text className={!!error.password && "is-invalid"}>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control type="password" placeholder="Senha" value={password} onChange={(e) => setPassword(e.target.value)} isInvalid={!!error.password} />
                        <Form.Control.Feedback type="invalid">{error.password}</Form.Control.Feedback>

                      </InputGroup>
                    </Form.Group>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <Form.Check type="checkbox">
                        <FormCheck.Input id="defaultCheck5" className="me-2" value={rememberToken} onChange={handleClick} />
                        <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Lembrar-me</FormCheck.Label>
                      </Form.Check>
                    </div>
                  </Form.Group>
                  <Button variant="primary" type="submit" className="w-100">
                    Fazer Login
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
