import React, { useEffect, useState, Component, useRef } from "react";
import { Row, Form, Modal, Button, Col, InputGroup, Accordion, Spinner, Table, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Datetime from "react-datetime";
import { faCalendarAlt, faPen, faEye, faTrashAlt, faWarning, faLock, faLockOpen, faUndo, faRefresh, faDog, faList, faFileArchive, faPlus, faSearch, faCoins, faCreditCard, faMoneyBill, faPencil, faCheck } from "@fortawesome/free-solid-svg-icons";
import "moment/locale/pt-br";
import { DeleteCustomer, FindCustomer, GetAdressfromAPI, UpdateCustomer } from "../services/CustomerService";
import { toast } from "react-toastify";
import { CreateAnimal, DeleteAnimal, FetchColors, FetchRaces, FetchSpecies, FindAnimal, UpdateAnimal } from "../services/AnimalService";
import { CreateExam, DeleteExam, FindExam, FindExamById, ResetExamPassword } from "../services/ExamService";
import { IMaskInput } from "react-imask";
import moment from "moment-timezone";
import { Typeahead } from "react-bootstrap-typeahead";
import { DeleteUser, FetchRoles, FindUser, UpdateUser, resetPassword } from "../services/UserService";
import { DeleteService, FetchServices, FindService, UpdateService } from "../services/ServiceService";
import { CreatePayment, DeletePayment, FetchPayments, FindPayment, UpdatePayment } from "../services/PaymentService";
import { DeleteTicket, FindTicket, UpdateTicket } from "../services/TicketService";
import { WorkerModal } from "../pages/dashboard/customer/TicketView";
import { FindCommission } from "../services/FinanceService";


export const DeleteCustomerModal = ({ id, refreshState, setRefreshState }) => {
  const [showDefault, setShowDefault] = useState(false);
  const [showConflict, setShowConflict] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [conflict, setConflict] = useState([]);

  const handleClose = () => {
    setShowDefault(false);
  };
  const handleConflict = () => setShowConflict(false);

  let handleDelete = async (e) => {
    e.preventDefault();
    try {
      const { data } = await DeleteCustomer(id);

      if (data.status === "success") {
        if (data.data.table) {
          toast.error("Ocorreu um erro ao deletar o cliente!");
          setShowConflict(true);
          setConflict(data.data);
          setIsLoading(false);
          handleClose();
        } else {
          toast.success("Cliente deletado com sucesso!");
          refreshState === true ? setRefreshState(false) : setRefreshState(true);
          handleClose();
        }
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao deletar o cliente!");
    }
  };

  const renderConflict = () => {
    const table = conflict.table;
    return conflict[0].map((conflict) => {
      return (
        <tr key={conflict.id}>
          <td>
            <span className='fw-normal'>{table}</span>
          </td>
          <td>
            <span className='fw-bold text-info'>#{conflict.id.toString().padStart(5, 0)}</span>
          </td>
          <td>
            <span className='fw-normal'>{conflict.name}</span>
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <span onClick={() => setShowDefault(true)}>
        <FontAwesomeIcon icon={faTrashAlt} className='me-2' /> Deletar
      </span>
      <Modal size={"md"} as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className='h6'>Deletar Dados</Modal.Title>
          <Button variant='close' aria-label='Close' onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Row className='justify-content-center text-center'>
            <FontAwesomeIcon icon={faTrashAlt} className='me-2 fa-6x text-danger col-12' />
            <span className='h4 mb-3 mt-4 col-12'>Você tem certeza?</span>
          </Row>

          <Row className='justify-content-center text-center'>
            <p className='mb-0'>Você realmente deseja deletar esses dados?</p>
            <p>Esse processo não pode ser desfeito.</p>
          </Row>
        </Modal.Body>
        <Modal.Footer className='justify-content-end'>
          <Button size={"md"} className='btn btn-gray me-3' onClick={handleClose}>
            Cancelar
          </Button>
          <Button size={"md"} onClick={handleDelete} className='btn btn-danger'>
            Deletar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal size={"md"} as={Modal.Dialog} centered show={showConflict} onHide={handleConflict}>
        <Modal.Header>
          <Modal.Title className='h6'>Análise de Conflitos</Modal.Title>
          <Button variant='close' aria-label='Close' onClick={handleConflict} />
        </Modal.Header>
        <Modal.Body>
          <Row className='justify-content-center text-center'>
            <FontAwesomeIcon icon={faWarning} className='me-2 fa-6x text-danger col-12' />
            <span className='h4 mb-3 mt-4 col-12'>Dependencia detectada!</span>
          </Row>

          <Row className='justify-content-center text-center'>
            <p className='mb-0'>Outros dados dependem desse para funcionar.</p>
            <p>Caso realmente queira deletar, remova-os antes!</p>

            <Table hover className='mt-2'>
              <thead>
                <tr>
                  <th className='border-bottom'>Tabela</th>
                  <th className='border-bottom'>ID</th>
                  <th className='border-bottom'>Nome</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan='100%' className='text-center'>
                      <Spinner animation='border' role='status' />
                    </td>
                  </tr>
                ) : (
                  <>{renderConflict()}</>
                )}
              </tbody>
            </Table>
          </Row>
        </Modal.Body>
        <Modal.Footer className='justify-content-end'>
          <Button size={"md"} className='btn btn-gray me-3' onClick={handleConflict}>
            Entendido
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const DeleteServiceModal = ({ id, refreshState, setRefreshState }) => {
  const [showDefault, setShowDefault] = useState(false);
  const [showConflict, setShowConflict] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [conflict, setConflict] = useState([]);

  const handleClose = () => {
    setShowDefault(false);
  };
  const handleConflict = () => setShowConflict(false);

  let handleDelete = async (e) => {
    e.preventDefault();
    try {
      const { data } = await DeleteService(id);

      if (data.status === "success") {
        if (data.data.table) {
          toast.error("Ocorreu um erro ao deletar o cliente!");
          setShowConflict(true);
          setConflict(data.data);
          setIsLoading(false);
          handleClose();
        } else {
          toast.success("Serviço deletado com sucesso!");
          refreshState === true ? setRefreshState(false) : setRefreshState(true);
          handleClose();
        }
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao deletar o serviço!"+err);
    }
  };

  const renderConflict = () => {
    const table = conflict.table;
    return conflict[0].map((conflict) => {
      return (
        <tr key={conflict.id}>
          <td>
            <span className='fw-normal'>{table}</span>
          </td>
          <td>
            <span className='fw-bold text-info'>#{conflict.id.toString().padStart(5, 0)}</span>
          </td>
          <td>
            <span className='fw-normal'>{conflict.name}</span>
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <span onClick={() => setShowDefault(true)}>
        <FontAwesomeIcon icon={faTrashAlt} className='me-2' /> Deletar
      </span>
      <Modal size={"md"} as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className='h6'>Deletar Dados</Modal.Title>
          <Button variant='close' aria-label='Close' onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Row className='justify-content-center text-center'>
            <FontAwesomeIcon icon={faTrashAlt} className='me-2 fa-6x text-danger col-12' />
            <span className='h4 mb-3 mt-4 col-12'>Você tem certeza?</span>
          </Row>

          <Row className='justify-content-center text-center'>
            <p className='mb-0'>Você realmente deseja deletar esses dados?</p>
            <p>Esse processo não pode ser desfeito.</p>
          </Row>
        </Modal.Body>
        <Modal.Footer className='justify-content-end'>
          <Button size={"md"} className='btn btn-gray me-3' onClick={handleClose}>
            Cancelar
          </Button>
          <Button size={"md"} onClick={handleDelete} className='btn btn-danger'>
            Deletar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal size={"md"} as={Modal.Dialog} centered show={showConflict} onHide={handleConflict}>
        <Modal.Header>
          <Modal.Title className='h6'>Análise de Conflitos</Modal.Title>
          <Button variant='close' aria-label='Close' onClick={handleConflict} />
        </Modal.Header>
        <Modal.Body>
          <Row className='justify-content-center text-center'>
            <FontAwesomeIcon icon={faWarning} className='me-2 fa-6x text-danger col-12' />
            <span className='h4 mb-3 mt-4 col-12'>Dependencia detectada!</span>
          </Row>

          <Row className='justify-content-center text-center'>
            <p className='mb-0'>Outros dados dependem desse para funcionar.</p>
            <p>Caso realmente queira deletar, remova-os antes!</p>

            <Table hover className='mt-2'>
              <thead>
                <tr>
                  <th className='border-bottom'>Tabela</th>
                  <th className='border-bottom'>ID</th>
                  <th className='border-bottom'>Nome</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan='100%' className='text-center'>
                      <Spinner animation='border' role='status' />
                    </td>
                  </tr>
                ) : (
                  <>{renderConflict()}</>
                )}
              </tbody>
            </Table>
          </Row>
        </Modal.Body>
        <Modal.Footer className='justify-content-end'>
          <Button size={"md"} className='btn btn-gray me-3' onClick={handleConflict}>
            Entendido
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const DeleteAnimalModal = ({ id, refreshState, setRefreshState }) => {
  const [showDefault, setShowDefault] = useState(false);
  const [showConflict, setShowConflict] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [conflict, setConflict] = useState([]);

  const handleClose = () => {
    setShowDefault(false);
  };
  const handleConflict = () => setShowConflict(false);

  let handleDelete = async (e) => {
    e.preventDefault();
    try {
      const { data } = await DeleteAnimal(id);

      if (data.status === "success") {
        if (data.data.table) {
          toast.error("Ocorreu um erro ao deletar o animal!");
          setShowConflict(true);
          setConflict(data.data);
          setIsLoading(false);
          handleClose();
        } else {
          toast.success("Animal deletado com sucesso!");
          refreshState === true ? setRefreshState(false) : setRefreshState(true);
          handleClose();
        }
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao deletar o animal!");
    }
  };

  const renderConflict = () => {
    const table = conflict.table;
    return conflict[0].map((conflict) => {
      return (
        <tr key={conflict.id}>
          <td>
            <span className='fw-normal'>{table}</span>
          </td>
          <td>
            <span className='fw-bold text-info'>#{conflict.id.toString().padStart(5, 0)}</span>
          </td>
          <td>
            <span className='fw-normal'>{conflict.name}</span>
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <span onClick={() => setShowDefault(true)}>
        <FontAwesomeIcon icon={faTrashAlt} className='me-2' /> Deletar
      </span>
      <Modal size={"md"} as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className='h6'>Deletar Dados</Modal.Title>
          <Button variant='close' aria-label='Close' onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Row className='justify-content-center text-center'>
            <FontAwesomeIcon icon={faTrashAlt} className='me-2 fa-6x text-danger col-12' />
            <span className='h4 mb-3 mt-4 col-12'>Você tem certeza?</span>
          </Row>

          <Row className='justify-content-center text-center'>
            <p className='mb-0'>Você realmente deseja deletar esses dados?</p>
            <p>Esse processo não pode ser desfeito.</p>
          </Row>
        </Modal.Body>
        <Modal.Footer className='justify-content-end'>
          <Button size={"md"} className='btn btn-gray me-3' onClick={handleClose}>
            Cancelar
          </Button>
          <Button size={"md"} onClick={handleDelete} className='btn btn-danger'>
            Deletar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal size={"md"} as={Modal.Dialog} centered show={showConflict} onHide={handleConflict}>
        <Modal.Header>
          <Modal.Title className='h6'>Análise de Conflitos</Modal.Title>
          <Button variant='close' aria-label='Close' onClick={handleConflict} />
        </Modal.Header>
        <Modal.Body>
          <Row className='justify-content-center text-center'>
            <FontAwesomeIcon icon={faWarning} className='me-2 fa-6x text-danger col-12' />
            <span className='h4 mb-3 mt-4 col-12'>Dependencia detectada!</span>
          </Row>

          <Row className='justify-content-center text-center'>
            <p className='mb-0'>Outros dados dependem desse para funcionar.</p>
            <p>Caso realmente queira deletar, remova-os antes!</p>

            <Table hover className='mt-2'>
              <thead>
                <tr>
                  <th className='border-bottom'>Tabela</th>
                  <th className='border-bottom'>ID</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan='100%' className='text-center'>
                      <Spinner animation='border' role='status' />
                    </td>
                  </tr>
                ) : (
                  <>{renderConflict()}</>
                )}
              </tbody>
            </Table>
          </Row>
        </Modal.Body>
        <Modal.Footer className='justify-content-end'>
          <Button size={"md"} className='btn btn-gray me-3' onClick={handleConflict}>
            Entendido
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const DeleteExamModal = ({ id, refreshState, setRefreshState }) => {
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => {
    setShowDefault(false);
  };

  let handleDelete = async (e) => {
    e.preventDefault();
    try {
      const { status } = await DeleteExam(id);
      if (status === "success") {
        toast.success("Exame deletado com sucesso!");
        refreshState === true ? setRefreshState(false) : setRefreshState(true);
        handleClose();
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao deletar o exame!" + err);
    }
  };

  return (
    <>
      <span onClick={() => setShowDefault(true)}>
        <FontAwesomeIcon icon={faTrashAlt} className='me-2' /> Deletar
      </span>
      <Modal size={"md"} as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className='h6'>Deletar Dados</Modal.Title>
          <Button variant='close' aria-label='Close' onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Row className='justify-content-center text-center'>
            <FontAwesomeIcon icon={faTrashAlt} className='me-2 fa-6x text-danger col-12' />
            <span className='h4 mb-3 mt-4 col-12'>Você tem certeza?</span>
          </Row>

          <Row className='justify-content-center text-center'>
            <p className='mb-0'>Você realmente deseja deletar esses dados?</p>
            <p>Esse processo não pode ser desfeito.</p>
          </Row>
        </Modal.Body>
        <Modal.Footer className='justify-content-end'>
          <Button size={"md"} className='btn btn-gray me-3' onClick={handleClose}>
            Cancelar
          </Button>
          <Button size={"md"} onClick={handleDelete} className='btn btn-danger'>
            Deletar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const DeleteUserModal = ({ id, refreshState, setRefreshState }) => {
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => {
    setShowDefault(false);
  };

  let handleDelete = async (e) => {
    e.preventDefault();
    try {
      const { status } = await DeleteUser(id);
      if (status === "success") {
        toast.success("Usuário deletado com sucesso!");
        refreshState === true ? setRefreshState(false) : setRefreshState(true);
        handleClose();
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao deletar o usuário!");
    }
  };

  return (
    <>
      <span onClick={() => setShowDefault(true)}>
        <FontAwesomeIcon icon={faTrashAlt} className='me-2' /> Deletar
      </span>
      <Modal size={"md"} as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className='h6'>Deletar Dados</Modal.Title>
          <Button variant='close' aria-label='Close' onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Row className='justify-content-center text-center'>
            <FontAwesomeIcon icon={faTrashAlt} className='me-2 fa-6x text-danger col-12' />
            <span className='h4 mb-3 mt-4 col-12'>Você tem certeza?</span>
          </Row>

          <Row className='justify-content-center text-center'>
            <p className='mb-0'>Você realmente deseja deletar esses dados?</p>
            <p>Esse processo não pode ser desfeito.</p>
          </Row>
        </Modal.Body>
        <Modal.Footer className='justify-content-end'>
          <Button size={"md"} className='btn btn-gray me-3' onClick={handleClose}>
            Cancelar
          </Button>
          <Button size={"md"} onClick={handleDelete} className='btn btn-danger'>
            Deletar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const DeletePaymentModal = ({ id, refreshState, setRefreshState }) => {
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => {
    setShowDefault(false);
  };

  let handleDelete = async (e) => {
    e.preventDefault();
    try {
      const { status } = await DeletePayment(id);
      if (status === "success") {
        toast.success("Mét. Pagamento deletado com sucesso!");
        refreshState === true ? setRefreshState(false) : setRefreshState(true);
        handleClose();
      } else {
        toast.error("Ocorreu um erro ao deletar o mét pagamento!");
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao deletar o mét pagamento!");
    }
  };

  return (
    <>
      <span onClick={() => setShowDefault(true)}>
        <FontAwesomeIcon icon={faTrashAlt} className='me-2' /> Deletar
      </span>
      <Modal size={"md"} as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className='h6'>Deletar Dados</Modal.Title>
          <Button variant='close' aria-label='Close' onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Row className='justify-content-center text-center'>
            <FontAwesomeIcon icon={faTrashAlt} className='me-2 fa-6x text-danger col-12' />
            <span className='h4 mb-3 mt-4 col-12'>Você tem certeza?</span>
          </Row>

          <Row className='justify-content-center text-center'>
            <p className='mb-0'>Você realmente deseja deletar esses dados?</p>
            <p>Esse processo não pode ser desfeito.</p>
          </Row>
        </Modal.Body>
        <Modal.Footer className='justify-content-end'>
          <Button size={"md"} className='btn btn-gray me-3' onClick={handleClose}>
            Cancelar
          </Button>
          <Button size={"md"} onClick={handleDelete} className='btn btn-danger'>
            Deletar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const AnimalDetailModal = ({ id, setRefreshState, refreshState }) => {
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => {
    setShowDefault(false);
    setDisabled(true);
  };
  const switchDeceased = () => { setDeceased(deceased => deceased === 0 ? 1 : 0); };
  const [error, setError] = useState({});
  const [createdAt, setCreatedAt] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const [isDisabled, setDisabled] = useState(true);

  const [owner, setOwner] = useState("");
  const [cpf, setCPF] = useState("");
  const [ownerId, setOwnerId] = useState("");

  const [name, setName] = useState("");
  const [tag, setTag] = useState("");
  const [bornAt, setBornAt] = useState("");
  const [deceased, setDeceased] = useState();

  const [gender, setGender] = useState("");
  const [race, setRace] = useState("");
  const [species, setSpecies] = useState("");
  const [color, setColor] = useState("");
  const [castrated, setCastrated] = useState("");
  const [chip, setChip] = useState("");

  const { data: specieses, isLoading: speciesLoading } = FetchSpecies();
  const { data: races, isLoading: racesLoading } = FetchRaces();
  const { data: colors, isLoading: colorsLoading } = FetchColors();

  const genders = [{ name: "Macho" }, { name: "Fêmea" }];

  let fillForm = async () => {
    try {
      const { data, status } = await FindAnimal(id);
      if (status === "success") {
        setOwner(data.owner.name);
        setCPF(data.owner.cpf);
        setOwnerId(data.owner.id);

        setName(data.name);
        setTag(data.tag);
        setBornAt(data.born_at);
        setDeceased(data.deceased);

        setGender(data.gender);
        setRace(data.race);
        setColor(data.color);
        setSpecies(data.species);
        setCastrated(data.castrated);
        setChip(data.chip);

        setCreatedAt(data.created_at);
        setUpdatedAt(data.updated_at);

        setShowDefault(true);
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao buscar o cliente!");
    }
  };
  let handleEditable = async () => {
    isDisabled === true ? setDisabled(false) : setDisabled(true);
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { status } = await UpdateAnimal(id, ownerId, name, gender, species, color, race, deceased, bornAt, tag, castrated, chip);

      if (status === "success") {
        toast.success("Animal atualizado com sucesso!");
        refreshState === true ? setRefreshState(false) : setRefreshState(true);
        handleClose();
        setError({});
        setDisabled(true);
      }
    } catch (err) {
      const errors = err.response.data;
      if (errors) {
        setError(errors.data);
        toast.warn("Verifique os campos obrigatórios!");
      } else {
        toast.error("Ocorreu um erro ao atualizar o animal!");
      }
    }
  };
  return (
    <div
      onKeyDown={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}>

      <span onClick={fillForm}>
        <FontAwesomeIcon icon={faEye} className='me-2' /> Detalhes
      </span>
      <Modal size={"xl"} as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className='h6'>Ver Detalhes</Modal.Title>
          <Button variant='close' aria-label='Close' onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <h5 className='mb-2'>Dados do Tutor</h5>
          <Row>
            <Col sm={12} xl={6} className='mb-3'>
              <Form.Group id='ownerName'>
                <Form.Label>Nome</Form.Label>
                <Form.Control disabled type='text' placeholder='Nome Exemplo' value={owner} />
              </Form.Group>
            </Col>
            <Col sm={12} xl={6} className='mb-3'>
              <Form.Group>
                <Form.Label>CPF</Form.Label>
                <Form.Control disabled type='text' placeholder='CPF Exemplo' value={cpf} />
              </Form.Group>
            </Col>
          </Row>
          <h5 className='mb-2 mt-3'>Dados do Animal</h5>

          <Row>
            <Col sm={4} xl={6} className='mb-3'>
              <Form.Group>
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  disabled={isDisabled}
                  type='text'
                  placeholder='Nome Exemplo'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  isInvalid={!!error.name}
                />
                <Form.Control.Feedback type='invalid'>{error.name}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm={4} xl={6} className='mb-3'>
              <Form.Group>
                <Form.Label className='col-10 text-truncate'>Carteirinha do Animal <small>(Opcional)</small></Form.Label>
                <Form.Control
                  disabled={isDisabled}
                  type='text'
                  placeholder={"000000"}
                  value={tag}
                  onChange={(e) => setTag(e.target.value)}
                  isInvalid={!!error.tag}
                />
                <Form.Control.Feedback type='invalid'>{error.tag}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm={6} xl={6} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label className='col-10 text-truncate'>Data de Nascimento</Form.Label>
                <Datetime
                  timeFormat={false}
                  closeOnSelect={true}
                  onChange={setBornAt}
                  renderInput={(props, openCalendar) => (
                    <InputGroup hasValidation>
                      <InputGroup.Text className={(!!error.born_at && "is-invalid") || (isDisabled && "disabled")}>
                        <FontAwesomeIcon icon={faCalendarAlt} />
                      </InputGroup.Text>
                      <Form.Control
                        isInvalid={!!error.born_at}
                        type='text'
                        disabled={isDisabled}
                        value={bornAt}
                        placeholder='dd/mm/yyyy'
                        onFocus={openCalendar}
                        onChange={() => { }}
                      />
                      <Form.Control.Feedback type='invalid'>{error.born_at}</Form.Control.Feedback>
                    </InputGroup>
                  )}
                />
                <Form.Control.Feedback type='invalid'>{error.born_at}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={2} md={6} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Group className='mb-2'>
                  <Form.Label>Óbito</Form.Label>
                  <div>
                    <Form.Check checked={deceased ? true : false} inline label='Sim' name='deceased' type={"radio"} value='1' onChange={(e) => setDeceased(1)} />
                    <Form.Check checked={deceased ? false : true} inline name='deceased' label='Não' type={"radio"} value='0' onChange={(e) => setDeceased(0)} />
                  </div>
                </Form.Group>
              </Form.Group>

            </Col>
          </Row>
          <h5 className='mb-2 mt-3'>Características do Animal</h5>
          <Row>
            <Col sm={6} className='mb-3'>
              <Form.Group id='gender'>
                <Form.Label>Gênero</Form.Label>
                <Typeahead
                  disabled={isDisabled}
                  id='gender'
                  isInvalid={!!error.gender}
                  labelKey={(genders) => `${genders.name}`}
                  onChange={(value) => value[0] && setGender(value[0].name)}
                  options={genders}
                  className='is-invalid'
                  placeholder='Escolha o gênero do animal'
                  defaultInputValue={gender}
                />
                <Form.Control.Feedback type='invalid'>{error.gender}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm={6} className='mb-3'>
              <Form.Group id='race'>
                <Form.Label>Raça</Form.Label>
                <Typeahead
                  disabled={isDisabled}
                  id='race'
                  isInvalid={!!error.race}
                  labelKey={(races) => `${races.name}`}
                  onChange={(value) => value[0] && setGender(value[0].name)}
                  options={races}
                  className='is-invalid'
                  placeholder='Escolha a raça do animal'
                  defaultInputValue={race.name}
                />
                <Form.Control.Feedback type='invalid'>{error.race}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm={6} className='mb-3'>
              <Form.Group id='species'>
                <Form.Label>Espécie</Form.Label>
                <Typeahead
                  disabled={isDisabled}
                  id='race'
                  isInvalid={!!error.species}
                  labelKey={(specieses) => `${specieses.name}`}
                  onChange={(value) => value[0] && setSpecies(value[0].name)}
                  options={specieses}
                  className='is-invalid'
                  placeholder='Escolha a espécie do animal'
                  defaultInputValue={species.name}
                />
              </Form.Group>
              <Form.Control.Feedback type='invalid'>{error.species}</Form.Control.Feedback>
            </Col>
            <Col sm={6} className='mb-3'>
              <Form.Group id='color'>
                <Form.Label>Pelagem</Form.Label>
                <Typeahead
                  disabled={isDisabled}
                  id='race'
                  isInvalid={!!error.color}
                  labelKey={(colors) => `${colors.name}`}
                  onChange={(value) => value[0] && setColor(value[0].name)}
                  options={colors}
                  className='is-invalid'
                  placeholder='Escolha a pelagem do animal'
                  defaultInputValue={color.name}
                />
                <Form.Control.Feedback type='invalid'>{error.color}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm={6} className='mb-3'>
              <Form.Group id='color'>
                <Form.Label>Chip <small>(Opcional)</small></Form.Label>
                <Form.Control
                  disabled={isDisabled}
                  type='text'
                  placeholder='Chip Exemplo'
                  value={chip}
                  onChange={(e) => setChip(e.target.value)}
                  isInvalid={!!error.chip}
                />
                <Form.Control.Feedback type='invalid'>{error.color}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={2} md={6} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Group className='mb-2'>
                  <Form.Label>Castrado</Form.Label>
                  <div>
                    <Form.Check checked={castrated ? true : false} inline label='Sim' name='castrated' type={"radio"} value='1' onChange={(e) => setCastrated(1)} />
                    <Form.Check checked={castrated ? false : true} inline name='castrated' label='Não' type={"radio"} value='0' onChange={(e) => setCastrated(0)} />
                  </div>
                </Form.Group>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className='justify-content-between'>
          <div>
            <Col>
              <small>Criado em: {createdAt}</small>
            </Col>
            <Col>
              <small>Atualizado em: {updatedAt}</small>
            </Col>
          </div>
          <div>
            <Button size={"md"} className='btn btn-gray me-3' onClick={handleEditable}>
              <FontAwesomeIcon icon={faPen} />
            </Button>
            {!isDisabled && (
              <Button size={"md"} onClick={handleSubmit} className='btn btn-success'>
                Salvar
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export const CustomerDetailModal = ({ id, setRefreshState, refreshState }) => {
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => {
    setShowDefault(false);
    setDisabled(true);
  };

  const [error, setError] = useState({});
  const [createdAt, setCreatedAt] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const [isDisabled, setDisabled] = useState(true);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [cpf, setCPF] = useState("");
  const [rg, setRG] = useState("");
  const [bornAt, setBornAt] = useState("");
  const [phone, setPhone] = useState("");

  const [cep, setCep] = useState("");
  const [street, setStreet] = useState("");
  const [district, setDistrict] = useState("");
  const [city, setCity] = useState("");
  const [knowledge, setKnowledge] = useState("");

  const knowledges = [
    { place: "Indicação" },
    { place: "Frente Loja" },
    { place: "Instagram" },
    { place: "Facebook" },
    { place: "Google" },
    { place: "Youtube" },
    { place: "Panfletos" },
    { place: "Email" },
    { place: "Site" },
    { place: "Outros" },
    { place: "Banners (Online)" },
    { place: "Banners (Offline)" }
  ];

  let fillForm = async () => {
    try {
      const { data, status } = await FindCustomer(id);
      if (status === "success") {
        setName(data.name);
        setEmail(data.email);
        setCPF(data.cpf);
        setRG(data.rg);
        setBornAt(data.birth_at);
        setPhone(data.phone);
        setCep(data.cep);
        setStreet(data.street);
        setDistrict(data.district);
        setCity(data.city);
        setKnowledge(data.knowledge);

        setCreatedAt(data.created_at);
        setUpdatedAt(data.updated_at);

        setShowDefault(true);
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao buscar o cliente!");
    }
  };
  let handleEditable = async () => {
    isDisabled === true ? setDisabled(false) : setDisabled(true);
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { status } = await UpdateCustomer(id, name, cpf, rg, email, phone, cep, street, district, city, bornAt, knowledge);
      if (status === "success") {
        toast.success("Tutor atualizado com sucesso!");
        refreshState === true ? setRefreshState(false) : setRefreshState(true);
        handleClose();
        setError({});
        setDisabled(true);
      }
    } catch (err) {
      const errors = err.response.data;
      if (errors) {
        setError(errors.data);
        toast.warn("Verifique os campos obrigatórios!");
      } else {
        toast.error("Ocorreu um erro ao atualizar o Tutor!");
      }
    }
  };
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchCEP();
    }, 2000);

    return () => clearTimeout(delayDebounceFn);
  }, [cep]);

  let fetchCEP = async (e) => {
    if (!isDisabled) {
      try {
        const { data } = await GetAdressfromAPI(cep);
        setStreet(data.address);
        setDistrict(data.district);
        setCity(data.city + " / " + data.state);
        toast.success("O CEP foi encontrado e adicionado ao formulário!");
      } catch (err) {
        toast.error("O CEP não foi encontrado ou é inexistente!");
      }
    }

  };

  return (
    <>
      <span onClick={fillForm}>
        <FontAwesomeIcon icon={faEye} className='me-2' /> Detalhes do Perfil
      </span>
      <Modal size={"xl"} as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className='h6'>Ver Detalhes</Modal.Title>
          <Button variant='close' aria-label='Close' onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <h5 className='mb-2'>Dados do Tutor</h5>
          <Row>
            <Col sm={12} xl={6} className='mb-3'>
              <Form.Group>
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  disabled={isDisabled}
                  type='text'
                  placeholder='Nome Exemplo'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  isInvalid={!!error.name}
                />
                <Form.Control.Feedback type='invalid'>{error.name}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm={12} xl={6} className='mb-3'>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  disabled={isDisabled}
                  type='text'
                  placeholder='Email Exemplo'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  isInvalid={!!error.email}
                />
                <Form.Control.Feedback type='invalid'>{error.email}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm={12} xl={2} className='mb-3'>
              <Form.Group>
                <Form.Label>CPF</Form.Label>
                <Form.Control
                  disabled={isDisabled}
                  type='text'
                  as={IMaskInput}
                  mask='000.000.000-00'
                  placeholder={"___.___.___-__"}
                  value={cpf}
                  onChange={(e) => setCPF(e.target.value)}
                  isInvalid={!!error.cpf}
                />
                <Form.Control.Feedback type='invalid'>{error.cpf}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm={12} xl={2} className='mb-3'>
              <Form.Group>
                <Form.Label>RG</Form.Label>
                <Form.Control
                  disabled={isDisabled}
                  type='text'
                  as={IMaskInput}
                  mask='00.000.000-0'
                  placeholder={"__.___.___-_"}
                  value={rg}
                  onChange={(e) => setRG(e.target.value)}
                  isInvalid={!!error.rg}
                />
                <Form.Control.Feedback type='invalid'>{error.rg}</Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col sm={12} xl={2} className='mb-3'>
              <Form.Group>
                <Form.Label className='col-10 text-truncate'>Data de Nascimento</Form.Label>
                <Datetime
                  timeFormat={false}
                  closeOnSelect={true}
                  onChange={setBornAt}
                  renderInput={(props, openCalendar) => (
                    <InputGroup hasValidation>
                      <InputGroup.Text className={(!!error.birth_at && "is-invalid") || (isDisabled && "disabled")}>
                        <FontAwesomeIcon icon={faCalendarAlt} />
                      </InputGroup.Text>
                      <Form.Control
                        disabled={isDisabled}
                        isInvalid={!!error.birth_at}
                        type='text'
                        value={bornAt ? moment(bornAt).format("DD/MM/YYYY") : ""}
                        placeholder='dd/mm/yyyy'
                        onFocus={openCalendar}
                        onChange={() => { }}
                      />
                      <Form.Control.Feedback type='invalid'>{error.birth_at}</Form.Control.Feedback>
                    </InputGroup>
                  )}
                />
              </Form.Group>
            </Col>

            <Col sm={12} xl={6} className='mb-3'>
              <Form.Group>
                <Form.Label>Telefone</Form.Label>
                <Form.Control
                  disabled={isDisabled}
                  type='text'
                  as={IMaskInput}
                  mask='(00) 00000-0000'
                  placeholder={"(00) 00000-0000"}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  isInvalid={!!error.phone}
                />
                <Form.Control.Feedback type='invalid'>{error.phone}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm={12} xl={6} className='mb-3'>
              <Form.Group>
                <Form.Label>Como nos conheceu?</Form.Label>
                <Typeahead
                  disabled={isDisabled}
                  id='customer'
                  labelKey={(knowledges) => `${knowledges.place}`}
                  onChange={(value) => value[0] && setKnowledge(value[0].place)}
                  options={knowledges}
                  placeholder='Escolha o local de conhecimento'
                  className='is-invalid'
                  isInvalid={!!error.knowledge}
                  defaultInputValue={knowledge}
                />
                <Form.Control.Feedback type='invalid'>{error.rg}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <h5 className='mb-2 mt-3'>Dados do Endereço</h5>
          <Row>
            <Col sm={12} xl={4} className='mb-3'>
              <Form.Group>
                <Form.Label>CEP</Form.Label>
                <Form.Control
                  disabled={isDisabled}
                  type='text'
                  mask='00000000'
                  placeholder={"_______"}
                  value={cep}
                  onChange={(e) => setCep(e.target.value)}
                  isInvalid={!!error.cep}
                />
                <Form.Control.Feedback type='invalid'>{error.cep}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={4} xl={4} className='mb-3'>
              <Form.Group>
                <Form.Label>Rua</Form.Label>
                <Form.Control
                  disabled={isDisabled}
                  type='text'
                  placeholder='Rua Exemplo'
                  value={street}
                  onChange={(e) => setStreet(e.target.value)}
                  isInvalid={!!error.street}
                />
                <Form.Control.Feedback type='invalid'>{error.street}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm={4} xl={4} className='mb-3'>
              <Form.Group>
                <Form.Label>Bairro</Form.Label>
                <Form.Control
                  disabled={isDisabled}
                  type='text'
                  placeholder='Nome Exemplo'
                  value={district}
                  onChange={(e) => setDistrict(e.target.value)}
                  isInvalid={!!error.district}
                />
                <Form.Control.Feedback type='invalid'>{error.district}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm={4} xl={4} className='mb-3'>
              <Form.Group>
                <Form.Label>Cidade / Estado</Form.Label>
                <Form.Control
                  disabled={isDisabled}
                  type='text'
                  placeholder='Cidade / Estado Exemplo'
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  isInvalid={!!error.city}
                />
                <Form.Control.Feedback type='invalid'>{error.city}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className='justify-content-between'>
          <div>
            <Col>
              <small>Criado em: {createdAt}</small>
            </Col>
            <Col>
              <small>Atualizado em: {updatedAt}</small>
            </Col>
          </div>
          <div>
            <Button size={"md"} className='btn btn-gray me-3' onClick={handleEditable}>
              <FontAwesomeIcon icon={faPen} />
            </Button>
            {!isDisabled && (
              <Button size={"md"} onClick={handleSubmit} className='btn btn-success'>
                Salvar
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const ServiceDetailModal = ({ id, setRefreshState, refreshState }) => {
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => {
    setShowDefault(false);
    setDisabled(true);
  };

  const [error, setError] = useState({});
  const [createdAt, setCreatedAt] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const [isDisabled, setDisabled] = useState(true);

  const [name, setName] = useState("");
  const [storage, setStorage] = useState(1);
  const [price, setPrice] = useState("");
  const [buyCost, setBuyCost] = useState("");
  const [unit, setUnit] = useState("");
  const [workerList, setWorkerList] = useState([]);
  // const [type, setType] = useState("");

  const ref = useRef();
  const ref2 = useRef();


  const units = [
    { id: 0, type: "KG" },
    { id: 1, type: "Unitário" }
  ];

  const types = [
    { id: 0, type: "Serviço" },
    { id: 1, type: "Produto" }
  ];

  let fillForm = async () => {
    try {
      const { data, status } = await FindService(id);
      if (status === "success") {
        setName(data.name);
        setStorage(data.storage);
        setPrice(data.price);
        setBuyCost(data.buy_cost);
        setUnit(data.unit);
        setWorkerList(data.workers);

        setCreatedAt(data.created_at);
        setUpdatedAt(data.updated_at);

        setShowDefault(true);
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao buscar o cliente!");
    }

  };
  let handleEditable = async () => {
    isDisabled === true ? setDisabled(false) : setDisabled(true);
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { status } = await UpdateService(id, name, storage, unit, price, buyCost, workerList);
      if (status === "success") {
        toast.success("Serviço atualizado com sucesso!");
        refreshState === true ? setRefreshState(false) : setRefreshState(true);
        handleClose();
        setError({});
        setDisabled(true);
        setWorkerList([]);
      }
    } catch (err) {
      const errors = err.response.data;
      if (errors) {
        setError(errors.data);
        toast.warn("Verifique os campos obrigatórios!");
      } else {
        toast.error("Ocorreu um erro ao atualizar o Serviço!");
      }
    }
  };

  let removeSelectedWorker = (id) => {
    setWorkerList(workerList.filter(worker => worker.id !== id));
  }

  const renderTable = () => {
    return workerList.map((worker) => {
      return (
        <tr key={worker.id}>
          <td>
            <span className='fw-bold text-info'>#{worker.id.toString().padStart(5, 0)}</span>
          </td>
          <td>
            <span className='fw-normal'>{worker.name}</span>
          </td>
          <td>
            <span className='fw-normal'>{worker.comissionType === 0 ? `${worker.comission}% (R$ ${worker.equivalentTo})` : `R$ ${worker.comission}`}</span>
          </td>

          {!isDisabled && (
            <td>
              <span className='btn btn-danger' onClick={() => removeSelectedWorker(worker.id)}><FontAwesomeIcon icon={faTrashAlt} /></span>
            </td>
          )}

        </tr >
      );
    });
  }

  return (
    <>
      <span onClick={fillForm}>
        <FontAwesomeIcon icon={faEye} className='me-2' /> Detalhes
      </span>
      <Modal size={"xl"} as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className='h6'>Ver Detalhes</Modal.Title>
          <Button variant='close' aria-label='Close' onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <h5 className='mb-2'>Dados do Serviço</h5>
          <Row>
            <Col md={6} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  disabled={isDisabled}
                  type='text'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder={"Insira o nome do Serviço"}
                  isInvalid={!!error.name}
                />
                <Form.Control.Feedback type='invalid'>{error.name}</Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={6} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>Tipo de Serviço</Form.Label>
                <Typeahead
                  disabled={isDisabled}
                  ref={ref2}
                  id='service'
                  labelKey={(units) => `${units.type}`}
                  onChange={(value) => value[0] && setUnit(value[0].id)}
                  options={types}
                  placeholder='Escolha o tipo de serviço'
                  className='is-invalid'
                  defaultInputValue={unit == 3 ? 'Serviço' : 'Produto'}
                  isInvalid={!!error.unit}
                />
                <Form.Control.Feedback type='invalid'>{error.storage}</Form.Control.Feedback>
              </Form.Group>
            </Col>

            {unit != 3 &&
              <>
                <Col md={6} className='mb-3'>
                  <Form.Group className='mb-2'>
                    <Form.Label>Estoque</Form.Label>
                    <Form.Control
                      disabled={isDisabled}
                      type='number'
                      value={storage}
                      placeholder={"Estoque"}
                      onChange={(e) => setStorage(e.target.value)}
                      isInvalid={!!error.storage}
                    />
                    <Form.Control.Feedback type='invalid'>{error.storage}</Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6} md={6} className='mb-3'>
                  <Form.Group className='mb-2'>
                    <Form.Label>Unidade</Form.Label>
                    <Typeahead
                      disabled={isDisabled}
                      ref={ref}
                      id='customer'
                      labelKey={(units) => `${units.type}`}
                      onChange={(value) => value[0] && setUnit(value[0].id)}
                      options={units}
                      placeholder='Escolha a unidade do Produto'
                      className='is-invalid'
                      defaultInputValue={unit == 0 ? 'KG' : 'Unitário'}
                      isInvalid={!!error.unit}
                    />
                    <Form.Control.Feedback type='invalid'>{error.unit}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </>
            }

            <Col md={3} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>Preço de Venda</Form.Label>
                <Form.Control
                  disabled={isDisabled}
                  type='number'
                  value={price}
                  placeholder={"R$ 00.00"}
                  onChange={(e) => setPrice(e.target.value)}
                  isInvalid={!!error.price}
                  step={0.01}
                />
                <Form.Control.Feedback type='invalid'>{error.price}</Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={3} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>Custo de Compra</Form.Label> <small>(Opcional)</small>
                <Form.Control
                  disabled={isDisabled}
                  type='number'
                  value={buyCost}
                  placeholder={"R$ 00.00"}
                  onChange={(e) => setBuyCost(e.target.value)}
                  isInvalid={!!error.buyCost}
                  step={0.01}
                />
                <Form.Control.Feedback type='invalid'>{error.buyCost}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <hr></hr>
          <h5>Dados dos Colaboradores <span className="ms-3"> {!isDisabled && <WorkerModal setWorkerList={setWorkerList} workerList={workerList} totalTicket={price} />}</span></h5>
          {error.users &&
            <>
              <Alert variant={'warning'}>
                {error.users}
              </Alert>
            </>
          }
          <Row>

            <Table hover >
              <thead className="text-center">
                <tr>

                  <th className='border-bottom'>ID</th>
                  <th className='border-bottom'>Colaborador</th>
                  <th className='border-bottom'>Comissão</th>
                  {!isDisabled &&
                    <th className='border-bottom'>Ação</th>
                  }
                </tr>
              </thead>
              <tbody className="text-center">
                <>{renderTable()}</>
              </tbody>
            </Table>

          </Row>
        </Modal.Body>
        <Modal.Footer className='justify-content-between'>
          <div>
            <Col>
              <small>Criado em: {createdAt}</small>
            </Col>
            <Col>
              <small>Atualizado em: {updatedAt}</small>
            </Col>
          </div>
          <div>
            <Button size={"md"} className='btn btn-gray me-3' onClick={handleEditable}>
              <FontAwesomeIcon icon={faPen} />
            </Button>
            {!isDisabled && (
              <Button size={"md"} onClick={handleSubmit} className='btn btn-success' >
                Salvar
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal >
    </>
  );
};

export const TicketDetailModal = ({ id, setRefreshState, refreshState }) => {
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => {
    setShowDefault(false);
  };

  const [owner, setOwner] = useState("");
  const [animal, setAnimal] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [status, setStatus] = useState("");
  const [tax, setTax] = useState("");
  const [total, setTotal] = useState("");
  const [paidAmount, setPaidAmount] = useState("");
  const [workers, setWorkers] = useState([]);
  const [services, setServices] = useState([]);

  const [createdAt, setCreatedAt] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");

  const [isDisabled, setDisabled] = useState(1);

  let fillForm = async () => {
    try {
      const { data, status } = await FindTicket(id);

      if (status === "success") {
        toast.success("Ticket encontrado com sucesso!");

        setOwner(data.owner);
        setAnimal(data.animal);
        setPaymentMethod(data.payment_method);
        setStatus(data.status);
        setTax(data.tax);
        setTotal(data.total);
        setPaidAmount(data.paid_amount);
        // setWorkers(data.workers);
        setServices(data.services);

        setCreatedAt(data.created_at);
        setUpdatedAt(data.updated_at);
        setShowDefault(true);
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao buscar o ticket!" + err);
    }
  };

  let openModal = async () => {
    fillForm();
  };

  const renderTable = () => {
    if (services) {
      return services.map((service) => {
        return (
          <tr key={service.id}>
            <td>
              <span className='fw-bold text-info'>#{service.id.toString().padStart(5, 0)}</span>
            </td>
            <td>
              <span className='fw-normal'>{service.service.name}</span>
            </td>
            <td>
              <span className='fw-normal'>{service.amount}</span>
            </td>
            <td>
              <span className={'fw-bold'}> {service.discountType === 0 ? `${service.discount}%` : `R$ ${service.discount}`}</span>
            </td>
            <td>
              <span className={`fw-bold text-success`}>R$ {service.total}</span>
            </td>
          </tr>
        );
      });
    }
  };


  return (
    <>
      <span onClick={openModal}>
        <FontAwesomeIcon icon={faEye} className='me-2' /> Detalhes
      </span>
      <Modal size={"xl"} as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className='h6'>Ver Detalhes</Modal.Title>
          <Button variant='close' aria-label='Close' onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Row>
            <h5 className='mb-2'>Dados do Ticket</h5>
            {/* <Col md={6} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>Colaborador 01</Form.Label>
                {workers[0] &&
                  <Form.Control disabled={isDisabled} type='text' value={workers[0]['name']} placeholder='Nome Exemplo' />
                }
              </Form.Group>
            </Col>
            <Col md={6} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>Comissão</Form.Label>
                {workers[0] &&
                  <Form.Control disabled={isDisabled} type='text' value={workers[0]['comissionType'] ? `R$ ${workers[0]['comission']}` : `${workers[0]['comission']}% (R$ ${(total * (workers[0]['comission'] / 100))})`} placeholder='Nome Exemplo' />
                }
              </Form.Group>
            </Col> */}
            {/* {workers[1] &&
              <>
                <Col md={6} className='mb-3'>
                  <Form.Group className='mb-2'>
                    <Form.Label>Colaborador 02</Form.Label>
                    <Form.Control disabled={isDisabled} type='text' value={workers[1]['name']} placeholder='Nome Exemplo' />
                  </Form.Group>
                </Col>


                <Col md={6} className='mb-3'>
                  <Form.Group className='mb-2'>
                    <Form.Label>Comissão</Form.Label>
                    <Form.Control disabled={isDisabled} type='text' value={workers[1]['comissionType'] ? `R$ ${workers[1]['comission']}` : `${workers[1]['comission']}% (R$ ${(total * (workers[1]['comission'] / 100))})`} placeholder='Nome Exemplo' />

                  </Form.Group>
                </Col>
              </>
            } */}

            {/* <h5 className='mb-2'>Dados do Pagamento</h5> */}
            <Col md={6} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>Total</Form.Label>
                <InputGroup>
                  <InputGroup.Text className={`${isDisabled && 'disabled'}`}><FontAwesomeIcon icon={faCoins} /></InputGroup.Text>
                  <Form.Control disabled={isDisabled} type='text' value={total} placeholder='Nome Exemplo' />
                </InputGroup>

              </Form.Group>
            </Col>
            <Col md={6} className='mb-3'>
              <Form.Group className='mb-2'>

                <Form.Label>Valor Pago</Form.Label>
                <InputGroup>
                  <InputGroup.Text className={`${isDisabled && 'disabled'}`}><FontAwesomeIcon icon={faCoins} /></InputGroup.Text>
                  <Form.Control disabled={isDisabled} type='text' value={paidAmount} placeholder='Nome Exemplo' />
                </InputGroup>
              </Form.Group>
            </Col>

            <Col md={6} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>Mét. Pagamento</Form.Label>
                <Form.Control disabled={isDisabled} type='text' value={paymentMethod.name} placeholder='Nome Exemplo' />
              </Form.Group>
            </Col>
            <Col md={2} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>Taxa</Form.Label>
                <InputGroup className="align-items-center">
                  <InputGroup.Text className="border-0"><FontAwesomeIcon className="text-danger" icon={faCreditCard} /></InputGroup.Text>
                  <span className="fw-bold text-danger">R$ {tax}</span>
                </InputGroup>

              </Form.Group>
            </Col>
          </Row>

          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Serviços Registrados</Accordion.Header>
              <Accordion.Body>

                <Table hover responsive className='align-items-center'>
                  <thead className="text-center">
                    <tr>
                      <th className='border-bottom'>ID</th>
                      <th className='border-bottom'>Serviço</th>
                      <th className='border-bottom'>Quantidade</th>
                      <th className='border-bottom'>Desconto</th>
                      <th className='border-bottom'>Total</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    <>{renderTable()}</>
                  </tbody>
                </Table>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

        </Modal.Body>
        <Modal.Footer className='justify-content-between'>
          <div>
            <Col>
              <small>Criado em: {createdAt}</small>
            </Col>
            <Col>
              <small>Atualizado em: {updatedAt}</small>
            </Col>
          </div>
          <div>
            <Button size={"md"} className='btn btn-gray' onClick={handleClose}>
              Entendido
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const DeleteTicketModal = ({ id, refreshState, setRefreshState }) => {
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => {
    setShowDefault(false);
  };

  let handleDelete = async (e) => {
    e.preventDefault();
    try {
      const { status } = await DeleteTicket(id);
      if (status === "success") {
        toast.success("Ticket deletado com sucesso!");
        refreshState === true ? setRefreshState(false) : setRefreshState(true);
        handleClose();
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao deletar o Ticket!");
    }
  };

  return (
    <>
      <span onClick={() => setShowDefault(true)}>
        <FontAwesomeIcon icon={faTrashAlt} className='me-2' /> Deletar
      </span>
      <Modal size={"md"} as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className='h6'>Deletar Dados</Modal.Title>
          <Button variant='close' aria-label='Close' onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Row className='justify-content-center text-center'>
            <FontAwesomeIcon icon={faTrashAlt} className='me-2 fa-6x text-danger col-12' />
            <span className='h4 mb-3 mt-4 col-12'>Você tem certeza?</span>
          </Row>

          <Row className='justify-content-center text-center'>
            <p className='mb-0'>Você realmente deseja deletar esses dados?</p>
            <p>Esse processo não pode ser desfeito.</p>
          </Row>
        </Modal.Body>
        <Modal.Footer className='justify-content-end'>
          <Button size={"md"} className='btn btn-gray me-3' onClick={handleClose}>
            Cancelar
          </Button>
          <Button size={"md"} onClick={handleDelete} className='btn btn-danger'>
            Deletar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const TicketAddPaymentModal = ({ id, setRefreshState, refreshState }) => {
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => setShowDefault(false);

  const [paidAmount, setPaidAmount] = useState("");
  const [maxPaidAmount, setMax] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const { data: payments, isLoading: paymentsLoading } = FetchPayments();


  let fillForm = async () => {
    try {
      const { data, status } = await FindTicket(id);
      if (status === "success") {
        setPaidAmount(data.paid_amount);
        setMax(data.paid_amount)
        setShowDefault(true);
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao buscar o cliente!");
    }

  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { status } = await UpdateTicket(id, paymentMethod, paidAmount);
      if (status === "success") {
        toast.success("Pagamento gerado com sucesso!");
        refreshState === true ? setRefreshState(false) : setRefreshState(true);
        handleClose();
        // setError({});
        // setDisabled(true);
      }
    } catch (err) {
      // const errors = err.response.data;
      // if (errors) {
      //   setError(errors.data);
      //   toast.warn("Verifique os campos obrigatórios!");
      // } else {
      //   toast.error("Ocorreu um erro ao gerar o pagamento!");
      // }
    }
  };

  return (
    <div
      onKeyDown={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}>
      <span onClick={fillForm}>
        <FontAwesomeIcon icon={faMoneyBill} className='me-2' /> Gerar Pagamento
      </span>
      <Modal size={"md"} as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className='h6'>Gerar Pagamentos</Modal.Title>

          <Button variant='close' aria-label='Close' onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>Valor Pago</Form.Label>
                <Form.Control onKeyDown={e => e.stopPropagation()} type='number' as={IMaskInput} max={maxPaidAmount} value={paidAmount} onChange={(e) => setPaidAmount(e.target.value)} placeholder='Valor Pago' />
              </Form.Group>
            </Col>
            <Typeahead
              id='payments'
              labelKey={(payments) => `${payments.name}`}
              onChange={(value) => value[0] && setPaymentMethod(value[0].id)}
              options={payments}
              placeholder='Escolha um dos métodos de pagamento'
              className='is-invalid'
              isLoading={paymentsLoading}
            />
          </Row>
        </Modal.Body>
        <Modal.Footer>

          <Button size={"md"} type={'submit'} onClick={handleSubmit} className='btn btn-success float-end'>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export const ExamDetailModal = ({ id, setRefreshState, refreshState }) => {
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => {
    setShowDefault(false);
  };

  const [createdAt, setCreatedAt] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");

  const [name, setName] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [file, setFile] = useState("");

  let fillForm = async () => {
    try {
      const { data, status } = await FindExamById(id);

      if (status === "success") {
        toast.success("Exame encontrado com sucesso!");

        setFile(data.file);
        setName(data.pet.name);
        setOwnerName(data.pet.owner.name);

        setCreatedAt(data.created_at);
        setUpdatedAt(data.updated_at);
        setShowDefault(true);
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao buscar o exame!");
    }
  };

  let openModal = async () => {
    fillForm();
  };


  return (
    <>
      <span onClick={openModal}>
        <FontAwesomeIcon icon={faEye} className='me-2' /> Detalhes
      </span>
      <Modal size={"xl"} as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className='h6'>Ver Detalhes</Modal.Title>
          <Button variant='close' aria-label='Close' onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <h5 className='mb-2'>Exame Anexado</h5>
          <hr />
          <Col md={12} className='text-center'>
            <object data={file} type="application/pdf" className="img-fluid mb-3" width="100%" height="100%" style={{ height: '600px' }}>
              <p>Anexo do Exame <a href={file}></a></p>
            </object>

          </Col>
          <hr />
          <h5 className='mb-2'>Dados do Tutor</h5>
          <Row>
            <Col sm={12} xl={6} className='mb-3'>
              <Form.Group>
                <Form.Label>Tutor</Form.Label>
                <Form.Control disabled type='text' value={ownerName} />
              </Form.Group>
            </Col>
            <Col sm={12} xl={6} className='mb-3'>
              <Form.Group>
                <Form.Label>Nome do Animal</Form.Label>
                <Form.Control disabled type='text' value={name} />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className='justify-content-between'>
          <div>
            <Col>
              <small>Criado em: {createdAt}</small>
            </Col>
            <Col>
              <small>Atualizado em: {updatedAt}</small>
            </Col>
          </div>
          <div>
            <Button size={"md"} className='btn btn-gray' onClick={handleClose}>
              Entendido
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const UserDetailModal = ({ id, setRefreshState, refreshState }) => {
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => setShowDefault(false);
  const [createdAt, setCreatedAt] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [crmv, setCRMV] = useState("");
  const [crmvs, setCRMVS] = useState("");
  const [mapa, setMAPA] = useState("");

  const [role, setRole] = useState("");
  const [isDisabled, setDisabled] = useState(true);

  const { data: roles, isLoading: rolesLoading } = FetchRoles();

  const states = [
    { id: 0, name: "AC" },
    { id: 1, name: "AL" },
    { id: 2, name: "AP" },
    { id: 3, name: "AM" },
    { id: 4, name: "BA" },
    { id: 5, name: "CE" },
    { id: 6, name: "DF" },
    { id: 7, name: "ES" },
    { id: 8, name: "GO" },
    { id: 9, name: "MA" },
    { id: 10, name: "MT" },
    { id: 11, name: "MS" },
    { id: 12, name: "MG" },
    { id: 13, name: "PA" },
    { id: 14, name: "PB" },
    { id: 15, name: "PR" },
    { id: 16, name: "PE" },
    { id: 17, name: "PI" },
    { id: 18, name: "RJ" },
    { id: 19, name: "RN" },
    { id: 20, name: "RS" },
  ];

  let fillForm = async () => {
    try {
      const { data, status } = await FindUser(id);
      if (status === "success") {
        setUsername(data.username);
        setEmail(data.email);
        setRole(data.role);
        setName(data.name);

        if (data.role.id == 3 || data.role.id == 2) {
          setCRMV(data.vet_info.crmv);
          setCRMVS(data.vet_info.crmv_state);
          setMAPA(data.vet_info.mapa);
        }
        setCreatedAt(data.created_at);
        setUpdatedAt(data.updated_at);

        setShowDefault(true);
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao buscar o funcionário!" + err);
    }
  };

  const resetUserPassword = async () => {
    try {
      const response = await resetPassword(id);
      if (response.data.status === "success") {
        toast.success("Senha resetada com sucesso! Definida para: Troca123");
        handleClose();
      } else {
        throw new Error();
      }
    } catch (err) {
      if (err.response.data.errors) {
        toast.warn("Verifique os campos obrigatórios!");
      } else {
        toast.error("Ocorreu um erro ao resetar a senha!");
      }
    }
  };

  let handleEditable = async () => {
    isDisabled === true ? setDisabled(false) : setDisabled(true);
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { status } = await UpdateUser(id, username, name, email, role.id, crmv, crmvs, mapa);
      console.log(status);
      if (status === "success") {
        toast.success("Colaborador atualizado com sucesso!");
        refreshState === true ? setRefreshState(false) : setRefreshState(true);
        handleClose();
        setDisabled(true);
      }
    } catch (err) {
      const errors = err.response.data;
      if (errors) {
        toast.warn("Verifique os campos obrigatórios!");
      } else {
        toast.error("Ocorreu um erro ao atualizar o Serviço!");
      }
    }
  };

  return (
    <>
      <span onClick={fillForm}>
        <FontAwesomeIcon icon={faEye} className='me-2' /> Detalhes
      </span>
      <Modal size={"xl"} as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className='h6'>Ver Detalhes</Modal.Title>

          <Button variant='close' aria-label='Close' onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Row>
            <h5 className='mb-2'>Dados do Funcionário</h5>
            <Col md={12} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>Usuário do Sistema</Form.Label>
                <Form.Control disabled={isDisabled} type='text' value={username} onChange={(e) => setUsername(e.target.value)} placeholder='Nome Exemplo' />
              </Form.Group>
            </Col>
            <Col md={6} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>Nome</Form.Label>
                <Form.Control disabled={isDisabled} type='text' value={name} onChange={(e) => setName(e.target.value)} placeholder='Nome Exemplo' />
              </Form.Group>
            </Col>
            <Col md={6} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>Email</Form.Label>
                <Form.Control disabled={isDisabled} type='text' value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Nome Exemplo' />
              </Form.Group>
            </Col>
            <Col md={6} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>Função</Form.Label>
                <Typeahead
                  labelKey={(roles) => `${roles.name}`}
                  onChange={(value) => value[0] && setRole(value[0].id)}
                  options={roles}
                  disabled={isDisabled}
                  placeholder='Escolha uma das funções'
                  isLoading={rolesLoading}
                  className='is-invalid'
                  defaultInputValue={role.name}

                />              </Form.Group>
            </Col>
          </Row>
          {role.id == 3 || role.id == 2 &&
            <>
              <hr></hr>
              <h5 className='mb-2'>Dados do Veterinário</h5>
              <Row>
                <Col md={6} className='mb-3'>
                  <Form.Group className='mb-2'>
                    <Form.Label>CRMV</Form.Label>
                    <Form.Control disabled={isDisabled} type='text' value={crmv} onChange={(e) => setCRMV(e.target.value)} placeholder='Nome Exemplo' />
                  </Form.Group>
                </Col>
                <Col md={6} className='mb-3'>
                  <Form.Group className='mb-2'>
                    <Form.Label>CRMV Estado</Form.Label>
                    <Typeahead
                      labelKey={(states) => `${states.name}`}
                      onChange={(value) => value[0] && setCRMVS(value[0].name)}
                      options={states}
                      disabled={isDisabled}
                      placeholder='Escolha uma das funções'
                      isLoading={rolesLoading}
                      className='is-invalid'
                      defaultInputValue={crmvs}

                    />
                  </Form.Group>
                </Col>
                <Col md={6} className='mb-3'>
                  <Form.Group className='mb-2'>
                    <Form.Label>MAPA</Form.Label>
                    <Form.Control disabled={isDisabled} type='text' value={mapa} onChange={(e) => setMAPA(e.target.value)} placeholder='Nome Exemplo' />
                  </Form.Group>
                </Col>
              </Row>
            </>}
        </Modal.Body>
        <Modal.Footer className='justify-content-between'>
          <div>
            <Col>
              <small>Criado em: {createdAt}</small>
            </Col>
            <Col>
              <small>Atualizado em: {updatedAt}</small>
            </Col>
          </div>
          <div>
            <Button size={"md"} className='btn btn-danger me-3' onClick={resetUserPassword}>
              <FontAwesomeIcon icon={faUndo} className='me-1' />
              Resetar Senha
            </Button>
            <Button size={"md"} className='btn btn-gray me-3' onClick={handleEditable}>
              <FontAwesomeIcon icon={faPencil} className='me-1' />
            </Button>

            {!isDisabled && (
              <Button size={"md"} onClick={handleSubmit} className='btn btn-success' >
                <FontAwesomeIcon icon={faCheck} className='me-1' />
                Salvar
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const PaymentDetailModal = ({ id, setRefreshState, refreshState }) => {
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => setShowDefault(false);
  const [createdAt, setCreatedAt] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");

  const [name, setName] = useState("");
  const [tax, setTax] = useState("");
  const [textColor, setTextColor] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("");

  const [isDisabled, setDisabled] = useState(true);
  const [error, setError] = useState("");

  let fillForm = async () => {
    try {
      const { data, status } = await FindPayment(id);
      if (status === "success") {
        setName(data.name);
        setTax(data.tax);
        setTextColor(data.text_color);
        setBackgroundColor(data.background_color);
        setCreatedAt(data.created_at);
        setUpdatedAt(data.updated_at);

        setShowDefault(true);
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao buscar o mét pagamento!");
    }
  };

  let handleEditable = async () => {
    isDisabled === true ? setDisabled(false) : setDisabled(true);
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { status } = await UpdatePayment(id, name, tax, textColor, backgroundColor);
      if (status === "success") {
        toast.success("Mét. pagamento atualizado com sucesso!");
        refreshState === true ? setRefreshState(false) : setRefreshState(true);
        handleClose();
        setError({});
        setDisabled(true);
      }
    } catch (err) {
      const errors = err.response.data;
      if (errors) {
        setError(errors.data);
        toast.warn("Verifique os campos obrigatórios!");
      } else {
        toast.error("Ocorreu um erro ao atualizar o Mét. Pagamento!");
      }
    }
  };

  return (
    <div onKeyDown={e => e.stopPropagation()}>
      <span onClick={fillForm}>
        <FontAwesomeIcon icon={faEye} className='me-2' /> Detalhes
      </span>
      <Modal size={"xl"} as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className='h6'>Ver Detalhes</Modal.Title>

          <Button variant='close' aria-label='Close' onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Row>
            <h5 className='mb-2'>Dados do Mét. Pagamento</h5>
            <Col md={6} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>Nome</Form.Label>
                <Form.Control disabled={isDisabled} type='text' value={name} onChange={(e) => setName(e.target.value)} placeholder='Nome Exemplo' />
              </Form.Group>
            </Col>
            <Col md={6} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>Taxa</Form.Label>
                <Form.Control disabled={isDisabled} type='text' value={tax} onChange={(e) => setTax(e.target.value)} placeholder='Nome Exemplo' />
              </Form.Group>
            </Col>
            <Col md={6} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>Cor do Texto</Form.Label>
                <Form.Control disabled={isDisabled} type='color' value={textColor} onChange={(e) => setTextColor(e.target.value)} placeholder='Nome Exemplo' />
              </Form.Group>
            </Col>
            <Col md={6} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>Cor de Fundo</Form.Label>
                <Form.Control disabled={isDisabled} type='color' value={backgroundColor} onChange={(e) => setBackgroundColor(e.target.value)} placeholder='Nome Exemplo' />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className='justify-content-between'>
          <div>
            <Col>
              <small>Criado em: {createdAt}</small>
            </Col>
            <Col>
              <small>Atualizado em: {updatedAt}</small>
            </Col>
          </div>
          <div>
            <Button size={"md"} className='btn btn-gray me-3' onClick={handleEditable}>
              <FontAwesomeIcon icon={faPen} />
            </Button>
            {!isDisabled && (
              <Button size={"md"} onClick={handleSubmit} className='btn btn-success'>
                Salvar
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export const CommissionDetailModal = ({ id, setRefreshState, refreshState }) => {
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => setShowDefault(false);
  const [createdAt, setCreatedAt] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");

  // const [worker, setWorker] = useState("");
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [servicePrice, setServicePrice] = useState("");
  const [ticketPaid, setTicketPaid] = useState("");
  const [ticketTax, setTicketTax] = useState("");
  // const [service, setService] = useState("");
  // const [commission, setCommission] = useState("");

  // const [commission, setCommission] = useState("");
  // const [commission, setCommission] = useState("");

  let fillForm = async () => {
    try {
      const { data, status } = await FindCommission(id);
      if (status === "success") {
        console.log(data.user);
        setName(data.user.username);
        setRole(data.user.role.name);
        setServiceName(data.service.name);
        setServicePrice(data.service.price);
        setTicketPaid(data.ticket.paid_amount);
        setTicketTax(data.ticket.tax);

        // setCommission(data.commission);

        setCreatedAt(data.created_at);
        setUpdatedAt(data.updated_at);

        setShowDefault(true);
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao buscar a comissão");
    }
  };

  return (
    <div onKeyDown={e => e.stopPropagation()}>
      <span onClick={fillForm}>
        <FontAwesomeIcon icon={faEye} className='me-2' /> Detalhes
      </span>
      <Modal size={"xl"} as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className='h6'>Ver Detalhes</Modal.Title>

          <Button variant='close' aria-label='Close' onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Row>
            <h5 className='mb-2'>Dados do Colaborador</h5>
            <Col md={6} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>Nome</Form.Label>
                <Form.Control disabled type='text' value={name} placeholder='Nome' />
              </Form.Group>
            </Col>
            <Col md={6} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>Função</Form.Label>
                <Form.Control disabled type='text' value={role} placeholder='Função' />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <h5 className='mb-2'>Dados do Serviço</h5>
            <Col md={6} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>Serviço</Form.Label>
                <Form.Control disabled type='text' value={serviceName} placeholder='Nome do Serviço' />
              </Form.Group>
            </Col>
            <Col md={6} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>Preço (R$)</Form.Label>
                <Form.Control disabled type='text' value={servicePrice} placeholder='Preço Serviço' />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <h5>Dados do Ticket</h5>
            <Col md={6} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>Valor Pago (R$)</Form.Label>
                <Form.Control disabled type='text' value={ticketPaid} placeholder='Valor Pago Ticket' />
              </Form.Group>
            </Col>
            <Col md={6} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>Taxa (R$)</Form.Label>
                <Form.Control disabled type='text' value={ticketTax} placeholder='Taxa Ticket' />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className='justify-content-between'>
          <div>
            <Col>
              <small>Criado em: {createdAt}</small>
            </Col>
            <Col>
              <small>Atualizado em: {updatedAt}</small>
            </Col>
          </div>
          <div>
            <Button size={"md"} className='btn btn-gray me-3' onClick={handleClose}>
              <FontAwesomeIcon icon={faPen} /> Entendido
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div >
  );
};

export const CheckExamPasswordModal = ({ id, setRefreshState, refreshState }) => {
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => {
    setShowDefault(false);
  };
  const [password, setPassword] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  let openModal = async () => {
    setShowDefault(true);
  };

  let checkPassword = async () => {
    try {
      const { status } = await FindExam(id, password);
      if (status === "success") {
        setPassword("");
        setShowDefault(false);
        setIsSuccess(!isSuccess);
        toast.info("Carregando Exame...");
      }
    } catch (err) {
      toast.error("A senha inserida está incorreta.");
    }
  };

  return (
    <>
      <span onClick={openModal}>
        <FontAwesomeIcon icon={faEye} className='me-2' /> Detalhes
      </span>
      <Modal size={"md"} as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className='h6'>Ver Detalhes</Modal.Title>
          <Button variant='close' aria-label='Close' onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Row className='justify-content-center text-center'>
            <FontAwesomeIcon icon={faLock} className='me-2 fa-6x text-danger col-12' />
            <span className='h4 mb-3 mt-4 col-12'>Senha necessária!</span>
          </Row>

          <Row className='justify-content-center text-center'>
            <p className='mb-0'>Digite a senha para acessar os dados do exame.</p>
          </Row>
          <Row>
            <Col sm={12} xl={12} className='mt-3'>
              <Form.Group>
                <Form.Control type='password' placeholder='Insira a senha do Exame' value={password} onChange={(e) => setPassword(e.target.value)} />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className='justify-content-end'>
          <Button size={"md"} onClick={checkPassword} className='btn btn-info'>
            Acessar
          </Button>
        </Modal.Footer>
      </Modal>

      {isSuccess && (
        <ExamDetailModal id={id} setRefreshState={setRefreshState} refreshState={refreshState} setIsSuccess={setIsSuccess} isSuccess={isSuccess} />
      )}
    </>
  );
};

export const ResetExamPasswordModal = ({ id, setRefreshState, refreshState }) => {
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => {
    setShowDefault(false);
  };

  let openModal = async () => {
    setShowDefault(true);
  };

  let resetPassword = async () => {
    try {
      const { data, status } = await ResetExamPassword(id);
      if (status === "success") {
        toast.success("Senha resetada com sucesso!");
        toast.info(`A senha desse exame é: ${data}`, {
          autoClose: false,
          pauseOnHover: true,
        });

        setShowDefault(false);
      }
    } catch (err) {
      toast.error("Houve um erro ao resetar a senha!");
    }
  };

  return (
    <>
      <span onClick={openModal}>
        <FontAwesomeIcon icon={faLockOpen} className='me-2' /> Resetar Senha
      </span>
      <Modal size={"md"} as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className='h6'>Reset de Senha</Modal.Title>
          <Button variant='close' aria-label='Close' onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Row className='justify-content-center text-center'>
            <FontAwesomeIcon icon={faLockOpen} className='me-2 fa-6x text-warning col-12' />
            <span className='h4 mb-3 mt-4 col-12'>Você tem certeza?</span>
          </Row>

          <Row className='justify-content-center text-center'>
            <p className='mb-0'>Realizando essa ação a senha será redefinada e mostrada no canto superior direito.</p>
          </Row>
        </Modal.Body>
        <Modal.Footer className='justify-content-end'>
          <Button size={"md"} className='btn btn-gray me-3' onClick={handleClose}>
            Cancelar
          </Button>
          <Button size={"md"} onClick={resetPassword} className='btn btn-danger'>
            Resetar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const CreateExamModal = ({ id, refreshState, setRefreshState }) => {
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => {
    setShowDefault(false);
  };

  let openModal = async () => {
    setShowDefault(true);
  };

  const [owner, setOwner] = useState("");
  const [petId, setPetId] = useState("");
  const [file, setFile] = useState("");
  const [animals, setAnimals] = useState([]);
  const [isAnimalDisabled, setIsAnimalDisabled] = useState(true);
  const [error, setError] = useState({});
  const inputTutor = React.createRef();
  const inputAnimal = React.createRef();

  const fileInputRef = useRef();


  /**
   * USE EFFECT PARA EXECUTAR A FUNÇÃO QUE FAZ A LIBERAÇÃO DO ANIMAL QUANDO SELECIONA O DONO
   */
  useEffect(() => {
    findCustomerFromId(id);
  }, [id]);

  let findCustomerFromId = async (e) => {
    const { data: owner } = await FindCustomer(id);
    if (owner.pets !== undefined) {
      setOwner(owner);
      if (owner.pets.length > 0) {
        setAnimals(owner.pets);
        setIsAnimalDisabled(false);
      } else {
        toast.error("O tutor selecionado não possui nenhum pet cadastrado!");
        handleClose();
        inputTutor.current.clear();
      }
    }
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await CreateExam(petId, file);

      if (response.data.status === "success") {
        toast.success("Exame cadastrado com sucesso!");
        setPetId("");
        setError("");
        inputAnimal.current.clear();
        fileInputRef.current.value = null;
        refreshState === true ? setRefreshState(false) : setRefreshState(true);

        handleClose();
      } else {
        throw new Error("Falha ao cadastrar o exame.");
      }
    } catch (err) {
      if (err.response) {
        toast.warn("Verifique os campos obrigatórios!");
        setError(err.response.data.data);
      } else {
        toast.error("Ocorreu um erro ao cadastrar o animal!" + err);
      }
    }
  };

  return (
    <div
      onKeyDown={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}>

      <span onClick={openModal}>
        <FontAwesomeIcon icon={faFileArchive} className='me-2' /> Cadastrar Exame
      </span>
      <Modal size={"xl"} as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className='h6'>Formulário de Cadastramento de Exames</Modal.Title>
          <Button variant='close' aria-label='Close' onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <h5 className='mb-4'>Dados do Animal</h5>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6} className='mb-3'>
                <Form.Group className='mb-2'>
                  <Form.Label>Tutor</Form.Label>{" "}
                  <Form.Control
                    disabled={true}
                    type='text'
                    value={owner.name}
                  />
                </Form.Group>
                <Form.Control.Feedback type='invalid'>{error.owner_id}</Form.Control.Feedback>
              </Col>
              <Col md={6} className='mb-3'>
                <Form.Group className='mb-2'>
                  <Form.Label>Animais do Tutor</Form.Label>
                  <Typeahead
                    disabled={isAnimalDisabled}
                    ref={inputAnimal}
                    id='animals'
                    labelKey={(animals) => `${animals.name}`}
                    onChange={(value) => value[0] && setPetId(value[0].id)}
                    options={animals}
                    placeholder='Escolha um dos animais do tutor'
                    // isLoading={animals}
                    className='is-invalid'
                    isInvalid={!!error.pet_id}
                  />
                  <Form.Control.Feedback type='invalid'>{error.pet_id}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={12} md={12} className='mb-3'>
                <Form.Group className='mb-2'>
                  <Form.Label>Exame</Form.Label>
                  <Form.Control
                    // disabled={isAnimalDisabled}
                    ref={fileInputRef}
                    type='file'
                    onChange={(e) => setFile(e.target.files[0])}
                    accept='.png,.jpg,.jpeg,.pdf'
                    isInvalid={!!error.file}
                  />
                  <Form.Text muted>Seu arquivo deve ser estar em .pdf, .jpg ou .png</Form.Text>
                  <Form.Control.Feedback type='invalid'>{error.file}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <div className='d-flex mt-3 justify-content-end'>
              <Button variant='primary' type='submit'>
                Cadastrar Dados
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export const CreateAnimalModal = ({ id, refreshState, setRefreshState, simplified = false }) => {
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => {
    setShowDefault(false);
  };

  let openModal = async () => {
    setShowDefault(true);
  };

  const [name, setName] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [gender, setGender] = useState("");
  const [specie, setSpecie] = useState("");
  const [color, setColor] = useState("");
  const [race, setRace] = useState("");
  const [deceased, setDeceased] = useState();
  const [birthAt, setBirthAt] = useState("");
  const [tag, setTag] = useState("");
  const [chip, setChip] = useState("");
  const [castrated, setCastrated] = useState("");

  const [error, setError] = useState({});

  const ref = React.createRef();

  const { data: species, isLoading: speciesLoading } = FetchSpecies();
  const { data: races, isLoading: racesLoading } = FetchRaces();
  const { data: colors, isLoading: colorsLoading } = FetchColors();

  const genders = [{ name: "Macho" }, { name: "Fêmea" }];

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { status } = await CreateAnimal(id, name, gender, specie, color, race, deceased, birthAt, tag, chip, castrated);
      if (status === "success") {
        toast.success("Animal cadastrado com sucesso!");
        setName("");
        setGender("");
        setSpecie("");
        setColor("");
        setRace("");
        setDeceased();
        setBirthAt("");
        setTag("");
        setChip("");
        setCastrated("");
        setError("");
        ref.current.clear();
        refreshState === true ? setRefreshState(false) : setRefreshState(true);

        handleClose();
      }
    } catch (err) {
      const errors = err.response.data;
      if (errors) {
        toast.warn("Verifique os campos obrigatórios!");
        setError(errors.data);
      } else {
        toast.error("Ocorreu um erro ao cadastrar o animal!");
      }
    }
  };



  let findCustomerfromId = async (e) => {
    const { data: customer } = await FindCustomer(id);
    setOwnerName(customer.name);
    openModal();
  };

  useEffect(() => {
    findCustomerfromId(id);
  }, [id]);

  return (
    <div
      onKeyDown={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}>
      {simplified == false &&
        <>
          <span onClick={openModal}>
            <FontAwesomeIcon icon={faDog} className='me-2' /> Cadastrar Animal
          </span>
        </>
      }
      <Modal size={"xl"} as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className='h6'>Formulário de Cadastramento de Pets</Modal.Title>
          <Button variant='close' aria-label='Close' onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <h5 className='mb-4'>Dados do Animal</h5>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6} className='mb-3'>
                <Form.Group className='mb-2'>
                  <Form.Label>Tutor</Form.Label>
                  <Form.Control
                    type='text'
                    disabled
                    value={ownerName}
                    placeholder={"Preencha o nome do tutor"}
                  />
                  <Form.Control.Feedback type='invalid'>{error.owner_id}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6} className='mb-3'>
                <Form.Group className='mb-2'>
                  <Form.Label>Nome</Form.Label>
                  <Form.Control
                    type='text'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder={"Preencha o nome do animal"}
                    isInvalid={!!error.name}
                  />
                  <Form.Control.Feedback type='invalid'>{error.name}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={3} md={4} className='mb-3'>
                <Form.Group className='mb-2'>
                  <Form.Label className='col-10 text-truncate'>Carteirinha <small>(Opcional)</small></Form.Label>
                  <Form.Control type='text' value={tag} onChange={(e) => setTag(e.target.value)} placeholder={"000000"} isInvalid={!!error.tag} />
                  <Form.Control.Feedback type='invalid'>{error.tag}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={3} md={4} className='mb-3'>
                <Form.Group className='mb-2'>
                  <Form.Label className='col-10 text-truncate'>Data de Nascimento</Form.Label>
                  <Datetime
                    timeFormat={false}
                    closeOnSelect={true}
                    onChange={setBirthAt}
                    renderInput={(props, openCalendar) => (
                      <InputGroup hasValidation>
                        <InputGroup.Text className={!!error.born_at && "is-invalid"}>
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          isInvalid={!!error.born_at}
                          type='text'
                          value={birthAt ? moment(birthAt).format("DD/MM/YYYY") : ""}
                          placeholder='dd/mm/yyyy'
                          onFocus={openCalendar}
                          onChange={() => { }}
                        />
                        <Form.Control.Feedback type='invalid'>{error.born_at}</Form.Control.Feedback>
                      </InputGroup>
                    )}
                  />
                  <Form.Control.Feedback type='invalid'>{error.born_at}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={2} md={4} className='mb-3'>
                <Form.Group className='mb-2'>
                  <Form.Label>Óbito</Form.Label>
                  <div>
                    <Form.Check inline label='Sim' name='deceased' type={"radio"} value='1' onChange={(e) => setDeceased(1)} />
                    <Form.Check inline name='deceased' label='Não' type={"radio"} value='0' onChange={(e) => setDeceased(0)} />
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <h5 className='mb-4'>Características do Animal</h5>

            <Row>
              <Col md={6} className='mb-3'>
                <Form.Group className='mb-2'>
                  <Form.Label>Gênero</Form.Label>
                  <Typeahead
                    ref={ref}
                    id='gender'
                    isInvalid={!!error.gender}
                    labelKey={(genders) => `${genders.name}`}
                    onChange={(value) => value[0] && setGender(value[0].name)}
                    options={genders}
                    className='is-invalid'
                    placeholder='Escolha o gênero do animal'
                  />
                  <Form.Control.Feedback type='invalid'>{error.gender}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6} className='mb-3'>
                <Form.Group className='mb-2'>
                  <Form.Label>Raça</Form.Label>
                  <Typeahead
                    id='races'
                    labelKey={(races) => `${races.name}`}
                    onChange={(value) => value[0] && setRace(value[0].id)}
                    options={races}
                    placeholder='Escolha uma das espécies'
                    className='is-invalid'
                    isLoading={racesLoading}
                  />
                  <Form.Control.Feedback type='invalid'>{error.race}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6} className='mb-3'>
                <Form.Group className='mb-2'>
                  <Form.Label>Espécie</Form.Label>
                  <Typeahead
                    id='species'
                    labelKey={(species) => `${species.name}`}
                    onChange={(value) => value[0] && setSpecie(value[0].id)}
                    options={species}
                    placeholder='Escolha uma das espécies'
                    className='is-invalid'
                    isLoading={speciesLoading}
                  />
                  <Form.Control.Feedback type='invalid'>{error.species}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6} className='mb-3'>
                <Form.Group className='mb-2'>
                  <Form.Label>Coloração</Form.Label>
                  <Typeahead
                    id='colors'
                    labelKey={(colors) => `${colors.name}`}
                    onChange={(value) => value[0] && setColor(value[0].id)}
                    options={colors}
                    placeholder='Escolha uma das espécies'
                    className='is-invalid'
                    isLoading={colorsLoading}
                  />
                  <Form.Control.Feedback type='invalid'>{error.color}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6} className='mb-3'>
                <Form.Group className='mb-2'>
                  <Form.Label>Chip <small>(Opcional)</small></Form.Label>
                  <Form.Control
                    type='text'
                    value={chip}
                    onChange={(e) => setChip(e.target.value)}
                    placeholder={"Chips"}
                    isInvalid={!!error.chips}
                  />
                </Form.Group>
              </Col>
              <Col md={6} className='mb-3'>
                <Form.Group className='mb-2'>
                  <Form.Label>Castrado?</Form.Label>
                  <div>
                    <Form.Check inline label='Sim' name='castrated' type={"radio"} value='1' onChange={(e) => setCastrated(1)} />
                    <Form.Check inline name='castrated' label='Não' type={"radio"} value='0' onChange={(e) => setCastrated(0)} />
                  </div>
                  <Form.Control.Feedback type='invalid'>{error.color}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <div className='d-flex mt-3 justify-content-end'>
              <Button variant='primary' type='submit'>
                Cadastrar Dados
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );

};

export const PaymentFilter = ({ setFilter, setRefreshState, refreshState }) => {
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => {
    setShowDefault(false);
  };

  let openModal = async () => {
    setShowDefault(true);
  };

  const [name, setName] = useState("");
  const [textColor, setTextColor] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("");
  const [tax, setTax] = useState("");
  const [error, setError] = useState("");


  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await CreatePayment(name, textColor, backgroundColor, tax);
      console.log(response);
      if (response.status === "success") {
        toast.success("Mét. Pagamento cadastrado com sucesso!");
        setName("");
        setTextColor("");
        setBackgroundColor("");
        setTax("");

        setError("");
        refreshState === true ? setRefreshState(false) : setRefreshState(true);

        handleClose();
      }

    } catch (err) {

      if (err.response) {
        toast.warn("Verifique os campos obrigatórios!");
        setError(err.response.data.data);
      } else {
        toast.error("Ocorreu um erro ao cadastrar o mét. pagamento!" + err);
      }
    }
  };

  return (
    <div className="table-settings"

      onKeyDown={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}>

      <Row className="justify-content-between align-items-center">
        <Col xs={8} md={6} lg={3} xl={4}>
          <InputGroup>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faSearch} />
            </InputGroup.Text>
            <Form.Control type="text" placeholder="Procurar na Tabela" />
          </InputGroup>
        </Col>
        <Col xs={4} md={2} xl={2} className="ps-md-0 text-end">
          <React.Fragment>
            <Button variant="success" className="my-3" onClick={() => setShowDefault(true)}> <FontAwesomeIcon icon={faPlus} /> Cadastrar Pagamento</Button>

            <Modal size={"xl"} as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
              <Modal.Header>
                <Modal.Title className='h6'>Formulário de Cadastro de Mét. Pagamentos</Modal.Title>
                <Button variant='close' aria-label='Close' onClick={handleClose} />
              </Modal.Header>
              <Modal.Body>
                <h5 className='mb-4'>Dados do Método</h5>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col md={6} className='mb-3'>
                      <Form.Group className='mb-2'>
                        <Form.Label>Nome</Form.Label>{" "}
                        <Form.Control
                          type='text'
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          placeholder={"Insira o nome do Método"}
                          isInvalid={!!error.name}
                        />
                        <Form.Control.Feedback type='invalid'>{error.name}</Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6} className='mb-3'>
                      <Form.Group className='mb-2'>
                        <Form.Label>Taxa</Form.Label>{" "}
                        <Form.Control
                          type='number'
                          value={tax}
                          onChange={(e) => setTax(e.target.value)}
                          placeholder={"Insira a porcentagem da taxa"}
                          isInvalid={!!error.tax}
                        />
                        <Form.Control.Feedback type='invalid'>{error.tax}</Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6} className='mb-3'>
                      <Form.Group className='mb-2'>
                        <Form.Label>Cor do Texto</Form.Label>{" "}
                        <Form.Control
                          type='color'
                          value={textColor}
                          onChange={(e) => setTextColor(e.target.value)}
                          placeholder={"Insira a cor do texto"}
                          isInvalid={!!error.textColor}
                        />
                        <Form.Control.Feedback type='invalid'>{error.name}</Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6} className='mb-3'>
                      <Form.Group className='mb-2'>
                        <Form.Label>Cor de Fundo</Form.Label>{" "}
                        <Form.Control
                          type='color'
                          value={backgroundColor}
                          onChange={(e) => setBackgroundColor(e.target.value)}
                          placeholder={"Insira a cor de fundo"}
                          isInvalid={!!error.backgroundColor}
                        />
                        <Form.Control.Feedback type='invalid'>{error.name}</Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <div className='d-flex mt-3 justify-content-end'>
                    <Button variant='primary' type='submit'>
                      Cadastrar Dados
                    </Button>
                  </div>
                </Form>
              </Modal.Body>
            </Modal>
          </React.Fragment>
        </Col>

      </Row>
    </div>
  )

};