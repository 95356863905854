import "./scss/volt.scss";
import "react-datetime/css/react-datetime.css";
import 'react-toastify/dist/ReactToastify.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-loading-skeleton/dist/skeleton.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";

// core styles


import HomePage from "./pages/HomePage";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <HomePage />
  </Router>
);
