
import { useParams } from "react-router-dom";
import React, { useEffect, useState, Component } from "react";
import { Row, Form, Modal, Button, Col, InputGroup, Accordion, Spinner, Table, Breadcrumb, Card, Nav, Tab, Dropdown, ButtonGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Datetime from "react-datetime";
import { faCalendarAlt, faPen, faEye, faTrashAlt, faWarning, faLock, faLockOpen, faUndo, faRefresh, faDog, faList, faHome, faUser, faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import "moment/locale/pt-br";
import { toast } from "react-toastify";
import { IMaskInput } from "react-imask";
import moment from "moment-timezone";
import { FindCurrentCustomer, FindCustomer, GetCurrentCustomer } from "../../../services/CustomerService";
import Skeleton from "react-loading-skeleton";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { DeleteExam, FindExamByCurrentCustomer, FindExamByCustomer, ResetExamPassword } from "../../../services/ExamService";
import { CheckExamPasswordModal, DeleteExamModal, ExamDetailModal, ResetExamPasswordModal } from "../../../components/Modals";
import { CustomerExamTable } from "../../../components/Tables";
import { PaginationControl } from "react-bootstrap-pagination-control";
import { CustomerAnimalFilter } from "../../../components/Filters";


export default () => {


  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [name, setName] = useState("");
  const [filter, setFilter] = useState({});
  const [refreshState, setRefreshState] = useState();
  useEffect(() => {
    fillForm();
  }, []);

  let fillForm = async () => {
    try {
      const { data, status } = await FindCurrentCustomer();
      if (status === "success") {
        setData(data);
        setName(data.name);
        setLoading(false);

      }
    } catch (error) {
      toast.error("Ocorreu um erro ao buscar o cliente!");
    }
  }

  return (
    <div className="justify-content-between flex-wrap flex-md-nowrap py-4 p-5">
      <div className="mb-4 mb-lg-0">

        <div className="d-block mb-4 mb-md-0 col-md-auto"><h1 className="h2">Olá, {name}</h1></div>

        <TutorHeader data={data} isLoading={isLoading} />
        <section>
          <h3 className="mt-3">Seus Exames</h3>
          {data.id >= 0 &&
            <>
            <CustomerAnimalFilter id={data.id} setFilter={setFilter} refreshState={refreshState} setRefreshState={setRefreshState} />
            </>
          }
        <Card border='light' className="mt-3">
          <ExamTable id={data.id} filter={filter} refreshState={refreshState} setRefreshState={setRefreshState} />
        </Card>
      </section>
    </div>
    </div >
  );
};

export const TutorHeader = ({ data, isLoading }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [cpf, setCPF] = useState("");
  const [street, setStreet] = useState("");
  const [district, setDistrict] = useState("");
  const [city, setCity] = useState("");
  const [cep, setCEP] = useState("");
  const [phone, setPhone] = useState("");
  const [id, setID] = useState("");

  const SetCustomerData = (data) => {
    console.log(data.id);
    setID(data.id);
    setName(data.name);
    setEmail(data.email);
    setCPF(data.cpf);
    setCEP(data.cep);
    setPhone(data.phone)
    setStreet(data.street);
    setDistrict(data.district);
    setCity(data.city);

  }

  useEffect(() => {
    SetCustomerData(data);
  }, [data]);

  const address = street + ", " + district + ", " + city + " - " + cep;

  return (
    <section className="mt-3">
      <Card border='light' className='table-wrapper table-responsive shadow-sm'>
        <Card.Body>
          <div className="d-flex">
            <FontAwesomeIcon size='10x' icon={faUser} color="black" border={true} />
            <Row className="ms-3">
              <Col md={4}>
                <Form.Group>
                  <Form.Label>Nome</Form.Label>
                  {isLoading ? <Skeleton height={'40px'} width={'100%'} /> :
                    <Form.Control
                      type='text'
                      placeholder='Nome Exemplo'
                      disabled
                      value={name}
                    />}
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group>
                  <Form.Label>E-mail</Form.Label>
                  {isLoading ? <Skeleton height={'40px'} width={'100%'} /> :
                    <Form.Control
                      type='text'
                      placeholder='Email Exemplo'
                      disabled
                      value={email}
                    />}

                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group>
                  <Form.Label>CPF</Form.Label>
                  {isLoading ? <Skeleton height={'40px'} width={'100%'} /> :
                    <Form.Control
                      type='text'
                      placeholder='CPF Exemplo'
                      disabled
                      value={cpf}

                    />}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Endereço</Form.Label>
                  {isLoading ? <Skeleton height={'40px'} width={'100%'} /> :
                    <Form.Control
                      type='text'
                      placeholder='Endereço Exemplo'
                      disabled
                      value={address}

                    />}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Telefone</Form.Label>
                  {isLoading ? <Skeleton height={'40px'} width={'100%'} /> :
                    <Form.Control
                      type='text'
                      placeholder='Telefone Exemplo'
                      disabled
                      value={phone}

                    />}
                </Form.Group>
              </Col>
            </Row>
          </div>
        </Card.Body>
      </Card>

    </section>
  );
};

export const ExamTable = ({ id, filter }) => {
  const [pagination, setPagination] = useState([]);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);

  const [refreshState, setRefreshState] = useState();


  useEffect(() => {
    getExamData(id);
  }, [id, page, refreshState, filter]);

  const getExamData = async (id) => {
    setLoading(true);

    if (id) {
      try {
        const { data, pagination, status } = await FindExamByCurrentCustomer(id, page, filter);
        if (status === "success") {
          setLoading(false);
          setPagination(pagination);

          if (data.length > 0) {
            setData(data);
          } else {
            setIsEmpty(true);
          }

        }
      } catch (err) {
        toast.error("Ocorreu um erro ao buscar os exames!");
      }
    };
  }


  const renderTable = () => {
    if (data) {
      return data.map((exam) => {
        return (
          <tr key={exam.id}>
            <td>
              <span className='fw-bold text-info'>#{exam.id.toString().padStart(5, 0)}</span>
            </td>
            <td>
              <span className='fw-normal'>{exam.pet.name}</span>
            </td>
            <td>
              <span className='fw-normal'>{exam.created_at}</span>
            </td>
            <td>
              <Dropdown as={ButtonGroup}>
                <Dropdown.Toggle as={Button} split variant='link' className='text-dark m-0 p-0'>
                  <span className='icon icon-sm'>
                    <FontAwesomeIcon icon={faEllipsisH} className='icon-dark' />
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item className='text-info'>
                    <ExamDetailModal id={exam.id} />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr>
        );
      });
    }
  };
  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm p-0">
      <Card.Body className="pt-0">
        <Table hover responsive className='align-items-center'>
          <thead>
            <tr>
              <th className='border-bottom'>ID</th>
              <th className='border-bottom'>Animal</th>
              <th className='border-bottom'>Data da Consulta</th>
              <th className='border-bottom'>Ações</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="100%" className="text-center">
                  <Spinner animation="border" role="status" />
                </td>
              </tr>
            ) : isEmpty ? (
              <tr>
                <td colSpan='100%' className='text-center'>
                  <span className='mt-2'>Não há registros cadastrados</span>
                </td>
              </tr>
            ) : (
              <>{renderTable()}</>
            )}
          </tbody>
        </Table>
      </Card.Body>
      <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
        <PaginationControl
          page={pagination.current_page}
          between={4}
          total={pagination.total}
          limit={pagination.per_page}
          changePage={(page) => {
            setPage(page);
          }}
          ellipsis={1}
        />
      </Card.Footer>
    </Card>
  );
};



