
import React from "react";
import moment from "moment-timezone";
import 'moment/locale/pt-br';
import { Row, Col, Card } from 'react-bootstrap';

export default (props) => {
  const currentYear = moment().get("year");

  return (
    <footer className="footer">
      <Row className="me-3">
        <Col xs={12} className="mb-4 mb-lg-0 ">
          <div className="justify-content-between">
            <p className="mb-0 text-start copyright">© {`${currentYear} `} C.E.R VET</p>
            <p className="mb-0 text-end">Feito com <span role="img" aria-label="gratitude">💙</span> por  <Card.Link href="https://www.linkedin.com/in/giovane-breno/" target="_blank" className="text-blue text-decoration-none fw-normal">
              Giovane Breno
            </Card.Link>
            </p>
          </div>

        </Col>
      </Row>
    </footer>
  );
};
