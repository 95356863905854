import React, { useState, useEffect } from 'react';
import { Route, Routes, Outlet } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

import { AppRoutes } from "../routes";

//petshop
import CreateCustomer from "./forms/CreateCustomer";
import ViewCustomer from "./tables/ViewCustomer";
import CreateAnimal from "./forms/CreateAnimal";
import ViewAnimal from "./tables/ViewAnimal";
import CreateExam from "./forms/CreateExam";
import ViewExam from "./tables/ViewExam";
import ViewService from './tables/ViewService';
import ViewPayment from './tables/ViewPayment';


import CustomerIndexPage from "./dashboard/customer/CustomerIndexPage";

// pages
import DashboardOverview from "./dashboard/DashboardOverview";
import Signin from "./auth/Signin";

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";

import PrivateOutlet from '../components/routes/private/private';
import ViewWorker from './tables/ViewWorker';
import ExamDashboard from './dashboard/ExamDashboard';
import NotFound from './auth/NotFound';
import CustomerDetailView from './dashboard/customer/CustomerDetailView';
import TicketView from './dashboard/customer/TicketView';
import CustomerSignIn from './auth/CustomerSignIn';
import PrivateCustomerOutlet from '../components/routes/private/customer';
import CreateService from './forms/CreateService';
import ViewCommission from './tables/ViewCommission';


const RouteWithLoader = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Preloader show={!loaded} />
      <ToastContainer />
      <Outlet />
    </>
  );
};

const RouteWithSidebar = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, [loaded]);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }
  return (<>
    <Sidebar />
    <Navbar />
    <ToastContainer />
    <main className="content">
      <Outlet />
    </main>
    <Footer toggleSettings={toggleSettings} showSettings={showSettings} />

  </>);
};


export default () => (
  <Routes>
    <Route path={AppRoutes.Signin.path} element={<Signin />} />
    <Route path={AppRoutes.CustomerSignin.path} element={<CustomerSignIn />} />
    <Route path={AppRoutes.ExamDashboard.path} element={<ExamDashboard />} />

    <Route element={<PrivateCustomerOutlet />}>
      <Route element={<RouteWithLoader />} >
        <Route path={AppRoutes.CustomerIndexPage.path} element={<CustomerIndexPage />} />
      </Route>
    </Route>

    <Route element={<PrivateOutlet />}>
      <Route exact path="/" element={<RouteWithLoader />} >
        <Route path={"*"} element={<NotFound />} />

        <Route path="/" element={<RouteWithSidebar />} >
          <Route path={AppRoutes.CreateCustomer.path} element={<CreateCustomer />} />
          <Route path={AppRoutes.CreateService.path} element={<CreateService />} />
          <Route path={AppRoutes.ViewCustomer.path} element={<ViewCustomer />} />
          <Route path={AppRoutes.CreateAnimal.path} element={<CreateAnimal />} />
          <Route path={AppRoutes.ViewAnimal.path} element={<ViewAnimal />} />
          <Route path={AppRoutes.CreateExam.path} element={<CreateExam />} />
          <Route path={AppRoutes.ViewExam.path} element={<ViewExam />} />
          <Route path={AppRoutes.ViewWorker.path} element={<ViewWorker />} />
          <Route path={AppRoutes.ViewService.path} element={<ViewService />} />
          <Route path={AppRoutes.CustomerDetailView.path} element={<CustomerDetailView />} />
          <Route path={AppRoutes.ViewPayment.path} element={<ViewPayment />} />
          <Route path={AppRoutes.TicketView.path} element={<TicketView />} />
          <Route path={AppRoutes.ViewCommission.path} element={<ViewCommission />} />

          <Route path={AppRoutes.DashboardOverview.path} element={<DashboardOverview />} />
          <Route path={AppRoutes.CreateExam.path} element={<CreateExam />} />


        </Route>
      </Route>

    </Route>
  </Routes>
);
