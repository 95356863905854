import { useParams } from "react-router-dom";
import React, { useEffect, useState, Component } from "react";
import {
    Row,
    Form,
    Modal,
    Button,
    Col,
    InputGroup,
    Accordion,
    Spinner,
    Table,
    Breadcrumb,
    Card,
    Nav,
    Tab,
    Dropdown,
    ButtonGroup,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Datetime from "react-datetime";
import {
    faCalendarAlt,
    faPen,
    faEye,
    faTrashAlt,
    faWarning,
    faLock,
    faLockOpen,
    faUndo,
    faRefresh,
    faDog,
    faList,
    faHome,
    faUser,
    faEllipsisH,
    faChevronDown,
    faReceipt,
    faChevronRight,
    faEllipsis,
    faPlus,
    faDollarSign,
    faPercent,
    faDollar,
} from "@fortawesome/free-solid-svg-icons";
import "moment/locale/pt-br";
import { toast } from "react-toastify";
import { IMaskInput } from "react-imask";
import moment from "moment-timezone";
import { FindCustomer } from "../../../services/CustomerService";
import Skeleton from "react-loading-skeleton";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { DeleteExam, FindExamByCustomer, ResetExamPassword } from "../../../services/ExamService";
import {
    AnimalDetailModal,
    CheckExamPasswordModal,
    CreateAnimalModal,
    CreateExamModal,
    CustomerDetailModal,
    DeleteAnimalModal,
    DeleteExamModal,
    ExamDetailModal,
    ResetExamPasswordModal,
} from "../../../components/Modals";
import { FetchAnimals, FindAnimal, FindAnimalByCustomer } from "../../../services/AnimalService";
import { PaginationControl } from "react-bootstrap-pagination-control";
import { CustomerAnimalTable, CustomerExamTable } from "../../../components/Tables";
import { Link } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";
import { FetchServices } from "../../../services/ServiceService";
import { useRef } from "react";
import { FetchUsers } from "../../../services/UserService";
import { Cell } from "recharts";
import { CreateTicket } from "../../../services/TicketService";
import { FetchPayments } from "../../../services/PaymentService";

export default () => {
    const params = useParams();


    const [refreshState, setRefreshState] = useState();
    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [name, setName] = useState("");

    useEffect(() => {
        fillForm();
    }, [refreshState]);

    let fillForm = async () => {
        setLoading(true);

        try {
            const { data, status } = await FindCustomer(params.customerId);
            if (status === "success") {
                setLoading(false);
                setData(data);
                setName(data.name);
            }
        } catch (err) {
            toast.error("Ocorreu um erro ao buscar o cliente!");
        }
    };

    return (
        <div className='justify-content-between flex-wrap flex-md-nowrap py-4'>
            <div className='mb-4 mb-lg-0'>
                <Breadcrumb className='d-none d-md-inline-block' listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                    <Breadcrumb.Item>
                        <FontAwesomeIcon icon={faHome} />
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>Tutores</Breadcrumb.Item>
                    <Breadcrumb.Item>Ticket</Breadcrumb.Item>

                    <Breadcrumb.Item active>
                        <Link to={{
                            pathname: `/customer/${params.customerId}`,
                        }}>
                            {name}
                        </Link></Breadcrumb.Item>
                </Breadcrumb>

                <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
                    <div className='d-block mb-4 mb-md-0'>
                        <h4>Registrar Ticket</h4>
                    </div>
                </div>
                <TicketHeader id={params.petId} owner={params.customerId} isLoading={isLoading} setLoading={setLoading} />
            </div>
        </div>
    );
};

export const TicketHeader = ({ id, owner, isLoading, refreshState }) => {
    const [name, setName] = useState();
    const [chip, setChip] = useState();
    const [gender, setGender] = useState();
    const [species, setSpecies] = useState();
    const [color, setColor] = useState();
    const [race, setRace] = useState();
    const [deceased, setDeceased] = useState();
    const [bornAt, setBornAt] = useState();
    const [age, setAge] = useState();
    const [totalTicket, setTotalTicket] = useState(0);
    const [createdAt, setCreated] = useState();


    const [serviceList, setServiceList] = useState([]);
    const [workerList, setWorkerList] = useState([]);


    const switchDeceased = (deceased) => { setDeceased(deceased => deceased === 0 ? "Sim" : "Não"); };

    const getAge = (age) => {
        var m = moment(age, "DD.MM.YYYY");
        var years = moment().diff(m, 'years', false);
        var months = moment().diff(m.add(years, 'years'), 'months', false);
        setAge(years + ' ano(s) e ' + months + ' mes(es)');
    };

    let fillForm = async () => {
        try {
            const { data, status } = await FindAnimal(id);
            if (status === "success") {
                setName(data.name);
                setChip(data.chip);
                setGender(data.gender);
                setSpecies(data.species.name);
                setColor(data.color.name);
                setRace(data.race.name);
                switchDeceased(data.deceased);
                setBornAt(data.born_at);
                getAge(data.born_at);
                setCreated(data.created_at);
            }
        } catch (err) {
            toast.error("Ocorreu um erro ao buscar o animal!");
        }
    };

    useEffect(() => {
        fillForm();
    }, [isLoading]);

    return (
        <>
            <section className='mt-3'>
                <Row >
                    <Col md={12} xl={6}>
                        <Card>
                            <Card.Header>
                                Detalhes do Animal
                            </Card.Header>
                            <Card.Body>
                                {isLoading ? (
                                    <div className="d-flex align-items-center justify-content-center" style={{ minHeight: '235px' }}>
                                        <Spinner animation='border' role='status' size="xl" />
                                    </div>
                                ) : (
                                    <div className="d-flex align-items-center">
                                        <div className="me-3 d-none d-xl-block">
                                            <FontAwesomeIcon size='10x' icon={faDog} color='black' border={true} />
                                        </div>
                                        <div>
                                            <span><b>Nome:</b>  {name}</span> <br />
                                            <span><b>Carteirinha do Animal:</b> {chip}</span><br />
                                            <span><b>Sexo:</b> {gender}</span><br />
                                            <span><b>Espécie:</b> {species}</span><br />
                                            <span><b>Pelagem:</b> {color}</span><br />
                                            <span><b>Raça:</b> {race}</span><br />
                                            <span><b>Óbito:</b> {deceased}</span><br />
                                            <span><b>Data de Nascimento:</b> {bornAt}</span><br />
                                            <span><b>Idade:</b> {age}</span><br />
                                            <span><b>Cadastrado Em:</b> {createdAt}</span><br />
                                        </div>
                                    </div>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={12} xl={6}>
                        <Card>
                            <Card.Header>
                                Buscar Serviço ou Produto
                            </Card.Header>
                            <Card.Body>
                                <ServiceForm setServiceList={setServiceList} serviceList={serviceList} setTotalTicket={setTotalTicket} totalTicket={totalTicket} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {serviceList.length > 0 &&
                    <Row className="mt-1 p-2">
                        <Card>
                            <Card.Body className="p-0">
                                <TicketTable setServiceList={setServiceList} serviceList={serviceList} setTotalTicket={setTotalTicket} totalTicket={totalTicket} />
                            </Card.Body>
                        </Card>
                    </Row>
                }
                {serviceList.length > 0 &&
                    <Row className="mt-1">
                        {/* <Col md={12} xl={6}>
                            <WorkerTable setServiceList={setServiceList} serviceList={serviceList} totalTicket={totalTicket} workerList={workerList} setWorkerList={setWorkerList} />
                        </Col> */}
                        <Col md={12} xl={12}>
                            <ResumeTable setTotalTicket={setTotalTicket} totalTicket={totalTicket} owner={owner} id={id} serviceList={serviceList} workerList={workerList} setWorkerList={setWorkerList} setServiceList={setServiceList} />
                        </Col>
                    </Row>
                }
            </section>

        </>
    );
};

export const ServiceForm = ({ setServiceList, serviceList, setTotalTicket, totalTicket }) => {
    const { data: services, isLoading: servicesLoading } = FetchServices();
    const [isDisabled, setDisabled] = useState(false);

    const [service, setService] = useState("");
    const [discount, setDiscount] = useState(0);
    const [discountType, setDiscountType] = useState(0);
    const [amount, setAmount] = useState(1);
    const [error, setError] = useState(1);

    const ref = useRef();

    const [counter, setCounter] = useState(0);

    const handleSelectedService = (id, service, discount, discountType, amount) => {
        if ((id) && (service) && (discount !== "") && (amount)) {
            let total = (amount * service.price);
            setCounter(counter + 1);
            

            let arr = {
                counter: counter,
                id: id,
                name: service.name,
                discount: discount,
                equivalentTo: discountType ? `${discount}` : `${(total * (discount / 100)).toFixed(2)}`,
                discountType: discountType,
                amount: amount,
                unit_price: service.price,
                unit: service.unit,
                total: discountType ? (total - discount).toFixed(2) : total - (total * (discount / 100)).toFixed(2)
            };

            setTotalTicket(totalTicket + arr.total);

            if (arr) {
                setServiceList([...serviceList, arr]);
                setService("");
                setDiscount("0");
                setAmount("1")

                ref.current.clear();
            }

        } else {
            toast.warning("Verifique todos os campos obrigatórios!");
        }

    }

    const handleDiscount = () => {
        // type 0 = %
        // type 1 = $

        if (discountType === 0) {
            setDiscountType(1);
        } else {
            setDiscountType(0);
        }
    };

    return (
        <>
            <Row className="justify-content-center">
                <Col md={12} className='mb-3'>
                    <Form.Group className='mb-2'>
                        <Form.Label>Serviço</Form.Label>
                        <Typeahead
                            ref={ref}
                            disabled={isDisabled}
                            id='services'
                            labelKey={(services) => `${services.name}`}
                            onChange={(value) => {
                                value[0] && setService(value[0])
                            }}
                            options={services}
                            isLoading={servicesLoading}
                            placeholder='Escolha o serviço a se adicionar'
                            className='is-invalid'
                            isInvalid={!!error.service}
                        />
                        <Form.Control.Feedback type='invalid'>{error.service}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col md={6} className='mb-3'>
                    <Form.Group className='mb-2'>
                        <Form.Label className='col-10 text-truncate'>Desconto</Form.Label>
                        <InputGroup>
                            <Form.Control
                                type='text'
                                value={discount}
                                onChange={(e) => setDiscount(e.target.value)}
                                placeholder={"0"} />
                            <InputGroup.Text className='btn btn-info border' onClick={() => handleDiscount()}><FontAwesomeIcon icon={discountType ? faDollarSign : faPercent} /></InputGroup.Text>

                        </InputGroup>
                    </Form.Group>
                </Col>
                <Col md={6} className='mb-3'>
                    <Form.Group className='mb-2'>
                        <Form.Label>Quantidade</Form.Label>
                        <Form.Control
                            type='number'
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            placeholder={"1"}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col md={6}>
                    <span onClick={() => handleSelectedService(service.id, service, discount, discountType, amount)} className='icon icon-sm btn btn-primary col-12'>
                        Inserir
                    </span>
                </Col>

            </Row>

        </>
    )
};


export const TicketTable = ({ setServiceList, serviceList, totalTicket, setTotalTicket }) => {
    let removeSelectedService = (id) => {
        setServiceList(serviceList.filter(service => service.counter !== id));
        setTotalTicket(totalTicket - serviceList[id].total);
    }

    const renderTable = () => {
        return serviceList.map((service) => {
            return (
                <tr key={service.counter}>
                    <td>
                        <span className='fw-normal'>{service.id}</span>
                    </td>
                    <td>
                        <span className='fw-normal'>{service.name}</span>
                    </td>
                    <td>
                        <span className='fw-normal'>{service.discountType === 0 ? `${service.discount}% (R$ ${service.equivalentTo})` : `R$ ${service.discount}`}</span>
                    </td>
                    <td>
                        <span className='fw-normal'>{service.unit == 0 ? `${service.amount} KG` : `${service.amount} UN`}</span>
                    </td>
                    <td>
                        <span className='fw-normal'>R$ {service.unit_price}</span>
                    </td>
                    <td>
                        <span className='fw-normal'>R$ {service.total.toFixed(2)}</span>
                    </td>
                    <td>
                        <span className='btn btn-danger' onClick={() => removeSelectedService(service.counter)}><FontAwesomeIcon icon={faTrashAlt} /></span>
                    </td>
                </tr>
            );
        });
    };

    return (
        <div style={{ height: '200px', overflowY: 'auto' }}>
            <Table hover responsive className='mt-2'>
                <thead>
                    <tr>
                        <th className='border-bottom'>ID</th>
                        <th className='border-bottom'>Serviço</th>
                        <th className='border-bottom'>Desconto</th>
                        <th className='border-bottom'>Quantidade</th>
                        <th className='border-bottom'>Preço Unit.</th>
                        <th className='border-bottom'>Total</th>
                        <th className='border-bottom'>Ação</th>
                    </tr>
                </thead>
                <tbody>
                    <>{renderTable()}</>
                </tbody>
            </Table>
        </div>
    );
};

export const WorkerTable = ({ isLoading, refreshState, totalTicket, workerList, setWorkerList }) => {
    let removeSelectedWorker = (id) => {
        setWorkerList(workerList.filter(worker => worker.id !== id));
    }

    const renderTable = () => {
        return workerList.map((worker) => {
            return (
                <tr key={worker.id}>
                    <td>
                        <span className='fw-normal'>{worker.id}</span>
                    </td>
                    <td>
                        <span className='fw-normal'>{worker.name}</span>
                    </td>
                    <td>
                        <span className='fw-normal'>{worker.comissionType === 0 ? `${worker.comission}% (R$ ${worker.equivalentTo})` : `R$ ${worker.comission}`}</span>
                    </td>
                    <td>
                        <span className='btn btn-danger' onClick={() => removeSelectedWorker(worker.id)}><FontAwesomeIcon icon={faTrashAlt} /></span>
                    </td>
                </tr>
            );
        });
    };

    return (
        <Card>
            <Card.Header className="py-2">
                <div className={"justify-content-between align-items-center row"}>
                    <Col xl={6}>
                        Colaboradores
                    </Col>
                    <Col xl={6} className='text-right'>
                        <WorkerModal setWorkerList={setWorkerList} workerList={workerList} totalTicket={totalTicket} />
                    </Col>
                </div>
            </Card.Header>
            <Card.Body className="p-0">
                <Table hover>
                    <thead>
                        <tr>
                            <th className='border-bottom'>ID</th>
                            <th className='border-bottom'>Colaborador</th>
                            <th className='border-bottom'>Comissão</th>
                            <th className='border-bottom'>Ação</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? (
                            <tr>
                                <td colSpan='100%' className='text-center'>
                                    <Spinner animation='border' role='status' />
                                </td>
                            </tr>
                        ) : (
                            <>{renderTable()}</>
                        )}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    );

};

export const WorkerModal = ({ setWorkerList, workerList, totalTicket, counter }) => {
    const [showDefault, setShowDefault] = useState(false);
    const handleClose = () => {
        setShowDefault(false);
    };

    const { data: users, isLoading: usersLoading } = FetchUsers();

    const [isDisabled, setDisabled] = useState(false);
    const [worker, setWorker] = useState("");
    const [comission, setComission] = useState("");
    const [comissionType, setComissionType] = useState(0);

    const ref = useRef();
    const addWorker = (id, worker, comission, comissionType) => {

        if ((worker) && (comission) || (worker) && isDisabled) {
            if (!workerList.some(worker => worker.id === id)) {
                let arr = {
                    id: id,
                    name: worker.username,
                    comission: comission,
                    equivalentTo: comissionType ? `${comission}` : `${(totalTicket * (comission / 100)).toFixed(2)}`,
                    comissionType: comissionType,
                };
                setWorkerList([...workerList, arr]);
                setWorker("");
                setComission("");
                ref.current.clear();
                setShowDefault(false);
                if (workerList.length === 0) {

                    if (comissionType === 0) {
                        setComission(100 - comission);
                    } else {
                        setComission(comission - totalTicket);
                    }

                }


            } else {
                toast.error("Você já atribuiu esse colaborador ao chamado!");
                setShowDefault(false);
            }


        } else {
            toast.warning("Verifique todos os campos obrigatórios!");
        }
    }

    const handleComission = () => {
        setComission("0");
        // type 0 = %
        // type 1 = $

        if (comissionType === 0) {
            setComissionType(1);
        } else {
            setComissionType(0);
        }
    };

    return (
        <>
            {workerList.length < 2 ?
                (
                    <span className='btn btn-sm btn-success' onClick={() => setShowDefault(true)}>
                        <FontAwesomeIcon icon={faPlus} />
                    </span >

                ) : (
                    <span className='btn btn-sm btn-success' onClick={() => toast.error("Você só pode atribuir no máximo 02 colaboradores!")}>
                        <FontAwesomeIcon icon={faPlus} />
                    </span>
                )
            }

            <Modal size={"lg"} as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title className='h6'>Adicionar Colaborador</Modal.Title>
                    <Button variant='close' aria-label='Close' onClick={handleClose} />
                </Modal.Header>
                <Modal.Body>
                    <Row className="justify-content-center">

                        <Col md={6} className='mb-3'>
                            <Form.Group className='mb-2'>
                                <Form.Label>Colaborador</Form.Label>
                                <Typeahead
                                    ref={ref}
                                    id='users'
                                    labelKey={(users) => `${users.username}`}
                                    onChange={(value) => {
                                        value[0] && setWorker(value[0])
                                    }}
                                    options={users}
                                    isLoading={usersLoading}
                                    placeholder='Escolha o colaborador a se adicionar'
                                    className='is-invalid'
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={3} md={6} className='mb-3'>

                            <Form.Group className='mb-2'>
                                <Form.Label>Comissão</Form.Label>
                                <InputGroup>
                                    <Form.Control type='number'
                                        disabled={isDisabled}
                                        value={comission}
                                        onChange={(e) => setComission(e.target.value)}
                                        placeholder={"0"} />
                                    <InputGroup.Text className='btn btn-info border' onClick={() => handleComission()}><FontAwesomeIcon icon={comissionType ? faDollarSign : faPercent} /></InputGroup.Text>

                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col xl={1}>
                            <Form.Group className='mb-2'>
                                <Form.Label>  </Form.Label>
                                <span onClick={() => addWorker(worker.id, worker, comission, comissionType)} className='icon icon-sm btn btn-primary col-12'>
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </span>
                            </Form.Group>

                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
}

export const ResumeTable = ({ id, owner, workerList, serviceList, isLoading, totalTicket, setTotalTicket, setWorkerList, setServiceList }) => {
    const { data: payments, isLoading: paymentsLoading } = FetchPayments();
    const [payment, setPayment] = useState("");
    const [keepOpen, setKeepOpen] = useState(1);
    const [error, setError] = useState();

    const handleCheck = () => { setKeepOpen(keepOpen => keepOpen === 0 ? totalTicket : 0); };


    let handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const { status } = await CreateTicket(owner, id, payment, keepOpen, serviceList, totalTicket, workerList);

            if (status === "success") {
                toast.success("Ticket cadastrado com sucesso!");
                setWorkerList([]);
                setServiceList([]);
                setTotalTicket(0);
            }
        } catch (err) {
            if (workerList.length === 0) {
                toast.warn("É necessário vincular ao menos 01 colaborador!");
            } else {
                toast.error("Ocorreu um erro ao cadastrar o serviço!");
            }
        }
    };


    return (
        <Card>
            <Card.Body className="p-0">
                <Form onSubmit={handleSubmit}>
                    <Table hover>
                        <thead>
                            <tr>
                                <th className='border-bottom'>Valor Total (R$)</th>
                                <th className='border-bottom'>Mét. Pagamento</th>
                                <th className='border-bottom'>Data do Ticket</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading ? (
                                <tr>
                                    <td colSpan='100%' className='text-center'>
                                        <Spinner animation='border' role='status' />
                                    </td>
                                </tr>
                            ) : (
                                <>
                                    <tr>
                                        <td>
                                            <Form.Control type='text'
                                                disabled="true"
                                                value={`R$ ${totalTicket.toFixed(2)}`} />
                                        </td>
                                        <td>
                                            <Col md={12} className='mb-3'>
                                                <Typeahead
                                                    id='payments'
                                                    labelKey={(payments) => `${payments.name}`}
                                                    onChange={(value) => {
                                                        value[0] && setPayment(value[0].id)
                                                    }}
                                                    options={payments}
                                                    isLoading={paymentsLoading}
                                                    placeholder='Escolha o Mét. Pagamento a se adicionar'
                                                    className='is-invalid'
                                                    // isInvalid={!!error.payment_method}
                                                />
                                            </Col>
                                        </td>
                                        <td>
                                            <Form.Control type='text'
                                                disabled={true}
                                                value={moment().format("DD/MM/YYYY")} />
                                        </td>


                                    </tr>
                                    <tr>
                                        <td>
                                            <Form.Check label="Manter Ticket em Aberto" onChange={handleCheck}/>
                                        </td>
                                        <td colSpan={3}><Button variant='success' type='submit'>
                                            Registrar Ticket
                                        </Button></td>
                                    </tr>
                                </>
                            )}
                        </tbody>
                    </Table>
                </Form>
            </Card.Body>
        </Card >
    );

};
