import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { Col, Row } from 'react-bootstrap';
import { CustomerForm } from "../../components/Forms";


export default () => {
  return (
    <div className="justify-content-between flex-wrap flex-md-nowrap py-4">
      <div className="mb-4 mb-lg-0">
        <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
          <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
          <Breadcrumb.Item>Tutores</Breadcrumb.Item>
          <Breadcrumb.Item active>Cadastrar</Breadcrumb.Item>
        </Breadcrumb>

        <Row className="d-flex flex-wrap flex-md-nowrap align-items-center py-4">
          <Col className="d-block mb-4 mb-md-0">
            <h1 className="h2">Cadastro de Tutores</h1>
            <p className="mb-0">
              Use o formulário abaixo para cadastrar novos tutores.
            </p>
          </Col>
        </Row>
        <CustomerForm />

      </div>
    </div>
  );
};
