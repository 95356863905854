import React, { useEffect, useState } from "react";
import { Row, Col, Form, Card, Container, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { ExamDetailModal } from "../../components/Modals";
import { FindExamByPassword } from "../../services/ExamService";

export default () => {
  useEffect(() => {
    document.body.style.backgroundColor = "#f5b759";
  }, []);

  const [password, setPassword] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [id, setId] = useState("");
  let checkPassword = async () => {
    try {
      const { data, status } = await FindExamByPassword(password);
      if (status === "success") {
        setPassword("");
        setId(data.id);
        setIsSuccess(!isSuccess);
        toast.info("Carregando Exame...");
      }
    } catch (err) {
      toast.error("Exame não encontrado!");
    }
  };
  return (
    <Container className='mt-10 col-5'>
      <Card className='justify-content-center'>
        <Card.Header>Acessar Exames</Card.Header>
        <Card.Body>
          <Row className='justify-content-center d-flex flex-wrap flex-md-nowrap align-items-center py-4'>
            <Col className='d-block mb-4 mb-md-0 text-center'>
              <Row className='justify-content-center text-center'>
                <FontAwesomeIcon icon={faLock} className='me-2 fa-6x text-danger col-12' />
                <span className='h4 mb-3 mt-4 col-12'>Senha necessária!</span>
              </Row>

              <Row className='justify-content-center text-center'>
                <p className='mb-0'>Digite a senha para acessar os dados do exame.</p>
              </Row>
              <Row>
                <Col sm={12} xl={12} className='mt-3'>
                  <Form.Group>
                    <Form.Control
                      type='password'
                      placeholder='Insira a senha do Exame'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          <Button size={"md"} onClick={checkPassword} className='btn btn-info float-end'>
            Acessar
          </Button>
        </Card.Footer>
      </Card>

      {isSuccess && <ExamDetailModal id={id} setIsSuccess={setIsSuccess} isSuccess={isSuccess} />}
    </Container>
  );
};
