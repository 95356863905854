import { faCoins, faEdit, faEllipsisH, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonGroup, Card, Col, Dropdown, Row } from "react-bootstrap";
import { DeletePaymentModal, PaymentDetailModal } from "./Modals";

export const PaymentWidget = ({ id, icon, iconColor, name, tax, timesUsed, setRefreshState, refreshState }) => {
  
  return (
    <Card border="light" className="shadow-sm">
      <Card.Body>
        <Row className="d-block d-xl-flex align-items-center">
          <Col xl={4} className="text-xl-center d-flex align-items-center justify-content-xl-center mb-3 mb-xl-0">
            <div className={'rounded me-sm-0'} style={{ color: iconColor, backgroundColor: '#f0f0f5' }}>
              <FontAwesomeIcon className="fa-5x" icon={icon} />
            </div>
          </Col>
          <Col xs={12} xl={6} className="px-xl-0">
            <div className="d-none d-sm-block">
              <h3 className="mb-1 text-truncate">{name}</h3>
            </div>
            <div className="d-none d-sm-block">
              <h5 className="mb-1">{tax}%</h5>
            </div>
            <small><FontAwesomeIcon icon={faCoins} size="xs" /> {timesUsed} Vezes Utilizada</small>
          </Col>
          <Col xl={2} className="text-xl-center d-flex align-items-center justify-content-xl-center mb-3 mb-xl-0">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle as={Button} split variant='link' className='text-dark m-0 p-0'>
                <span className='icon icon-sm'>
                  <FontAwesomeIcon icon={faEllipsisH} className='icon-dark' />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item className='text-info'>
                  <PaymentDetailModal id={id} setRefreshState={setRefreshState} refreshState={refreshState} />
                </Dropdown.Item>

                <Dropdown.Item className='text-danger'>
                  <DeletePaymentModal id={id} setRefreshState={setRefreshState} refreshState={refreshState} />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};