import axios from "axios";
import { useEffect, useState } from "react";
import api from "../api";

export const FindActiveTicket = (page, filter, refreshState) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    api
      .get(`ticket?page=${page}`)
      .then((response) => {
        response.data.length === 0 ? setIsEmpty(true) : setIsEmpty(false);
        setData(response.data.data.info);
        setPagination(response.data.data.pagination);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsEmpty(true);
        setIsLoading(false);
      });
  }, [page, filter, refreshState]);

  return { data, pagination, isLoading, isEmpty };
};

export const FindTicketByCustomer = async (customer, page, filter, refreshState) => {
  return await api.get(`/ticket/c/${customer}?page=${page}`).then(function (response) {
    return { data: response.data.data.info, pagination: response.data.data.pagination, status: response.data.status };
  });
};

export const CreateTicket = async (owner_id, animal_id, payment_method, paid_amount, services, total, users) => {
  return await api.post("/ticket", {
    owner_id: owner_id,
    animal_id: animal_id,
    payment_method: payment_method,
    paid_amount: paid_amount,
    services: services,
    total: total,
    users: users,
  })
    .then(function (response) {
      return { data: response.data.data, status: response.data.status };
    });
};

export const UpdateTicket = async (id, payment_method, paid_amount) => {
  return await api
    .put(`/ticket/${id}`, {
      payment_method: payment_method,
      paid_amount: paid_amount,
    }).then(function (response) {
      return { data: response.data.data, status: response.data.status };
    });
};

export const FindTicket = async (id) => {
  return await api.get(`/ticket/${id}`).then(function (response) {
    return { data: response.data.data, status: response.data.status };
  });
};

export const DeleteTicket = async (id) => {
  return await api.delete(`/ticket/${id}`).then(function (response) {
    return { data: response.data, status: response.data.status };
  });
};