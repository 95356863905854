import { useEffect, useState } from "react";
import api from "../api";

export const FindActiveAnimal = (page, filter, refreshState) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    api
      .get(`animal?page=${page}&name=${filter.search ?? ''}`)
      .then((response) => {
        response.data.length === 0 ? setIsEmpty(true) : setIsEmpty(false);
        setData(response.data.data.info);
        setPagination(response.data.data.pagination);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsEmpty(true);
        setIsLoading(false);
      });
  }, [page, filter, refreshState]);

  return { data, pagination, isLoading, isEmpty };
};

export const FetchAnimals = (id = 0) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState();
  const [data, setData] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    api
      .get(`list/animals/${id}`)
      .then((response) => {
        if (!response.data.status === "error") setIsEmpty(true);
        response.data.data.length === 0 ? setIsEmpty(true) : setIsEmpty(false);

        setData(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsEmpty(true);
        setIsLoading(false);
      });
  }, []);
  return { data, isLoading, isEmpty };
};

export const CreateAnimal = async (ownerId, name, gender, specie, color, race, deceased, birthAt, tag, chip, castrated,) => {
  return await api.post("/animal", {
    owner_id: ownerId,
    name: name,
    gender: gender,
    species_id: specie,
    color_id: color,
    race_id: race,
    deceased: deceased,
    born_at: birthAt,
    tag: tag,
    chip: chip,
    castrated: castrated
  })
    .then(function (response) {
      return { data: response.data.data, status: response.data.status };
    });
};

export const UpdateAnimal = async (id, owner_id, name, gender, species_id, color_id, race_id, deceased, born_at, tag, castrated, chip) => {
  return await api
    .put(`/animal/${id}`, {
      owner_id: owner_id,
      name: name,
      gender: gender,
      species_id: species_id,
      color_id: color_id,
      race_id: race_id,
      deceased: deceased,
      born_at: born_at,
      tag: tag,
      chip: chip,
      castrated: castrated
    }).then(function (response) {
      return { data: response.data.data, status: response.data.status };
    });

};

export const FindAnimal = async (id) => {
  return await api.get(`/animal/${id}`).then(function (response) {
    return { data: response.data.data, status: response.data.status };
  });
};

export const FindAnimalByCustomer = async (id, page) => {
  return await api.get(`/animal/a/${id}?page=${page}`).then(function (response) {
    return { data: response.data.data.info, pagination: response.data.data.pagination, status: response.data.status };
  });
};

export const DeleteAnimal = async (id) => {
  return await api.delete(`/animal/${id}`).then(function (response) {
    return { data: response.data, status: response.data.status };
  });
};

export const FetchRaces = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState();
  const [data, setData] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    api
      .get(`list/races`)
      .then((response) => {
        if (!response.data.status === "error") setIsEmpty(true);
        response.data.data.length === 0 ? setIsEmpty(true) : setIsEmpty(false);

        setData(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsEmpty(true);
        setIsLoading(false);
      });
  }, []);
  return { data, isLoading, isEmpty };
};

export const FetchColors = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState();
  const [data, setData] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    api
      .get(`list/colors`)
      .then((response) => {
        if (!response.data.status === "error") setIsEmpty(true);
        response.data.data.length === 0 ? setIsEmpty(true) : setIsEmpty(false);

        setData(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsEmpty(true);
        setIsLoading(false);
      });
  }, []);
  return { data, isLoading, isEmpty };
};

export const FetchSpecies = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState();
  const [data, setData] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    api
      .get(`list/species`)
      .then((response) => {
        if (!response.data.status === "error") setIsEmpty(true);
        response.data.data.length === 0 ? setIsEmpty(true) : setIsEmpty(false);

        setData(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsEmpty(true);
        setIsLoading(false);
      });
  }, []);
  return { data, isLoading, isEmpty };
};