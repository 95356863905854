import { useEffect, useState } from "react";
import api from "../api";

export const FindActiveCommission = (page, filter, refreshState) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState([]);
    useEffect(() => {
        setIsLoading(true);
        api
            .get(`finance/commission?page=${page}&user=${filter.worker ?? ''}`)
            .then((response) => {
                response.data.length === 0 ? setIsEmpty(true) : setIsEmpty(false);
                setData(response.data.data.info);
                setPagination(response.data.data.pagination);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsEmpty(true);
                setIsLoading(false);
            });
    }, [page, refreshState, filter]);

    return { data, pagination, isLoading, isEmpty };
};

export const FindCommission = async (id) => {
    return await api.get(`finance/commission/${id}`).then(function (response) {
        return { data: response.data.data, status: response.data.status };
    });
};