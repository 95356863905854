
import React, { useState } from "react";
import SimpleBar from 'simplebar-react';
import { useLocation } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEye, faDog, faPerson, faFileArchive, faFileCirclePlus, faCreditCard, faGear, faGauge, faMoneyBill, faArrowRightArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Nav, Badge, Image, Button, Dropdown, Accordion, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { AppRoutes } from "../routes";
import ReactHero from "../assets/img/technologies/cdr-logo-primary.png";
import { GetCurrentUser } from "../services/UserService";

export default (props = {}) => {
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";
  const [abilities, setAbilities] = useState("")

  const onCollapse = () => setShow(!show);
  const { data, isLoading } = GetCurrentUser();

  const CollapsableNavItem = (props) => {
    const { eventKey, title, icon, children = null } = props;
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";

    return (

      <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button as={Nav.Link} className="d-flex justify-content-between align-items-center">
            <span>
              <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span>
              <span className="sidebar-text">{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Nav className="flex-column">
              {children}
            </Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const NavItem = (props) => {
    const { title, link, external, target, icon, image, badgeText, badgeBg = "secondary", badgeColor = "primary" } = props;
    const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between" : "";
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span> : null}
            {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}

            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? (
            <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <Navbar expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-md-none">
        <Navbar.Brand className="me-lg-5" as={Link} to={AppRoutes.DashboardOverview.path}>
          <Image src={ReactHero} className="navbar-brand-light" />
        </Navbar.Brand>
        <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}>
          <div className="sidebar-inner px-3 pt-3">

            <Nav className="flex-column pt-3 pt-md-0">
              <Navbar.Brand className="ms-3 me-lg-5 mr-5 py-2" as={Link} to={AppRoutes.DashboardOverview.path}>
                <Image src={ReactHero} className="navbar-brand-light" />C.E.R VET
              </Navbar.Brand>
              <Dropdown.Divider className="border-indigo" />
              {/* <Button as={Link} to={AppRoutes.CreateExam.path} variant="warning" className="flying-button"><FontAwesomeIcon icon={faFileCirclePlus} className="me-1" /> Cadastrar Exame</Button> */}
              {(data.role_id === 2 || data.role_id === 3 || data.role_id === 4) &&
                <>
                  <Dropdown.Header>AMBIENTE CLÍNICO</Dropdown.Header>
                  <CollapsableNavItem title="Tutores" icon={faPerson}>
                    <NavItem title="Cadastrar" link={AppRoutes.CreateCustomer.path} icon={faPlus} />
                    <NavItem title="Consultar" link={AppRoutes.ViewCustomer.path} icon={faEye} />
                  </CollapsableNavItem>
                </>
              }
              {/* <CollapsableNavItem title="Animais" icon={faDog}>
                <NavItem title="Cadastrar" link={AppRoutes.CreateAnimal.path} icon={faPlus} />
                <NavItem title="Consultar" link={AppRoutes.ViewAnimal.path} icon={faEye} />
              </CollapsableNavItem> */}
              {(data.role_id === 3 || data.role_id === 2) &&
                <>
                  <NavItem title="Exames" link={AppRoutes.ViewExam.path} icon={faFileArchive} />
                </>
              }
              {(data.role_id === 2 || data.role_id === 3) &&
                <>
                  <Dropdown.Header>RECURSOS HUMANOS</Dropdown.Header>
                  <CollapsableNavItem title="Serviços" icon={faGauge}>
                    <NavItem title="Cadastrar" link={AppRoutes.CreateService.path} icon={faPlus} />
                    <NavItem title="Consultar" link={AppRoutes.ViewService.path} icon={faEye} />
                  </CollapsableNavItem>
                </>
              }
              {(data.role_id === 2) &&
                <>
                  <NavItem title="Funcionários" link={AppRoutes.ViewWorker.path} icon={faPerson} />
                </>
              }
              {(data.role_id === 2) &&
                <>
                  <Dropdown.Header>ADMINISTRATIVO</Dropdown.Header>
                  <CollapsableNavItem title="Configurações" icon={faGear}>
                    <NavItem title="Mét. Pagamentos" link={AppRoutes.ViewPayment.path} icon={faCreditCard} />
                  </CollapsableNavItem>
                </>
              }
              {data.role_id === 2 &&
                <>
                  <Dropdown.Header>FINANCEIRO</Dropdown.Header>
                  <NavItem title="Repasses" link={AppRoutes.ViewCommission.path} icon={faArrowRightArrowLeft} />
                </>
              }
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition >
    </>
  );
};

