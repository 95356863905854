import React, { useRef, useState } from "react";
import moment from "moment-timezone";
import "moment/locale/pt-br";
import Datetime from "react-datetime";
import { IMaskInput } from "react-imask";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faQuestionCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Card, Form, Button, InputGroup, OverlayTrigger, Tooltip, Spinner, Table } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { toast } from "react-toastify";
import { CreateCustomer, FetchCustomers, FindCustomer, GetAdressfromAPI } from "../services/CustomerService";
import { CreateAnimal } from "../services/AnimalService";
import { useEffect } from "react";
import { CreateExam } from "../services/ExamService";
import { CreateService } from "../services/ServiceService";
import { WorkerModal } from "../pages/dashboard/customer/TicketView";
import { CreateAnimalModal } from "./Modals";

export const CustomerForm = () => {
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => {
    setShowDefault(false);
  };

  let openModal = async () => {
    setShowDefault(true);
  };

  const [isMounted, setIsMounted] = useState(false);

  const [name, setName] = useState("");
  const [cpf, setCPF] = useState("");
  const [rg, setRG] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [cep, setCEP] = useState("");
  const [street, setStreet] = useState("");
  const [district, setDistrict] = useState("");
  const [city, setCity] = useState("");
  const [knowledge, setKnowledge] = useState("");
  const [birthAt, setBirthAt] = useState("");
  const [isCEPEmpty, setIsCEPEmpty] = useState(true);
  const [error, setError] = useState({});

  const [id, setId] = useState();
  
  

  const ref = useRef();

  const knowledges = [
    { place: "Indicação" },
    { place: "Frente Loja" },
    { place: "Instagram" },
    { place: "Facebook" },
    { place: "Google" },
    { place: "Youtube" },
    { place: "Panfletos" },
    { place: "Email" },
    { place: "Site" },
    { place: "Outros" },
    { place: "Banners (Online)" },
    { place: "Banners (Offline)" }
  ];


  /**
   * USE EFFECT PARA BUSCAR O CEP NA API APÓS 2 SEGUNDOS SEM DIGITAÇÃO.
   * TAMBÉM EVITA QUE SEJA ATIVADO NO MOMENTO EM QUE É MONTADO O SITE
   */
  useEffect(() => {
    if (isMounted) {
      const delayDebounceFn = setTimeout(() => {
        if (cep) {
          fetchCEP();
        }
      }, 2000);
      return () => clearTimeout(delayDebounceFn);
    } else {
      setIsMounted(true);
    }
  }, [cep]);

  let fetchCEP = async (e) => {
    try {
      const { data } = await GetAdressfromAPI(cep);
      setStreet(data.address);
      setDistrict(data.district);
      setCity(data.city + " / " + data.state);
      setIsCEPEmpty(false);
      toast.success("O CEP foi encontrado e adicionado ao formulário!");
    } catch (err) {
      toast.error("O CEP não foi encontrado ou é inexistente!" + err);
    }
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data, status } = await CreateCustomer(name, cpf, rg, email, phone, cep, street, district, city, birthAt, knowledge);
      if (status === "success") {
        toast.success("Tutor cadastrado com sucesso!");

        setName("");
        setCPF("");
        setRG("");
        setEmail("");
        setPhone("");
        setCEP("");
        setStreet("");
        setDistrict("");
        setCity("");
        setBirthAt("");
        setKnowledge("");
        setError("");
        setIsCEPEmpty(true);

        setId(data.id);
        ref.current.clear();

        openModal();
      }
    } catch (err) {
      const errors = err.response.data;
      if (errors) {
        toast.warn("Verifique os campos obrigatórios!");
        setError(errors.data);
      } else {
        toast.error("Ocorreu um erro ao cadastrar o tutor!");
      }
    }
  };

  return (
    <Card border='light' className='bg-white shadow-sm mb-4'>
      <Card.Body>
        <h5 className='mb-4'>Dados do Tutor</h5>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  type='text'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder={"Insira o nome do Tutor"}
                  isInvalid={!!error.name}
                />
                <Form.Control.Feedback type='invalid'>{error.name}</Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={6} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  type='text'
                  value={email}
                  placeholder={"email@exemplo.com"}
                  onChange={(e) => setEmail(e.target.value)}
                  isInvalid={!!error.email}
                />
                <Form.Control.Feedback type='invalid'>{error.email}</Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={2} md={6} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>CPF</Form.Label>
                <Form.Control
                  type='text'
                  value={cpf}
                  as={IMaskInput}
                  mask='000.000.000-00'
                  placeholder={"___.___.___-__"}
                  onChange={(e) => setCPF(e.target.value)}
                  isInvalid={!!error.cpf}
                />
                <Form.Control.Feedback type='invalid'>{error.cpf}</Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={2} md={6} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>RG</Form.Label>
                <Form.Control
                  type='text'
                  value={rg}
                  as={IMaskInput}
                  mask='00.000.000-0'
                  placeholder={"__.___.___-_"}
                  onChange={(e) => setRG(e.target.value)}
                  isInvalid={!!error.rg}
                />
                <Form.Control.Feedback type='invalid'>{error.rg}</Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={2} md={6} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label className='col-10 text-truncate'>Data de Nascimento</Form.Label>
                <Datetime
                  timeFormat={false}
                  closeOnSelect={true}
                  onChange={setBirthAt}
                  renderInput={(props, openCalendar) => (
                    <InputGroup hasValidation>
                      <InputGroup.Text className={!!error.birth_at && "is-invalid"}>
                        <FontAwesomeIcon icon={faCalendarAlt} />
                      </InputGroup.Text>
                      <Form.Control
                        isInvalid={!!error.birthAt}
                        type='text'
                        value={birthAt ? moment(birthAt).format("DD/MM/YYYY") : ""}
                        placeholder='dd/mm/yyyy'
                        onFocus={openCalendar}
                        onChange={() => { }}
                      />
                      <Form.Control.Feedback type='invalid'>{error.birth_at}</Form.Control.Feedback>
                    </InputGroup>
                  )}
                />
              </Form.Group>
            </Col>
            <Col lg={6} md={6} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>Telefone</Form.Label>
                <Form.Control
                  type='text'
                  value={phone}
                  as={IMaskInput}
                  mask='(00) 00000-0000'
                  placeholder={"(00) 00000-0000"}
                  onChange={(e) => setPhone(e.target.value)}
                  isInvalid={!!error.phone}
                />
                <Form.Control.Feedback type='invalid'>{error.phone}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={6} md={6} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>Como nos Conheceu?</Form.Label>
                <Typeahead
                  ref={ref}
                  id='customer'
                  labelKey={(knowledges) => `${knowledges.place}`}
                  onChange={(value) => value[0] && setKnowledge(value[0].place)}
                  options={knowledges}
                  placeholder='Escolha o local de conhecimento'
                  className='is-invalid'
                  isInvalid={!!error.knowledge}
                />
                <Form.Control.Feedback type='invalid'>{error.knowledge}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <h5 className='my-4'>Dados de Endereço</h5>
          <Row>
            <Col lg={3} md={8} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>CEP</Form.Label>
                <Form.Control
                  type='text'
                  value={cep}
                  as={IMaskInput}
                  mask='00000000'
                  placeholder={"_______"}
                  onChange={(e) => setCEP(e.target.value)}
                  isInvalid={!!error.cep}
                />
                <Form.Control.Feedback type='invalid'>{error.cep}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={{ span: 3 }} md={4} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>Rua</Form.Label>
                <Form.Control
                  disabled={isCEPEmpty}
                  type='text'
                  value={street}
                  onChange={(e) => setStreet(e.target.value)}
                  isInvalid={!!error.street}
                />
                <Form.Control.Feedback type='invalid'>{error.street}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={3} md={4} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>Bairro</Form.Label>
                <Form.Control
                  disabled={isCEPEmpty}
                  type='text'
                  value={district}
                  onChange={(e) => setDistrict(e.target.value)}
                  isInvalid={!!error.district}
                />
                <Form.Control.Feedback type='invalid'>{error.district}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={3} md={4} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>Cidade / Estado</Form.Label>
                <Form.Control
                  disabled={isCEPEmpty}
                  type='text'
                  placeholder={""}
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  isInvalid={!!error.city}
                />
                <Form.Control.Feedback type='invalid'>{error.city}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <div className='d-flex mt-3 justify-content-end'>
            <Button variant='primary' type='submit'>
              Cadastrar Dados
            </Button>
            <CreateAnimalModal id={id} simplified={true}/>

          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export const ServiceForm = () => {
  const [isMounted, setIsMounted] = useState(false);

  const [name, setName] = useState("");
  const [storage, setStorage] = useState(1);
  const [price, setPrice] = useState("");
  const [buyCost, setBuyCost] = useState("");
  const [unit, setUnit] = useState("");
  const [type, setType] = useState(0);
  const [error, setError] = useState({});

  const [serviceList, setServiceList] = useState([]);
  const [workerList, setWorkerList] = useState([]);

  const ref = useRef();
  const ref2 = useRef();

  const units = [
    { id: 0, type: "KG" },
    { id: 1, type: "Unitário" }
  ];

  const types = [
    { id: 0, type: "Serviço" },
    { id: 1, type: "Produto" }
  ];

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { status } = await CreateService(name, storage, unit, type, price, buyCost, workerList);
      if (status === "success") {
        toast.success("Serviço cadastrado com sucesso!");

        setName("");
        setStorage(1);
        setPrice("");
        setBuyCost("");
        setUnit("");
        setWorkerList([]);
        setError("");
        ref.current.clear();

      }
    } catch (err) {
      const errors = err.response.data;
      if (errors) {
        toast.warn("Verifique os campos obrigatórios!");
        setError(errors.data);
      } else {
        toast.error("Ocorreu um erro ao cadastrar o serviço!");
      }
    }
  };

  let removeSelectedWorker = (id) => {
    setWorkerList(workerList.filter(worker => worker.id !== id));
  }

  const renderTable = () => {
    return workerList.map((worker) => {
      return (
        <tr key={worker.id}>
          <td>
            <span className='fw-normal'>{worker.id}</span>
          </td>
          <td>
            <span className='fw-normal'>{worker.name}</span>
          </td>
          <td>
            <span className='fw-normal'>{worker.comissionType === 0 ? `${worker.comission}% (R$ ${worker.equivalentTo})` : `R$ ${worker.comission}`}</span>
          </td>
          <td>
            <span className='btn btn-danger' onClick={() => removeSelectedWorker(worker.id)}><FontAwesomeIcon icon={faTrashAlt} /></span>
          </td>
        </tr>
      );
    });
  };

  return (
    <Card border='light' className='bg-white shadow-sm mb-4'>
      <Card.Body>
        <h5 className='mb-4'>Dados do Serviço</h5>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  type='text'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder={"Insira o nome do Serviço"}
                  isInvalid={!!error.name}
                />
                <Form.Control.Feedback type='invalid'>{error.name}</Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={6} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>Tipo de Serviço</Form.Label>
                <Typeahead
                  ref={ref2}
                  id='service'
                  labelKey={(types) => `${types.type}`}
                  onChange={(value) => value[0] && setType(value[0].id)}
                  options={types}
                  placeholder='Escolha o tipo de serviço'
                  className='is-invalid'
                  isInvalid={!!error.unit}
                />
                <Form.Control.Feedback type='invalid'>{error.storage}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            {type === 1 &&
              <>
                <Col md={3} className='mb-3'>
                  <Form.Group className='mb-2'>
                    <Form.Label>Estoque</Form.Label>
                    <Form.Control
                      type='number'
                      value={storage}
                      placeholder={"Estoque"}
                      onChange={(e) => setStorage(e.target.value)}
                      isInvalid={!!error.storage}
                    />
                    <Form.Control.Feedback type='invalid'>{error.storage}</Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={3} className='mb-3'>
                  <Form.Group className='mb-2'>
                    <Form.Label>Unidade</Form.Label>
                    <Typeahead
                      ref={ref}
                      id='customer'
                      labelKey={(units) => `${units.type}`}
                      onChange={(value) => value[0] && setUnit(value[0].id)}
                      options={units}
                      placeholder='Escolha a unidade do Produto'
                      className='is-invalid'
                      isInvalid={!!error.unit}
                    />
                    <Form.Control.Feedback type='invalid'>{error.unit}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </>
            }
          </Row>
          <h5 className='mb-4'>Dados Financeiros</h5>
          <Row>
            <Col md={3} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>Preço de Venda</Form.Label>
                <Form.Control
                  type='number'
                  value={price}
                  placeholder={"R$ 00.00"}
                  onChange={(e) => setPrice(e.target.value)}
                  isInvalid={!!error.price}
                  step={0.01}
                />
                <Form.Control.Feedback type='invalid'>{error.price}</Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={3} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>Custo de Compra</Form.Label> <small>(Opcional)</small>
                <Form.Control
                  type='number'
                  value={buyCost}
                  placeholder={"R$ 00.00"}
                  onChange={(e) => setBuyCost(e.target.value)}
                  isInvalid={!!error.buyCost}
                  step={0.01}
                />
                <Form.Control.Feedback type='invalid'>{error.buyCost}</Form.Control.Feedback>
              </Form.Group>
            </Col>


          </Row>

          <h5 className='mb-4'>Colaboradores / Comissão</h5>
          <Row>
            <Card>
              <Card.Header className="py-2">
                <div className={"justify-content-between align-items-center row"}>
                  <Col xl={6}>
                    Colaboradores
                  </Col>
                  <Col xl={6} className='text-right'>
                    <WorkerModal setWorkerList={setWorkerList} workerList={workerList} totalTicket={price} />
                  </Col>
                </div>
              </Card.Header>
              <Card.Body className="p-0">
                <Table hover>
                  <thead>
                    <tr>
                      <th className='border-bottom'>ID</th>
                      <th className='border-bottom'>Colaborador</th>
                      <th className='border-bottom'>Comissão</th>
                      <th className='border-bottom'>Ação</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {isLoading ? (
                    <tr>
                      <td colSpan='100%' className='text-center'>
                        <Spinner animation='border' role='status' />
                      </td>
                    </tr>
                  ) : ( */}
                    <>{renderTable()}</>
                    {/* )} */}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Row>
          <div className='d-flex mt-3 justify-content-end'>
            <Button variant='primary' type='submit'>
              Cadastrar Dados
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export const AnimalForm = () => {
  const [ownerId, setOwnerId] = useState("");
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [species, setSpecies] = useState("");
  const [color, setColor] = useState("");
  const [race, setRace] = useState("");
  const [deceased, setDeceased] = useState();
  const [birthAt, setBirthAt] = useState("");
  const [tag, setTag] = useState("");

  const [error, setError] = useState({});
  const { data: customers, isLoading: customersLoading } = FetchCustomers();
  const ref = React.createRef();

  const genders = [{ name: "Masculino" }, { name: "Feminino" }];

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { status } = await CreateAnimal(ownerId, name, gender, species, color, race, deceased, birthAt, tag);
      if (status === "success") {
        toast.success("Animal cadastrado com sucesso!");

        setOwnerId("");
        setName("");
        setGender("");
        setSpecies("");
        setColor("");
        setRace("");
        setDeceased();
        setBirthAt("");
        setTag("");
        setError("");
        ref.current.clear();
      }
    } catch (err) {
      const errors = err.response.data;
      if (errors) {
        toast.warn("Verifique os campos obrigatórios!");
        setError(errors.data);
      } else {
        toast.error("Ocorreu um erro ao cadastrar o animal!");
      }
    }
  };

  return (
    <Card border='light' className='bg-white shadow-sm mb-4'>
      <Card.Body>
        <h5 className='mb-4'>Dados do Animal</h5>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>Tutor</Form.Label>
                <Typeahead
                  ref={ref}
                  id='customer'
                  labelKey={(customers) => `${customers.name}`}
                  onChange={(value) => value[0] && setOwnerId(value[0].id)}
                  options={customers}
                  placeholder='Escolha o tutor do animal'
                  isLoading={customersLoading}
                  className='is-invalid'
                  isInvalid={!!error.owner_id}
                />
                <Form.Control.Feedback type='invalid'>{error.owner_id}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  type='text'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder={"Preencha o nome do animal"}
                  isInvalid={!!error.name}
                />
                <Form.Control.Feedback type='invalid'>{error.name}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={2} md={4} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label className='col-10 text-truncate'>Carteirinha do Animal<small>(Opcional)</small></Form.Label>
                <Form.Control type='text' value={tag} onChange={(e) => setTag(e.target.value)} placeholder={"000000"} isInvalid={!!error.tag} />
                <Form.Control.Feedback type='invalid'>{error.tag}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={2} md={4} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label className='col-10 text-truncate'>Data de Nascimento</Form.Label>
                <Datetime
                  timeFormat={false}
                  closeOnSelect={true}
                  onChange={setBirthAt}
                  renderInput={(props, openCalendar) => (
                    <InputGroup hasValidation>
                      <InputGroup.Text className={!!error.born_at && "is-invalid"}>
                        <FontAwesomeIcon icon={faCalendarAlt} />
                      </InputGroup.Text>
                      <Form.Control
                        isInvalid={!!error.born_at}
                        type='text'
                        value={birthAt ? moment(birthAt).format("DD/MM/YYYY") : ""}
                        placeholder='dd/mm/yyyy'
                        onFocus={openCalendar}
                        onChange={() => { }}
                      />
                      <Form.Control.Feedback type='invalid'>{error.born_at}</Form.Control.Feedback>
                    </InputGroup>
                  )}
                />
                <Form.Control.Feedback type='invalid'>{error.born_at}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={2} md={4} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>Óbito</Form.Label>
                <div>
                  <Form.Check inline label='Sim' name='deceased' type={"radio"} value='0' onChange={(e) => setDeceased(0)} />
                  <Form.Check inline name='deceased' label='Não' type={"radio"} value='1' onChange={(e) => setDeceased(1)} />
                </div>
              </Form.Group>
            </Col>
          </Row>
          <h5 className='mb-4'>Características do Animal</h5>
          <Row>
            <Col md={6} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>Gênero</Form.Label>
                <Typeahead
                  ref={ref}
                  id='gender'
                  isInvalid={!!error.gender}
                  labelKey={(genders) => `${genders.name}`}
                  onChange={(value) => value[0] && setGender(value[0].name)}
                  options={genders}
                  className='is-invalid'
                  placeholder='Escolha o gênero do animal'
                />
                <Form.Control.Feedback type='invalid'>{error.gender}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>Raça</Form.Label>
                <Form.Control
                  type='text'
                  value={race}
                  onChange={(e) => setRace(e.target.value)}
                  placeholder={"Preencha a raça do animal"}
                  isInvalid={!!error.race}
                />
                <Form.Control.Feedback type='invalid'>{error.race}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>Espécie</Form.Label>
                <Form.Control
                  type='text'
                  value={species}
                  onChange={(e) => setSpecies(e.target.value)}
                  placeholder={"Preencha a espécie do animal"}
                  isInvalid={!!error.species}
                />
                <Form.Control.Feedback type='invalid'>{error.species}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>Pelagem</Form.Label>
                <Form.Control
                  type='text'
                  value={color}
                  onChange={(e) => setColor(e.target.value)}
                  placeholder={"Coloração do Animal"}
                  isInvalid={!!error.color}
                />
                <Form.Control.Feedback type='invalid'>{error.color}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <div className='d-flex mt-3 justify-content-end'>
            <Button variant='primary' type='submit'>
              Cadastrar Dados
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export const ExamForm = () => {
  const [ownerId, setOwnerId] = useState("");
  const [petId, setPetId] = useState("");
  const [file, setFile] = useState("");
  const [animals, setAnimals] = useState([]);
  const [isAnimalDisabled, setIsAnimalDisabled] = useState(true);
  const [error, setError] = useState({});
  const { data: customers, isLoading: customersLoading } = FetchCustomers();
  const inputTutor = React.createRef();
  const inputAnimal = React.createRef();

  const fileInputRef = useRef();


  /**
   * USE EFFECT PARA EXECUTAR A FUNÇÃO QUE FAZ A LIBERAÇÃO DO ANIMAL QUANDO SELECIONA O DONO
   */
  useEffect(() => {
    findAnimalfromId();
  }, [ownerId]);

  let findAnimalfromId = async (e) => {
    const { data: animals } = await FindCustomer(ownerId);
    if (animals.pets !== undefined) {
      if (animals.pets.length !== 0) {
        setAnimals(animals.pets);
        setIsAnimalDisabled(false);
      } else {
        toast.error("O tutor selecionado não possui nenhum pet cadastrado!");
        inputTutor.current.clear();
      }
    }
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await CreateExam(petId, file);

      if (response.data.status === "success") {
        toast.success("Exame cadastrado com sucesso!");
        toast.info(`A senha desse exame é: ${response.data.data.password}`, {
          autoClose: false,
          pauseOnHover: true,
        });

        setIsAnimalDisabled(true);
        setOwnerId("");
        setPetId("");
        setError("");
        inputTutor.current.clear();
        inputAnimal.current.clear();
        fileInputRef.current.value = null;
      } else {
        throw new Error("Falha ao cadastrar o exame.");
      }
    } catch (err) {
      if (err.response) {
        toast.warn("Verifique os campos obrigatórios!");
        setError(err.response.data.data);
      } else {
        toast.error("Ocorreu um erro ao cadastrar o animal!");
      }
    }
  };

  return (
    <Card border='light' className='bg-white shadow-sm mb-4'>
      <Card.Body>
        <h5 className='mb-4'>Dados do Animal</h5>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>Tutor</Form.Label>{" "}
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  placement='top'
                  overlay={<Tooltip style={{ margin: 0 }}>Selecione um tutor, para que em seguida, sejam carregados seus respectivos pets.</Tooltip>}>
                  <FontAwesomeIcon icon={faQuestionCircle} className='me-2' />
                </OverlayTrigger>
                <Typeahead
                  ref={inputTutor}
                  id='customer'
                  labelKey={(customers) => `${customers.name}`}
                  onChange={(value) => value[0] && setOwnerId(value[0].id)}
                  options={customers}
                  placeholder='Escolha o tutor do animal'
                  isLoading={customersLoading}
                  className='is-invalid'
                  isInvalid={!!error.owner_id}
                />
              </Form.Group>
              <Form.Control.Feedback type='invalid'>{error.owner_id}</Form.Control.Feedback>
            </Col>
            <Col md={6} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>Animais do Tutor</Form.Label>
                <Typeahead
                  disabled={isAnimalDisabled}
                  ref={inputAnimal}
                  id='animals'
                  labelKey={(animals) => `${animals.name}`}
                  onChange={(value) => value[0] && setPetId(value[0].id)}
                  options={animals}
                  placeholder='Escolha um dos animais do tutor'
                  // isLoading={animals}
                  className='is-invalid'
                  isInvalid={!!error.pet_id}
                />
                <Form.Control.Feedback type='invalid'>{error.pet_id}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={12} md={12} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>Exame</Form.Label>
                <Form.Control
                  disabled={isAnimalDisabled}
                  ref={fileInputRef}
                  type='file'
                  onChange={(e) => setFile(e.target.files[0])}
                  accept='.png,.jpg,.jpeg,.pdf'
                  isInvalid={!!error.file}
                />
                <Form.Text muted>Seu arquivo deve ser estar em .pdf, .jpg ou .png</Form.Text>
                <Form.Control.Feedback type='invalid'>{error.file}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <div className='d-flex mt-3 justify-content-end'>
            <Button variant='primary' type='submit'>
              Cadastrar Dados
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};