
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Button, FormCheck, Container, InputGroup } from '@themesberg/react-bootstrap';
import { useNavigate } from 'react-router-dom';

import BgImage from "../../assets/img/illustrations/signin.svg";
import { AuthenticateUser } from "../../services/UserService";
import { AuthenticateCustomer } from "../../services/CustomerService";
import { IMaskInput } from "react-imask";


export default () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberToken, setRememberToken] = useState(false);
  const [error, setError] = useState({});
  const handleClick = () => setRememberToken(!rememberToken)
  const navigate = useNavigate();


  const TempError = {};

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await AuthenticateCustomer(username, password, rememberToken);
      if (response.data.status === 'success') {
        navigate(`/customer/home`);
        navigate(0);
      }
    } catch (err) {
      if (err) {
        TempError.password = [];
        TempError.password[0] =
          "Nome de usuário ou senha Inválidas";
      }
      setError(TempError);
    }
  };

  useEffect(() => {
    document.body.style.backgroundColor = "#735986";
  }, []);



  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <h3>C.E.R VET - Área do Paciente</h3>
                <Form className="mt-4" onSubmit={handleSubmit}>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>CPF</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control autoFocus type="text" as={IMaskInput}
                        mask='000.000.000-00' placeholder="111.111.111-11" value={username} onChange={(e) => setUsername(e.target.value)} />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Senha</Form.Label>
                      <InputGroup>
                        <InputGroup.Text className={!!error.password && "is-invalid"}>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control type="password" placeholder="****" value={password} onChange={(e) => setPassword(e.target.value)} isInvalid={!!error.password} />
                        <Form.Control.Feedback type="invalid">{error.password}</Form.Control.Feedback>

                      </InputGroup>
                    </Form.Group>
                  </Form.Group>
                  <Button variant="primary" type="submit" className="w-100">
                    Fazer Login
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
