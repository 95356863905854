import axios from "axios";
import { useEffect, useState } from "react";
import api from "../api";

export const FindActivePayment = (page, filter, refreshState) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    api
      .get(`payment?page=${page}`)
      .then((response) => {
        response.data.length === 0 ? setIsEmpty(true) : setIsEmpty(false);
        setData(response.data.data.info);
        setPagination(response.data.data.pagination);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsEmpty(true);
        setIsLoading(false);
      });
  }, [page, filter, refreshState]);

  return { data, pagination, isLoading, isEmpty };
};

export const FetchPayments = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState();
  const [data, setData] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    api
      .get(`list/payments`)
      .then((response) => {
        if (!response.data.status === "error") setIsEmpty(true);
        response.data.data.length === 0 ? setIsEmpty(true) : setIsEmpty(false);

        setData(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsEmpty(true);
        setIsLoading(false);
      });
  }, []);
  return { data, isLoading, isEmpty };
};

export const CreatePayment = async (name, text_color, background_color, tax) => {
  return await api.post("/payment", {
    name: name,
    text_color: text_color,
    background_color: background_color,
    tax: tax,
  })
    .then(function (response) {
      return { data: response.data.data, status: response.data.status };
    });
};

export const UpdatePayment = async (id, name, tax, textColor, backgroundColor) => {
  return await api
    .put(`/payment/${id}`, {
      name: name,
      text_color: textColor,
      background_color: backgroundColor,
      tax: tax,
    }).then(function (response) {
      return { data: response.data.data, status: response.data.status };
    });

};

export const FindPayment = async (id) => {
  return await api.get(`/payment/${id}`).then(function (response) {
    return { data: response.data.data, status: response.data.status };
  });
};

export const DeletePayment = async (id) => {
  return await api.delete(`/payment/${id}`).then(function (response) {
    return { data: response.data, status: response.data.status };
  });
};